import SignupDetails from "../../modules/auth/components/SignupDetails";
import OnboardingPageWrapper from "../../modules/shared/components/Wrapper/PageWrapper";

export default function Signup() {
  return (
    <OnboardingPageWrapper>
      <SignupDetails />
    </OnboardingPageWrapper>
  );
}
