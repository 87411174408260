const dashboard = ({ color = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
  >
    <path
      d="M3.14062 15C3.14062 9.10744 3.14062 6.16116 4.97121 4.33059C6.80179 2.5 9.74806 2.5 15.6406 2.5C21.5331 2.5 24.4795 2.5 26.31 4.33059C28.1406 6.16116 28.1406 9.10744 28.1406 15C28.1406 20.8925 28.1406 23.8389 26.31 25.6694C24.4795 27.5 21.5331 27.5 15.6406 27.5C9.74806 27.5 6.80179 27.5 4.97121 25.6694C3.14062 23.8389 3.14062 20.8925 3.14062 15Z"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
    />
    <path
      d="M9.39062 22.5V18.75"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M15.6406 22.5V15"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M21.8906 22.5V11.25"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);

export default dashboard;
