import { http } from "../../../app/services";

const addDataset = (data: any) => {
  return http.post("/datasets", data);
};

const getAllDatasets = (queryKey: any) => {
  return http
    .get(`/datasets`, {
      params: { ...queryKey },
    })
    .then((res) => res.data);
};

const getOneDataset = ({ queryKey }: any) => {
  // console.log(queryKey, "One Query Key");

  return http.get(`/datasets/${queryKey[1]}`).then((res) => res.data);
};

const updateDataset = ({ data, id }: any) => {
  return http.patch(`/datasets/${id}`, data).then((res) => res.data);
};

const deleteDataset = ({ ids }: any) => {
  return http.delete(`/datasets`, { data: { ids } });
};

const deleteDataVersions = ({ ids, datasetId }: any) => {
  return http.delete(`/datasets/${datasetId}/versions`, { data: { ids } });
};

export const getDatasetDetails = async ({ queryKey }: any) => {
  try {
    // console.log("apple is good to eat");
    // console.log(queryKey[1]);
    const response = await http.get("/datasets", { params: queryKey[1] });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDatasetVersionDetails = async ({ queryKey }: any) => {
  try {
    const response = await http.get(`/datasets/${queryKey[1]}/versions`, {
      params: queryKey[2],
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CreateDataSet = async (body: any) => {
  try {
    const response = await http.post("/datasets", body);
    return response.data;
  } catch (error) {
    throw error; // Error handling will be managed by interceptors
  }
};

export const CreateDataSetVersion = async ({ body, datasetId }: any) => {
  try {
    const response = await http.patch(`/datasets/${datasetId}`, body);
    return response.data;
  } catch (error) {
    throw error; // Error handling will be managed by interceptors
  }
};

export const fileUpload = async (body: FormData) => {
  try {
    const response = await http.post("/media-object/file-upload", body);
    return response.data;
  } catch (error) {
    throw error; // Error handling will be managed by interceptors
  }
};

export {
  addDataset,
  deleteDataset,
  deleteDataVersions,
  getAllDatasets,
  getOneDataset,
  updateDataset,
};
