export const decryptAPIKey = (encryptedKey: string, secretKey: any) => {
  // console.log("Encrypted Key:", encryptedKey, secretKey);
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedKey, secretKey);
    const decryptedKey = bytes.toString(CryptoJS.enc.Base64);
    if (!decryptedKey)
      throw new Error("Decryption failed, no valid UTF-8 string");
    return decryptedKey;
  } catch (error) {
    console.error("Decryption error:", error);
    return "";
  }
};

export const userType = [
  {
    label: "User",
    value: "User",
  },
  {
    label: "Sme",
    value: "Sme",
  },
];
