import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Icons } from "../../../../assets";
import CardSelector from "./cardSelector";

const PresetsSection = ({
  presets,
  cardSelected,
  presetCreation,
  includedPreset,
  preSetDelete,
  clearSelection,
}: {
  presets: any[];
  cardSelected: any[];
  presetCreation?: any;
  preSetDelete: any;
  includedPreset: any;
  clearSelection?: any;
}) => {
  const [selectedPreset, setSelectedPreset] = useState<any | null>(null);

  const [selectedPresetIndex, setSelectedPresetIndex] = useState<number | null>(
    null
  );
  const [selectedName, setSelectedName] = useState<any>();

  const handleNameSelect = (name: any) => {
    setSelectedName(name);
    presetCreation(name);
  };

  useEffect(() => {
    if (!clearSelection) {
      setSelectedPreset(null);
    }
  }, [clearSelection]);

  const selectedKeys = selectedName?.versionName;

  const matchingPresets = presets?.filter((preset: any) => {
    const presetKeys = preset.selectedCards
      .map((card: any) => card?.versionName)
      .sort();

    if (selectedKeys?.length === 1) {
      return presetKeys.includes(selectedKeys[0]);
    }

    return presetKeys.includes(selectedKeys);
  });

  const namesMapped = cardSelected.map(
    (card) => `${card?.providerName} ${card?.versionName}`
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {/* <Box
          sx={{
            bgcolor: "#F8F8F8",
            borderRadius: "8px",
            padding: "10px",
          }}
        ></Box> */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 500,
        }}
      >
        Selected Model
      </Typography>
      {cardSelected?.length >= 1 && (
        <CardSelector
          names={[...namesMapped].reverse()}
          onNameSelect={handleNameSelect}
          cards={cardSelected}
          setDelete={preSetDelete}
          emptyPreset={setSelectedPreset}
        />
      )}

      <Box
        sx={{
          border: "1px solid #1212121A",
          // height: "18vh",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#FDF7F4",
          color: "#121212B2",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "1px solid #1212121A",
            gap: "10px",
            p: "0.5vw 0.5vw",
          }}
        >
          <img
            src={Icons.Memo}
            alt="Memo"
            width={"20px"}
            height={"20px"}
            style={{ marginTop: 2 }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
              }}
            >
              Selected presets for the model
            </Typography>
            {/* <Typography
              sx={{
                fontWeight: 500,
                mt: 0.1,
              }}
            >
              (Max 3 Allowed)
            </Typography> */}
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            textAlign: matchingPresets.length === 0 ? "center" : "left",
            justifyContent:
              matchingPresets.length === 0 ? "center" : "flex-start",
            alignItems: matchingPresets.length === 0 ? "center" : "none",
          }}
        >
          {matchingPresets.length > 0 ? (
            <Stack>
              <Box sx={{ width: "100%", overflowY: "auto" }}>
                {matchingPresets.map((preset, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom:
                        selectedPreset?.name === preset?.name
                          ? "4px solid #F07946"
                          : "none",
                      p: "0.2vw 0.8vw",
                      mb: "5px",
                      cursor:
                        selectedPreset?.name === preset?.name
                          ? "null"
                          : "pointer",

                      color:
                        selectedPreset?.name === preset?.name
                          ? "#F07946"
                          : "inherit",

                      // "&:hover": {
                      //   bgcolor: "#E8E8E8",
                      // },
                    }}
                    onClick={() => {
                      setSelectedPreset(preset);

                      includedPreset(preset);
                      preSetDelete(true);

                      setSelectedPresetIndex(index);
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 400, textAlign: "left" }}
                    >
                      {preset.name || `Preset ${index + 1}`}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 300 }}>
                      {preset.maxTokens} tokens | {preset.temperature}{" "}
                      temperature
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Stack>
          ) : (
            <Box
              sx={{
                p: "1vw 0.5vw",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                No presets added yet. Add presets using the configuration panel.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PresetsSection;
