import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UIStore } from "../../../store/general";
import AddProjectForm from "../../project/components/AddProjectForm";
import { useConfirm } from "../../project/components/confirmDialog";
import {
  deleteProject,
  getOneProject,
} from "../../project/services/ProjectService";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../shared/components/Errors/HandleError";
import SearchContainer from "../../shared/components/SearchContainer";
import Table from "../../shared/components/Table";

export default function FindAllPrompts() {
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);

  const [viewMode, setViewMode] = useState<"table" | "grid">("table");

  const { data: FindOneProject, isLoading } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  const GetAllPrompts = FindOneProject?.prompts;

  const { mutate } = useMutation(deleteProject, {
    onSuccess: () => {
      toast.success("Project Deleted  Successfully");
      queryClient.invalidateQueries("projects");
    },
    onError: (err) => handleError(err),
  });

  const handleDelete = (items: any) => {
    const idsToDelete =
      items.length >= 2 ? items.map((item: any) => item?._id) : [items[0]?._id];

    confirm({
      msg: "Are you sure you want to delete this?",
      action: () => {
        mutate({ ids: idsToDelete });
      },
    });
  };

  // const handleView = (prompt: any) => {
  //   navigate(`/app/projects/${projectId}/prompts/${prompt?._id}/responses`, {
  //     replace: true,
  //   });
  // };

  const handleView = (prompt: any) => {
    navigate(
      `/app/projects/${projectId}/prompts/${prompt?._id}/prompt-detailed-view`,
      {
        replace: true,
      }
    );
  };

  const filteredPrompts = GetAllPrompts?.filter((prompt: any) =>
    prompt.title?.toLowerCase().includes(search.toLowerCase())
  );

  const renderButtons = () => {
    return (
      // <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5vw" }}>
      //   {selectedRow.length >= 2 ? (
      //     <MultiTypeButton
      //       buttonType={ButtonType.Outlined}
      //       typeText={"Delete projects"}
      //       actionOnClick={() => handleDelete(selectedRow)}
      //       icon={Icons.TrashBin}
      //     />
      //     <></>
      //   ) : (
      //     <>
      //       <MultiTypeButton
      //         buttonType={ButtonType.Outlined}
      //         isLoading={isLoading}
      //         typeText={"Delete project "}
      //         actionOnClick={() => handleDelete(selectedRow)}
      //         icon={Icons.TrashBin}
      //       />

      //       <MultiTypeButton
      //         buttonType={ButtonType.Gradient}
      //         typeText={"Edit"}
      //         actionOnClick={() => setOpen2(true)}
      //         icon={Icons.Edit}
      //       />

      //       <AddProjectForm
      //         open={open2}
      //         setOpen={setOpen2}
      //         data={GetAllProjects?.projects[0]}
      //       />
      //     </>
      //   )}
      // </Box>
      <></>
    );
  };

  const columns = [
    { key: "title", title: "Prompt title" },
    { key: "tag", title: "Prompt tag" },
    {
      key: "category",
      title: "Category",
      render: (e: any) => {
        return (
          <>
            <Typography variant="h6" fontWeight="500">
              {e.category.replace(/_/g, " ")}
            </Typography>
          </>
        );
      },
    },
    {
      key: "versions",
      title: "Versions",
      render: (e: any) => {
        return (
          <>
            <Box
              sx={{
                width: "6vw",
                bgcolor: isDarkMode ? "rgba(75, 170, 190, 0.3)" : "#C75737",
                textAlign: "center",
                padding: "4px",
                borderRadius: "5px",
              }}
            >
              <Typography
                color={isDarkMode ? "rgba(75, 170, 190, 1)" : "white"}
                fontWeight={"bold"}
                variant="subtitle2"
              >
                {e?.versions?.length || "NA"}
              </Typography>
            </Box>
          </>
        );
      },
    },
    // {
    //   key: "status",
    //   title: "Status",
    //   render: (e: any) => {
    //     const runStatus = e?.versions?.[0]?.runStatus;

    //     return (
    //       <Typography
    //         variant="h6"
    //         fontWeight="500"
    //         color={
    //           runStatus ? (runStatus === "Done" ? "green" : "red") : "gray"
    //         }
    //         sx={{ display: "flex", alignItems: "center" }}
    //       >
    //         {runStatus}

    //         {runStatus !== "Done" && (
    //           <IconButton size="small" onClick={() => window.location.reload()}>
    //             <RefreshIcon fontSize="small" />
    //           </IconButton>
    //         )}
    //       </Typography>
    //     );
    //   },
    // },
  ];

  return (
    <Box sx={{ padding: "3.5vh 2vw" }}>
      <Box display="flex" flexDirection={"row"} justifyContent="space-between">
        <Typography
          variant="h1"
          sx={{
            fontWeight: 600,
          }}
        >
          Prompts
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5vw",
            alignItems: "center",
          }}
        >
          <SearchContainer
            debounced={true}
            onChange={setSearch}
            value={search}
          />
          <Box>
            {selectedRow?.length >= 1 &&
              GetAllPrompts?.length >= 1 &&
              renderButtons()}
          </Box>
          <Box>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Create Prompt"}
              actionOnClick={() =>
                navigate(`/app/projects/${projectId}/prompts/create`)
              }
            />
            <AddProjectForm open={open} setOpen={setOpen} data={null} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: "4vh" }}>
        <Table
          data={filteredPrompts || []}
          columns={columns}
          loading={isLoading}
          is_Id={true}
          renderButtons={renderButtons}
          onRowSelectionChange={(selected: any) => {
            setSelectedRow(selected || null);
          }}
          onViewClick={(item: any) => handleView(item)}
          pagination={{
            totalCount: GetAllPrompts?.length,
            pageCount: limit,
            setPageCount: setLimit,
            page: offset / limit,
            setPage: (page) => {
              const newOffset = page * limit;
              setOffset(newOffset);
            },
          }}
          onPageChangeCallback={(paginationData) => {
            setOffset(paginationData.offset);
            setLimit(paginationData.limit);
          }}
        />
      </Box>
    </Box>
  );
}
