import { Delete, Visibility } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { UIStore } from "../../../../store/general";
import { fileTypeIcons, UploadedDataDisplayProps } from "./UploadedDataDisplay";

export default function SingleFileUploadedCard({
  label,
  required,
  name,
  uploadFileType,
  containerProps,
  uploadedFiles,
  DeleteFilesHandler,
}: UploadedDataDisplayProps) {
  const file = uploadedFiles[0];
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  const handleViewClick = (fileUrl: string) => {
    window.open(fileUrl, "_blank");
  };
  const awsUrl = process.env.REACT_APP_AWS_URL;

  const Headers = JSON.parse(
    localStorage.getItem("ExtractedHeaders") || "[]"
  ) as string[];

  const chipNames = [
    "John Doe",
    "Jane Smith",
    "Michael Johnson",
    "Alice Brown",
    "Bob White",
  ];

  return (
    <Card
      key={file?.key}
      sx={{
        width: "100%", // Full width of the card container
        maxWidth: 500, // You can adjust max-width as needed
        position: "relative",
        display: "flex",
        flexDirection: "column", // Stack content vertically
        alignItems: "stretch",
        backgroundColor: isDarkMode ? "auto" : "#F7F8FA",
      }}
    >
      <Box display="flex" alignItems="center" padding={2}>
        <CardMedia
          component="img"
          image={fileTypeIcons[uploadFileType]}
          alt={file?.originalFileName}
          sx={{
            width: 40,
            height: 40,
            objectFit: "cover",
            marginRight: 2,
          }}
        />
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            height: "40px",
          }}
        >
          <Stack>
            <Typography variant="subtitle2" fontWeight={"bold"} noWrap>
              {file?.originalFileName || "NA"}
            </Typography>
            <Typography variant="caption">
              Date: {new Date(file?.createdAt)?.toLocaleDateString() || "NA"}
            </Typography>
          </Stack>
          <Box display="flex" gap={1}>
            <IconButton
              onClick={() =>
                handleViewClick(file?.url || `${awsUrl}/${file.key}`)
              }
              size="small"
              sx={{
                border: "0.5px solid grey",
                background: "FFFFFF",
                ":hover": {
                  backgroundColor: isDarkMode ? "transparent" : "lightgray",
                },
              }}
            >
              <Visibility
                fontSize="small"
                htmlColor={isDarkMode ? "grey" : "#F7711F"}
              />
            </IconButton>
            <IconButton
              onClick={() => DeleteFilesHandler(0)}
              color="error"
              size="small"
              sx={{
                border: "0.5px solid grey",
                background: "FFFFFF",
                ":hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        </CardContent>
      </Box>

      {/* Divider spans full width */}
      <Divider sx={{ width: "100%", my: 1 }} />

      {/* Chips section below the divider */}
      <Box
        sx={{
          padding: "10px 16px",
          height: {
            xs: "10vh",
            sm: "15vh",
            md: "15vh",
          },
          overflowY: "auto",
        }}
      >
        <Grid container spacing={1}>
          {Headers.map((name, index) => (
            <Grid item key={index}>
              <Chip
                label={name}
                color="primary"
                size="small"
                sx={{
                  fontSize: 14,
                  height: 24,
                  backgroundColor: isDarkMode
                    ? " rgba(40, 48, 60, 1)"
                    : "#e0e0e0",
                  padding: "15px",
                  borderRadius: "20px",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  );
}
