import { Box, InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { LabelWrapper } from "../LabelWrapper/LabelWrapper";

interface OptionType {
  label: string; // Display label
  value: string; // Unique identifier or value to store
  iconUrl?: string; // Optional icon or image URL
  backgroundColor?: any;
}

interface AutocompleteGenericProps {
  name: string; // Form field name
  control: Control<any>; // React Hook Form control
  options: OptionType[]; // Dataset options
  label: string; // Input label
  placeholder?: string; // Input placeholder
  disabled?: boolean; // Disable input
  errors?: any; // Validation errors
  sx?: any; // Additional styles
  size?: "small" | "medium"; // Input size
  required: boolean; // Whether the field is required
  defaultValue?: string; // Default value
  backgroundColor?: any;
}

const AutocompleteGeneric: React.FC<AutocompleteGenericProps> = ({
  name,
  control,
  options,
  label,
  required,
  disabled = false,
  size = "small",
  placeholder = "Select an option",
  defaultValue = "",
  backgroundColor,
}) => {
  return (
    <LabelWrapper label={label} required={required} name={name}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            disabled={disabled}
            size={size}
            {...field}
            options={options}
            getOptionLabel={(option) => option.label} // Display label
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderOption={(props, option) => (
              <li {...props}>
                {option.iconUrl && (
                  <img
                    src={option.iconUrl}
                    alt=""
                    width="20"
                    style={{ marginRight: 8 }}
                  />
                )}
                {option.label} {/* Only show the label */}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment:
                    field.value && false ? (
                      <InputAdornment position="start">
                        <Box sx={{ marginLeft: "10px" }}>
                          {options.find(
                            (option) => option.value === field.value
                          )?.iconUrl && (
                            <img
                              src={
                                options.find(
                                  (option) => option.value === field.value
                                )?.iconUrl
                              }
                              alt=""
                              width="20"
                            />
                          )}
                        </Box>
                      </InputAdornment>
                    ) : null,
                }}
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
              />
            )}
            value={
              options.find((option) => option.value === field.value) || null
            }
            onChange={(e, newValue) => {
              field.onChange(newValue ? newValue.value : null); // Register value
            }}
            sx={{
              backgroundColor: backgroundColor,
              "& .MuiInputBase-root": {
                backgroundColor: backgroundColor,
              },
            }}
          />
        )}
      />
    </LabelWrapper>
  );
};

export default AutocompleteGeneric;
