import { MoreVert } from "@mui/icons-material";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Styled components
const StyledCard = styled(Card)({
  background: "#FFFFFF",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  padding: "20px",
  marginBottom: "20px",
  boxShadow: "0px 6px 14px #36408D08",
  cursor: "pointer",
});

const StyledTypography = styled(Typography)({
  textAlign: "left",
});

const DealCodeTypography = styled(StyledTypography)({
  fontSize: "36px",
  lineHeight: "30px",
});

const DealTypeTypography = styled(StyledTypography)({
  fontSize: "20px",
  lineHeight: "28px",
  fontWeight: 400,
  color: "#333333",
});

const DealAmountTypography = styled(StyledTypography)({
  fontSize: "18px",
  lineHeight: "21px",
  color: "#121212",
  opacity: 0.7,
});

const DealDescriptionTypography = styled(StyledTypography)({
  fontSize: "16px",
  lineHeight: "18px",
  marginBottom: "10px",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const Divider = styled(Box)({
  width: "calc(100% - 40px)",
  height: "0px",
  border: "1px solid #1212121A",
  opacity: 1,
  marginTop: "10px",
  marginBottom: "10px",
});

const ProjectsGrid = ({
  paginatedData,
  handleDelete,
  handleEdit,
  onViewClick,
}: any) => {
  // console.log(paginatedData, "Grid Data");

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [open2, setOpen2] = useState(false);

  //   const { data: profileData } = useQuery("getUser-id", getUserProfile);

  const handleCardClick = (id: any) => {
    navigate(`/deal-process/deals/${id}`);
  };

  const handleEditSelected = (data: any) => {
    setIsEditing(!isEditing);
  };

  const menuItems = [
    { label: "Delete", action: "delete" },
    { label: "Edit", action: "edit" },
    { label: "View", action: "view" },
  ];

  const handleActionMenuItemClick = (action: any, deal: any) => {
    switch (action) {
      case "delete":
        handleDelete();
        break;
      case "edit":
        handleEdit();
        break;
      case "view":
        onViewClick(deal);
        break;
      default:
        break;
    }
    handleActionMenuClose();
  };

  const handleActionMenuOpen = (event?: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid container justifyContent="left" spacing={2}>
      {paginatedData.map((deal: any) => (
        <Grid item key={deal.id} xs={12} sm={6} md={4} lg={4}>
          <StyledCard>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              marginBottom="20px"
            >
              <Grid item>
                <Box
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{ color: "#F07946", fontWeight: 600 }}
                  >
                    {deal?.name}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={handleActionMenuOpen}
                    sx={{ color: "#38761D !important" }}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                  >
                    <MoreVert />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleActionMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {menuItems.map((item) => (
                      <MenuItem
                        key={item.action}
                        onClick={() =>
                          handleActionMenuItemClick(item.action, deal)
                        }
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Grid>
            </Grid>
            <Grid item>
              <Box>
                {" "}
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  {deal?.useCase.replace(/_/g, " ")}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <DealTypeTypography>
                {deal?.user?.name} -{" "}
                {moment(deal?.createdAt).format("Do, MMM YYYY").toUpperCase()}
              </DealTypeTypography>
            </Grid>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectsGrid;
