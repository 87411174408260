export const ColorPalette = {
  BrandColors: {
    OrangeLight: "#F07946",
    YellowLight: "#F4B537",
    Cream: "#EEEEEE",
    Cream1: "#FDF0DF",
    Cream2: "#FFE6C5",
    Brown1: "#312A2A",
    Brown2: "#383535",
    LightGreen: "#53b76b",
  },
  GreyColors: {
    GreyBlack: "rgba(18, 18, 18)",
    GreyBlack70: "rgba(18, 18, 18, 0.7)", // 121212 SHADE
    GreyBlack80: "rgba(18, 18, 18, 0.8)",
    GreyBlack15: "rgba(18, 18, 18, 0.15)",
    GreyBlack10: "rgba(18, 18, 18, 0.10)",
    GreyBlack50: "rgba(18, 18, 18, 0.5)",
    GreyBlack60: "rgba(18, 18, 18, 0.6)",
    GreyBlack85: "rgba(18, 18, 18, 0.85)",

    GreyDark60: "rgba(34, 34, 34, 0.6)", // 22222 SHADE
    GreyDark: "rgba(34, 34, 34)",
    GreyWhite: "#F7F8FA",
    GreyWhite95: "#f4f5f7",
    GreyWhite100: "#FFFFFF",
    GreyWhite15: "rgba(255, 255, 255, 0.15)",
    GreyWhite10: "rgba(255, 255, 255, 0.1)",
    GreyWhiteLight: "#F2F2F2",
    GreyWhite80: "rgba(255, 255, 255,0.8)", //FFFFFF SHADE
    GreyWhite70: "rgba(255, 255, 255,0.7)",
    GreyWhite50: "rgba(255, 255, 255,0.5)",
    GreyWhite85: "rgba(255, 255, 255,0.85)",
    GreyWhite60: "rgba(255, 255, 255,0.6)",
    GreyWhite90: "#282828",
    Grey1: "#151515",
    GreyWhite2: "#F8F8F8",
    GreyLight: "#12121226",
  },
  BlueGreyColors: {
    GreyBlue: "#1D2127",
    GreyBlue50: "#293640",
    GreyBlue70: "#161A1D",
    GreyBlueLight: "#515765",
    GreyBlueLight30: "#B6C2CF",
    DarkBlueGrey: "#0F0F0F",
    NavyBlue: "#020617",
    BlueGrey1: "#28303C",
    BlueGrey2: "#282E33",
    BlueGrey3: "#33353F",
    BlueGrey5: "#333333",
    BlueGrey6: "#324155",
    Lightblue: "#5f9fff",
    SeaBlue40: "rgba(75, 170, 190, 0.4)",
    SeaBlue30: "rgba(75, 170, 190, 0.3)",
    SeaBlue100: "rgba(75, 170, 190)",
  },
  UtilityColors: {
    Green: "#88B053",
    Blue: "#4BAABE",
    Orange: "#ED9035",
    Red: "#F2353C",
    Pink: "#EA4A6B",
    Yellow: "#F4C100",
    Green20: "#88B05333",
    Blue20: "#4BAABE33",
    Orange20: "#ED903533",
    Red20: "#F2353C33",
    Pink20: "#EA4A6B33",
    LightYellow: "#F4C10033",
  },
};
