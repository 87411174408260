const Prompts = ({ color = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
  >
    <path
      d="M21.5844 3.07812H9.6969C8.2483 3.07812 7.52401 3.07813 6.93986 3.28139C5.83215 3.66683 4.96247 4.56211 4.58806 5.70245C4.39062 6.30381 4.39062 7.04944 4.39062 8.5407V26.0459C4.39062 27.1186 5.62187 27.6879 6.40075 26.9751C6.85834 26.5564 7.54791 26.5564 8.0055 26.9751L8.60938 27.5278C9.41136 28.2616 10.6199 28.2616 11.4219 27.5278C12.2239 26.7939 13.4324 26.7939 14.2344 27.5278C15.0364 28.2616 16.2449 28.2616 17.0469 27.5278C17.8489 26.7939 19.0574 26.7939 19.8594 27.5278C20.6614 28.2616 21.8699 28.2616 22.6719 27.5278L23.2758 26.9751C23.7334 26.5564 24.4229 26.5564 24.8805 26.9751C25.6594 27.6879 26.8906 27.1186 26.8906 26.0459V8.5407C26.8906 7.04944 26.8906 6.30381 26.6931 5.70245C26.3187 4.56211 25.4491 3.66683 24.3414 3.28139C23.7572 3.07813 23.033 3.07812 21.5844 3.07812Z"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
    />
    <path
      d="M13.7656 14.3281H21.8906"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M9.39062 14.3281H10.0156"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M9.39062 9.95312H10.0156"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M9.39062 18.7031H10.0156"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M13.7656 9.95312H21.8906"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M13.7656 18.7031H21.8906"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);

export default Prompts;
