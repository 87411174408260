import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Icons } from "../../../assets";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../shared/components/Errors/HandleError";
import Spinner from "../../shared/components/Loaders/Spinner";
import SearchContainer from "../../shared/components/SearchContainer";
import Table from "../../shared/components/Table";
import { deleteProject, getAllProjects } from "../services/ProjectService";
import AddProjectForm from "./AddProjectForm";
import { useConfirm } from "./confirmDialog";

export default function FindAllProjects() {
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [viewMode, setViewMode] = useState<"table" | "grid">("table");

  const { data: GetAllProjects, isLoading }: any = useQuery(
    ["projects", search, limit, offset],
    () => getAllProjects({ search, limit, offset })
  );

  const { mutate } = useMutation(deleteProject, {
    onSuccess: () => {
      toast.success("Project Deleted Successfully");
      queryClient.invalidateQueries("projects");
    },
    onError: (err) => handleError(err),
  });

  const handleDelete = (items: any) => {
    const idsToDelete =
      items.length >= 2 ? items.map((item: any) => item?._id) : [items[0]?._id];

    confirm({
      msg: "Are you sure you want to delete this?",
      action: () => {
        mutate({ ids: idsToDelete });
      },
    });
  };

  const handleView = (data: any) => {
    navigate(`/app/projects/${data?._id}/prompts`, { replace: true });
  };

  const renderButtons = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5vw" }}>
        {selectedRow.length >= 2 ? (
          <MultiTypeButton
            buttonType={ButtonType.Outlined}
            typeText={"Delete"}
            actionOnClick={() => handleDelete(selectedRow)}
            icon={Icons.TrashBin}
          />
        ) : (
          <>
            <MultiTypeButton
              buttonType={ButtonType.Outlined}
              isLoading={isLoading}
              typeText={"Delete"}
              actionOnClick={() => handleDelete(selectedRow)}
              icon={Icons.TrashBin}
            />
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Edit"}
              actionOnClick={() => setOpen2(true)}
              icon={Icons.Edit}
            />

            <AddProjectForm
              open={open2}
              setOpen={setOpen2}
              data={GetAllProjects?.projects[0]}
            />
          </>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ padding: "3.5vh 2vw" }}>
      <Box display="flex" flexDirection={"row"} justifyContent="space-between">
        <Typography
          variant="h1"
          sx={{
            fontWeight: 600,
          }}
        >
          Projects
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5vw",
            alignItems: "center",
          }}
        >
          <SearchContainer
            debounced={true}
            onChange={setSearch}
            value={search}
          />

          <Box>
            {selectedRow?.length >= 1 &&
              GetAllProjects?.projects?.length >= 1 &&
              renderButtons()}
          </Box>
          <Box>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Create new project"}
              actionOnClick={() => setOpen(true)}
            />
            <AddProjectForm open={open} setOpen={setOpen} data={null} />
          </Box>
          {/* <Box>
            <Grid>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <ToggleButtonGroup
                  value={viewMode}
                  exclusive
                  onChange={(e, newViewMode) =>
                    newViewMode && setViewMode(newViewMode)
                  }
                  sx={{ marginLeft: "20px" }}
                >
                  <ToggleButton
                    value="table"
                    sx={{
                      ...getToggleButtonStyles(viewMode === "table"),
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <TableRows />
                  </ToggleButton>
                  <ToggleButton
                    value="grid"
                    sx={{
                      ...getToggleButtonStyles(viewMode === "grid"),
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <Apps />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </Box> */}
        </Box>
      </Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box sx={{ mt: "4vh" }}>
          {/* {viewMode === "table" ? ( */}
          <Table
            data={GetAllProjects?.projects || []}
            columns={columns}
            loading={isLoading}
            is_Id={true}
            renderButtons={renderButtons}
            onRowSelectionChange={(selected: any) => {
              setSelectedRow(selected || null);
            }}
            onViewClick={(item: any) => handleView(item)}
            pagination={{
              totalCount: GetAllProjects?.total,
              pageCount: limit,
              setPageCount: setLimit,
              page: offset / limit,
              setPage: (page) => {
                const newOffset = page * limit;
                setOffset(newOffset);
              },
            }}
            onPageChangeCallback={(paginationData) => {
              setOffset(paginationData.offset);
              setLimit(paginationData.limit);
            }}
          />
          {/* ) : (
          <ProjectsGrid
            paginatedData={GetAllProjects?.projects || []}
            handleDelete={() => handleDelete(selectedRow)}
            handleEdit={() => setOpen2(true)}
            onViewClick={(item: any) => handleView(item)}
          />
        )} */}
        </Box>
      )}
    </Box>
  );
}

const columns = [
  { key: "title", title: "Project title" },
  { key: "tag", title: "Project tag" },
  {
    key: "useCase",
    title: "Use case",
    render: (e: any) => {
      return e?.useCase.replace(/_/g, " ");
    },
  },
  {
    key: "createdAt",
    title: "Created on",
    render: (e: any) => {
      const Date = moment(e?.createdAt).format("YYYY-MM-DD");
      return Date || "NA";
    },
  },
  { key: "user.name", title: "Created by" },
];
