import { Box } from "@mui/material";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirm } from "../../../project/components/confirmDialog";
import { getOneProject } from "../../../project/services/ProjectService";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import Table from "../../../shared/components/Table";

export default function PromptVersionTable() {
  const { projectId, promptId } = useParams();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [viewMode, setViewMode] = useState<"table" | "grid">("table");

  const { data: FindOneProject, isLoading } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  const GetAllPrompts = FindOneProject?.prompts;

  //   const { mutate: deleteVersions } = useMutation(deleteDataVersions, {
  //     onSuccess: (res) => {
  //       queryClient.invalidateQueries("GetAllVersions");
  //       displaySuccessMessage(res.data.message);
  //     },
  //     onError: (err) => handleError(err),
  //   });

  const handleDelete = (items: any) => {
    const idsToDelete =
      items.length >= 2 ? items.map((item: any) => item?._id) : [items[0]?._id];

    confirm({
      msg: "Are you sure you want to delete this?",
      action: () => {
        // deleteVersions({ ids: idsToDelete, datasetId: datasetId });
      },
    });
  };

  const handleView = (data: any) => {
    navigate(`/app/projects/${projectId}/prompts/${promptId}/responses`, {
      replace: true,
    });
  };

  const renderButtons = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        {selectedRow.length >= 2 ? (
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            typeText={"Delete versions"}
            actionOnClick={() => handleDelete(selectedRow)}
          />
        ) : (
          <>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              isLoading={isLoading}
              typeText={"Delete version"}
              actionOnClick={() => handleDelete(selectedRow)}
            />
          </>
        )}
      </Box>
    );
  };

  const segregatedData =
    GetAllPrompts?.find((prompt: any) => prompt?._id === promptId) || {};
  console.log("Filtered Data:", segregatedData);

  return (
    <Box sx={{ padding: "1.3vw 1.5vw 1.3vw 0.3vw" }}>
      <Box display="flex" justifyContent="space-between">
        {/* <Box display="flex" width="40%">
          <SearchContainer
            debounced={true}
            onChange={setSearch}
            value={search}
          />
        </Box> */}
        {/* <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
          }}
        >
          {" "}
          Available versions -{" "}
          {segregatedData.map((d: any) => d?.title).join(", ")}
        </Typography> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            mt: -1,
          }}
        >
          {/* <Box>
            {selectedRow?.length >= 1 &&
              segregatedData?.versions?.length >= 1 &&
              renderButtons()}
          </Box> */}
          <Box>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Create new version"}
              actionOnClick={() => {
                navigate(
                  `/app/projects/${projectId}/prompts/${promptId}/responses`
                );
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Table
          data={segregatedData?.versions}
          columns={columns}
          loading={isLoading}
          is_Id={true}
          renderButtons={renderButtons}
          onRowSelectionChange={(selected: any) => {
            setSelectedRow(selected || null);
          }}
          onViewClick={(item: any) => handleView(item)}
          pagination={{
            totalCount: segregatedData?.versions?.length,
            pageCount: limit,
            setPageCount: setLimit,
            page: offset / limit,
            setPage: (page) => {
              const newOffset = page * limit;
              setOffset(newOffset);
            },
          }}
          onPageChangeCallback={(paginationData) => {
            setOffset(paginationData.offset);
            setLimit(paginationData.limit);
          }}
        />
      </Box>
    </Box>
  );
}

const columns = [
  { key: "number", title: "Version" },
  {
    key: "llm-models",
    title: "LLM Models",
    render: (e: any) => {
      return e?.models?.length;
    },
  },
  //   {
  //     key: "params",
  //     title: "Dataset Versions",
  //     render: (e: any) => {
  //       return <VersionParamsCard key={"params-card"} params={e.params} />;
  //     },
  //   },
  {
    key: "datasetLength",
    title: "Dataset Records",
    render: (e: any) => {
      return e?.datasets?.length;
    },
  },
  {
    key: "runStatus",
    title: "Status",
  },
];
