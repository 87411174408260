import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import { projectUseCase } from "../../auth/utils/AuthUtils";
import AutocompleteGeneric from "../../shared/components/Autocomplete/Autocomplete";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../shared/components/Errors/HandleError";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import DialogWrapper from "../../shared/components/Wrapper/DialogWrapper";
import { addProject, updateProject } from "../services/ProjectService";

const projectSchema = yup.object({
  title: yup
    .string()
    .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric characters are allowed")
    .required("Project title is required"),
  tag: yup
    .string()
    .matches(/^[A-Za-z0-9-\s]+$/, "Only alphanumeric - characters are allowed")
    .required("Project tag is required"),
  useCase: yup.string().required("Use Case is required"),
  // description: yup.string(),
  // // .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric characters are allowed")
  // // .required("Project description is required"),
});

export default function AddProjectForm({ open, setOpen, data }: any) {
  // console.log(data, "Form Data");
  const queryClient = useQueryClient();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(projectSchema),
    defaultValues: {
      title: data?.title || "",
      tag: data?.tag || "",
      useCase: data?.useCase || "SENTIMENT_ANALYSIS",
      // description: data?.description || "",
    },
  });

  const { mutate, isLoading } = useMutation(data ? updateProject : addProject, {
    onSuccess: () => {
      {
        data
          ? toast.success("Project Updated  Successfully")
          : toast.success("Project Added  Successfully");
      }

      queryClient.invalidateQueries("projects");
      setOpen(false);
      reset();
    },
    onError: (err) => handleError(err),
  });

  const onSubmit = (value: any) => {
    const mutationData = {
      title: value.title,
      tag: value.tag,
      useCase: value.useCase,
      description: value.description,
    };

    if (data) {
      mutate({
        data: mutationData,
        id: data._id,
      });
    } else {
      mutate(mutationData);
    }
  };

  return (
    <DialogWrapper
      open={open}
      setOpen={() => {
        setOpen(false);
        reset();
      }}
      title={data ? "Update Project" : "Create a Project"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextField
          formateType={Formate.Alphanumeric}
          control={control}
          label="Project Title"
          name="title"
          required
          placeholder="Enter a project title"
          backgroundColor="#FFFFFF"
        />
        <Typography
          variant="body1"
          sx={{
            fontStyle: "italic",
            fontWeight: 300,
            color: "#121212B2",
            mb: 2,
          }}
        >
          Ex - Academic Research Paper Summarization, E-Commerce Product
          Descriptions
        </Typography>
        <TextField
          formateType={Formate.Tag}
          control={control}
          label="Tag"
          name="tag"
          required
          placeholder="Enter a unique case-sensitive tag for this project"
          backgroundColor="#FFFFFF"
        />
        <Typography
          variant="body1"
          sx={{
            fontStyle: "italic",
            fontWeight: 300,
            color: "#121212B2",
            mb: 2,
          }}
        >
          Ex - chatbot-test, academic-paper-evaluation, question-generation
        </Typography>
        <AutocompleteGeneric
          name="useCase"
          control={control}
          options={projectUseCase}
          label="Project Category"
          required
          placeholder="Select a project category"
          backgroundColor="#FFFFFF"
        />
        {/* <SingleSelect
          control={control}
          required
          name="useCase"
          label="Use Case"
          options={projectUseCase}
          defaultData={"SENTIMENT_ANALYSIS"}
          placeholder="Select a project category"
        /> */}
        <Typography
          variant="body1"
          sx={{
            fontStyle: "italic",
            fontWeight: 300,
            color: "#121212B2",
            mb: 2,
          }}
        >
          Ex - Medical, Legal, Marketing
        </Typography>

        {/* <TextField
          formateType={Formate.EmailAddress}
          control={control}
          label="Description"
          name="description"
          required
          placeholder="Enter Description"
          backgroundColor="#FFFFFF"
          multiline={true}
        /> */}

        <Box sx={{ display: "flex", textAlign: "center", mt: 2, gap: "15px" }}>
          <MultiTypeButton
            buttonType={ButtonType.Cancel}
            typeText={"Cancel"}
            actionOnClick={() => setOpen(false)}
          />

          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            isLoading={isLoading}
            typeText={"Submit"}
            actionOnClick={() => {
              handleSubmit(onSubmit)();
            }}
          />
        </Box>
      </Box>
    </DialogWrapper>
  );
}
