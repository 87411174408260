import { Box } from "@mui/material";
import React from "react";
import DatasetSetup from "../../modules/dataset/components/DatasetSetup";

export default function Dataset() {
  return (
    <Box>
      <DatasetSetup />
    </Box>
  );
}
