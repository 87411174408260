import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

interface SkeletonTableProps {
  rows?: number; // Number of rows to display
  columns?: number; // Number of columns to display
}

export default function SkeletonTable({
  rows = 5,
  columns = 5,
}: SkeletonTableProps) {
  return (
    <TableContainer component={Box}>
      <Table>
        {/* Table Body */}
        <TableBody>
          {[...Array(rows)].map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {[...Array(columns)].map((_, colIndex) => (
                <TableCell key={colIndex}>
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    animation="wave" // Add wave animation
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
