import { UploadFile } from "@mui/icons-material";
import audio from "./icons/audio-file.png";
import DashboardIcon from "./icons/Dashboard";
import Dashboard from "./icons/Dashboard-Icon.png";
import Configuration from "./icons/Datasets-Icon.png";
import email from "./icons/email.svg";
import excel from "./icons/excel.svg";
import File from "./icons/File Send.png";
import csv from "./icons/file.svg";
import githubLogo from "./icons/github.svg";
import googleLogo from "./icons/googleLogo.png";
import Grades1 from "./icons/grades1.png";
import Grades2 from "./icons/grades2.png";
import Grades3 from "./icons/grades3.png";

import llmLoader from "./gif/LLMLoader.gif";
import chat from "./icons/Chat.svg";
import downloadLight from "./icons/download_light.svg";
import DownloadIcon from "./icons/downloadIcon.png";
import expandArrow from "./icons/expandArrow.svg";
import hide from "./icons/eye.svg";
import imageFile from "./icons/image.svg";
import lock from "./icons/lock.png";
import Memo from "./icons/memo-pad 4.png";
import lightMoon from "./icons/moon-icon.svg";
import Help from "./icons/NeedHelp-Icon.png";
import pin from "./icons/office-push-pin 1.svg";
import org1 from "./icons/Org-Temp-1.png";
import org2 from "./icons/Org-temp-2.png";
import PageNotF from "./icons/PageNotFound.png";
import pdf from "./icons/pdf.png";
import Edit from "./icons/Pen 2.png";
import {
  default as Datasets,
  default as Projects,
} from "./icons/Projects-Icon.png";
import promptkeyLogo from "./icons/promptkeyLogo.svg";
import namedLogo from "./icons/Propmtkey-named-logo.svg";
import Documentation from "./icons/Settings-Icon.png";
import SignOut from "./icons/sign-out.png";
import star from "./icons/Star.svg";
import lightSun from "./icons/sun-icon.svg";
import Toggle from "./icons/Toggle.png";
import TrashBin from "./icons/Trash Bin Trash.png";
import upDownArrow from "./icons/up-down.svg";
import uploadedFile from "./icons/upload-file.svg";
import video from "./icons/video.png";
import wrapperPatternDark from "./images/Background.png";
import emptyScreen from "./images/EmptyScreenIllustration.png";
import internalServerError from "./images/InternalServerError.png";
import internalServerErrorLight from "./images/InternalServerError_L.png";
import PageUnderCon from "./images/pageUnderConstruction.png";
import wrapperPattern from "./images/wrapperPattern.png";
import { orangeVerified } from "./lottie/orangeVerified";

export const Icons = {
  promptkeyLogo,
  wrapperPattern,
  namedLogo,
  email,
  googleLogo,
  githubLogo,
  Dashboard,
  Projects,
  Datasets,
  Configuration,
  Grades1,
  Grades2,
  Grades3,
  Documentation,
  Help,
  org1,
  org2,
  Toggle,
  excel,
  audio,
  UploadFile,
  imageFile,
  csv,
  video,
  pdf,
  lock,
  DownloadIcon,
  uploadedFile,
  DashboardIcon,
  TrashBin,
  Edit,
  lightSun,
  lightMoon,
  File,
  SignOut,
  Memo,
  upDownArrow,
  hide,
  expandArrow,
  chat,
  star,
  pin,
  downloadLight,
  llmLoader,
};
export const Images = {
  wrapperPattern,
  emptyScreen,
  wrapperPatternDark,
  internalServerError,
  internalServerErrorLight,
  PageUnderCon,
  PageNotF,
};
export const Lottie = { orangeVerified };
