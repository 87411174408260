import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { displaySuccessMessage } from "../../../app/services";
import AddProjectForm from "../../project/components/AddProjectForm";
import { useConfirm } from "../../project/components/confirmDialog";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../shared/components/Errors/HandleError";
import ProjectsGrid from "../../shared/components/Grid/grid";
import Table from "../../shared/components/Table";
import {
  deleteDataVersions,
  getDatasetVersionDetails,
} from "../services/DatasetService";
import VersionParamsCard from "./UploadedCards/VersionParamsCard";

export default function DatasetVersionTable() {
  const { datasetId, projectId } = useParams();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [viewMode, setViewMode] = useState<"table" | "grid">("table");

  const { data: GetAllVersionDatasets, isLoading } = useQuery(
    ["GetAllVersions", datasetId, { search, limit, offset }],
    getDatasetVersionDetails
  );

  const { mutate: deleteVersions } = useMutation(deleteDataVersions, {
    onSuccess: (res) => {
      queryClient.invalidateQueries("GetAllVersions");
      displaySuccessMessage(res.data.message);
    },
    onError: (err) => handleError(err),
  });

  const handleDelete = (items: any) => {
    const idsToDelete =
      items.length >= 2 ? items.map((item: any) => item?._id) : [items[0]?._id];

    confirm({
      msg: "Are you sure you want to delete this?",
      action: () => {
        deleteVersions({ ids: idsToDelete, datasetId: datasetId });
      },
    });
  };

  const handleView = (data: any) => {
    navigate(`/app/projects/${data?._id}/prompts`, { replace: true });
  };

  const renderButtons = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        {selectedRow.length >= 2 ? (
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            typeText={"Delete versions"}
            actionOnClick={() => handleDelete(selectedRow)}
          />
        ) : (
          <>
            {/* <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Edit Version"}
              actionOnClick={() => setOpen2(true)}
            /> */}

            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              isLoading={isLoading}
              typeText={"Delete version"}
              actionOnClick={() => handleDelete(selectedRow)}
            />

            <AddProjectForm
              open={open2}
              setOpen={setOpen2}
              data={GetAllVersionDatasets?.data[0]}
            />
          </>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ padding: "1.3vw 1.5vw 1.3vw 0.3vw" }}>
      <Box display="flex" justifyContent="space-between">
        {/* <Box display="flex" width="40%">
          <SearchContainer
            debounced={true}
            onChange={setSearch}
            value={search}
          />
        </Box> */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
          }}
        >
          {" "}
          Available versions -{" "}
          {GetAllVersionDatasets?.data?.map((d: any) => d?.tag).join(", ")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            mt: -1,
          }}
        >
          <Box>
            {selectedRow?.length >= 1 &&
              GetAllVersionDatasets?.data?.length >= 1 &&
              renderButtons()}
          </Box>
          <Box>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Create new version"}
              actionOnClick={() => {
                if (projectId) {
                  navigate(
                    `/app/projects/${projectId}/datasets/${datasetId}/add-dataset-version`
                  );
                } else {
                  navigate(`/app/datasets/${datasetId}/add-dataset-version`);
                }
              }}
            />
            <AddProjectForm open={open} setOpen={setOpen} data={null} />
          </Box>
          {/* <Box>
            <Grid>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <ToggleButtonGroup
                  value={viewMode}
                  exclusive
                  onChange={(e, newViewMode) =>
                    newViewMode && setViewMode(newViewMode)
                  }
                  sx={{ marginLeft: "20px" }}
                >
                  <ToggleButton
                    value="table"
                    sx={{
                      ...getToggleButtonStyles(viewMode === "table"),
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <TableRows />
                  </ToggleButton>
                  <ToggleButton
                    value="grid"
                    sx={{
                      ...getToggleButtonStyles(viewMode === "grid"),
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <Apps />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </Box> */}
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        {viewMode === "table" ? (
          <Table
            data={GetAllVersionDatasets?.data || []}
            columns={columns}
            loading={isLoading}
            is_Id={true}
            renderButtons={renderButtons}
            onRowSelectionChange={(selected: any) => {
              setSelectedRow(selected || null);
            }}
            onViewClick={(item: any) => {}}
            pagination={{
              totalCount: GetAllVersionDatasets?.total,
              pageCount: limit,
              setPageCount: setLimit,
              page: offset / limit,
              setPage: (page) => {
                const newOffset = page * limit;
                setOffset(newOffset);
              },
            }}
            onPageChangeCallback={(paginationData) => {
              setOffset(paginationData.offset);
              setLimit(paginationData.limit);
            }}
          />
        ) : (
          <ProjectsGrid
            paginatedData={GetAllVersionDatasets?.data || []}
            handleDelete={() => handleDelete(selectedRow)}
            handleEdit={() => setOpen2(true)}
            onViewClick={(item: any) => handleView(item)}
          />
        )}
      </Box>
    </Box>
  );
}

const columns = [
  { key: "versionNumber", title: "Version" },
  {
    key: "version-tag",
    title: "Tag",
    render: (e: any) => {
      return e?.tag;
    },
  },
  {
    key: "params",
    title: "Parameters",
    render: (e: any) => {
      return <VersionParamsCard key={"params-card"} params={e.params} />;
    },
  },
  {
    key: "noOfRecords",
    title: "No of records",
  },
];
