import VerifyEmailDetails from "../../modules/auth/components/VerifyEmailDetails";
import OnboardingPageWrapper from "../../modules/shared/components/Wrapper/PageWrapper";

export default function VerifyEmail() {
  return (
    <OnboardingPageWrapper>
      <VerifyEmailDetails />
    </OnboardingPageWrapper>
  );
}
