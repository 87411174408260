// Utility functions for Layout

export const formatLayoutData = (data: any) => {
  // Format data here
  return data;
};

export const capitalizeFirstLetter = (text: any) => {
  return text?.charAt(0).toUpperCase() + text?.slice(1).toLowerCase();
};
