import { Box } from "@mui/material";
import DashboardSetup from "../../modules/dashboard/components/DashboardSetup";

export default function Dashboard() {
  return (
    <Box>
      <DashboardSetup />
    </Box>
  );
}
