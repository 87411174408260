import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  StackProps,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { LabelWrapper } from "../LabelWrapper/LabelWrapper";

export type MultiSelectProps = {
  containerProps?: StackProps;
  description?: string;
  control: any;
  options: any;
  defaultData?: string[];
  backgroundColor?: string;
  border?: string;
} & MuiSelectProps;

export const MultiSelect = ({
  name = "",
  label,
  control,
  required = false,
  containerProps,
  description,
  options,
  placeholder,
  defaultData = [],
  backgroundColor,
  border,
}: MultiSelectProps) => {
  return (
    <LabelWrapper
      label={label}
      required={required}
      name={name}
      containerProps={containerProps}
    >
      <FormControl fullWidth required={required}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultData}
          render={({ field }) => (
            <MuiSelect
              {...field}
              multiple
              // displayEmpty
              value={field.value || []}
              IconComponent={ExpandMoreIcon}
              // renderValue={(selected: any) => {
              //   if (Array.isArray(selected)) {
              //     return selected.length > 0
              //       ? selected.join(", ")
              //       : placeholder || "Select options";
              //   }

              //   return placeholder || "Select options";
              // }}
              renderValue={(selected: any) => {
                if (Array.isArray(selected)) {
                  const selectedLabels = selected
                    .map((value) => {
                      const matchedOption = options.find(
                        (option: any) =>
                          option?.value === value || option === value
                      );
                      return matchedOption?.label || value;
                    })
                    .filter((label) => !!label);

                  return selectedLabels.length > 0
                    ? selectedLabels.join(", ")
                    : placeholder;
                }

                return (
                  <Typography
                    variant="h6"
                    sx={{
                      opacity: 0.2,
                    }}
                  >
                    {placeholder}
                  </Typography>
                );
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: "auto",
                    mt: 1,
                    borderRadius: 2,
                    boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
                  },
                },
              }}
              sx={{
                backgroundColor: backgroundColor,
                border,
                // maxHeight: "30vh",
                // overflowY: "auto",
                // scrollbarWidth: "thin",
                // "&::-webkit-scrollbar": {
                //   width: "8px",
                // },
                // "&::-webkit-scrollbar-thumb": {
                //   backgroundColor: "#c1c1c1",
                //   borderRadius: "4px",
                // },
                // "&::-webkit-scrollbar-thumb:hover": {
                //   backgroundColor: "#a0a0a0",
                // },
              }}
            >
              {options.map((option: any, index: any) => (
                <MenuItem
                  key={option?.value ? option?.value : option}
                  value={option?.value ? option?.value : option}
                  disableRipple
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom:
                      index !== options.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                    padding: "0px 16px",
                  }}
                >
                  <ListItemText
                    primary={option?.label ? option?.label : option}
                    sx={{
                      fontWeight: 400,
                      fontSize: "20px",
                    }}
                  />
                  <Checkbox
                    checked={
                      field.value &&
                      field.value.indexOf(
                        option?.value ? option?.value : option
                      ) > -1
                    }
                  />
                </MenuItem>
              ))}
            </MuiSelect>
          )}
        />
      </FormControl>
      {description && <Typography variant="caption">{description}</Typography>}
    </LabelWrapper>
  );
};
