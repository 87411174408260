// Utility functions for Auth

export const formatAuthData = (data: any) => {
  // Format data here
  return data;
};

export const employeeCountOptions = [
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1,000",
  "1,001-5,000",
  "5,001-10,000",
  "10,001+",
];

export const projectUseCase = [
  {
    label: "Sentiment Analysis",
    value: "SENTIMENT_ANALYSIS",
  },
  {
    label: "Image Classification",
    value: "IMAGE_CLASSIFICATION",
  },
  {
    label: "Object Detection",
    value: "OBJECT_DETECTION",
  },
  {
    label: "Machine Translation",
    value: "MACHINE_TRANSLATION",
  },
  {
    label: "Speech Recognition",
    value: "SPEECH_RECOGNITION",
  },
  {
    label: "Predictive Analysis",
    value: "PREDICTIVE_ANALYSIS",
  },
  {
    label: "Chatbots And Conversational AI",
    value: "CHATBOTS_CONVOLUTIONAL_AI",
  },
  {
    label: "Medical",
    value: "MEDICAL",
  },
  {
    label: "Academics",
    value: "ACADEMICS",
  },
  {
    label: "Legal",
    value: "LEGAL",
  },
  {
    label: "Marketing",
    value: "MARKETING",
  },
  {
    label: "Content Writing",
    value: "CONTENT_WRITING",
  },
  {
    label: "Technology",
    value: "TECHNOLOGY",
  },
  {
    label: "Finance",
    value: "FINANCE",
  },
  {
    label: "E-commerce",
    value: "E_COMMERCE",
  },
  {
    label: "Customer Support",
    value: "CUSTOMER_SUPPORT",
  },
  {
    label: "Education",
    value: "EDUCATION",
  },
  {
    label: "Entertainment",
    value: "ENTERTAINMENT",
  },
  {
    label: "Human Resources",
    value: "HUMAN_RESOURCES",
  },
  {
    label: "Healthcare",
    value: "HEALTHCARE",
  },
  {
    label: "Social Media",
    value: "SOCIAL_MEDIA",
  },
  {
    label: "Research and Development",
    value: "RESEARCH_AND_DEVELOPMENT",
  },
  {
    label: "Travel and Tourism",
    value: "TRAVEL_AND_TOURISM",
  },
  {
    label: "Gaming",
    value: "GAMING",
  },
  {
    label: "Real Estate",
    value: "REAL_ESTATE",
  },
  {
    label: "Retail",
    value: "RETAIL",
  },
  {
    label: "Government and Policy",
    value: "GOVERNMENT_AND_POLICY",
  },
  {
    label: "Non-Profit",
    value: "NON_PROFIT",
  },
  {
    label: "Media and Journalism",
    value: "MEDIA_AND_JOURNALISM",
  },
  {
    label: "Automotive",
    value: "AUTOMOTIVE",
  },
  {
    label: "Fashion and Lifestyle",
    value: "FASHION_AND_LIFESTYLE",
  },
  {
    label: "Manufacturing and Supply Chain",
    value: "MANUFACTURING_AND_SUPPLY_CHAIN",
  },
];

export const promptUseCase = [
  {
    label: "Text Summarization",
    value: "TEXT_SUMMARIZATION",
  },
  {
    label: "Sentiment Analysis",
    value: "SENTIMENT_ANALYSIS",
  },
  {
    label: "Content Generation",
    value: "CONTENT_GENERATION",
  },
  {
    label: "Language Translation",
    value: "LANGUAGE_TRANSLATION",
  },
  {
    label: "Question Answering",
    value: "QUESTION_ANSWERING",
  },
  {
    label: "Code Generation",
    value: "CODE_GENERATION",
  },
  {
    label: "Document Classification",
    value: "DOCUMENT_CLASSIFICATION",
  },
  {
    label: "Creative Writing",
    value: "CREATIVE_WRITING",
  },
  {
    label: "Speech-to-Text Conversion",
    value: "SPEECH_TO_TEXT_CONVERSION",
  },
  {
    label: "Text-to-Speech Conversion",
    value: "TEXT_TO_SPEECH_CONVERSION",
  },
  {
    label: "Entity Recognition",
    value: "ENTITY_RECOGNITION",
  },
  {
    label: "Customer Support Automation",
    value: "CUSTOMER_SUPPORT_AUTOMATION",
  },
  {
    label: "Product Recommendation",
    value: "PRODUCT_RECOMMENDATION",
  },
  {
    label: "Text Moderation",
    value: "TEXT_MODERATION",
  },
  {
    label: "Data Extraction from Text",
    value: "DATA_EXTRACTION_FROM_TEXT",
  },
  {
    label: "Resume Screening",
    value: "RESUME_SCREENING",
  },
  {
    label: "Medical Text Analysis",
    value: "MEDICAL_TEXT_ANALYSIS",
  },
  {
    label: "Legal Document Summarization",
    value: "LEGAL_DOCUMENT_SUMMARIZATION",
  },
  {
    label: "Personalized Learning Systems",
    value: "PERSONALIZED_LEARNING_SYSTEMS",
  },
  {
    label: "Ad Copywriting",
    value: "AD_COPYWRITING",
  },
  {
    label: "Knowledge Base Search",
    value: "KNOWLEDGE_BASE_SEARCH",
  },
  {
    label: "Social Media Content Analysis",
    value: "SOCIAL_MEDIA_CONTENT_ANALYSIS",
  },
  {
    label: "Event Detection from Text",
    value: "EVENT_DETECTION_FROM_TEXT",
  },
  {
    label: "Code Debugging Assistance",
    value: "CODE_DEBUGGING_ASSISTANCE",
  },
];

export const countries = [
  { name: "Afghanistan", code: "AF", flagUrl: "https://flagcdn.com/af.svg" },
  { name: "Albania", code: "AL", flagUrl: "https://flagcdn.com/al.svg" },
  { name: "Algeria", code: "DZ", flagUrl: "https://flagcdn.com/dz.svg" },
  { name: "Andorra", code: "AD", flagUrl: "https://flagcdn.com/ad.svg" },
  { name: "Angola", code: "AO", flagUrl: "https://flagcdn.com/ao.svg" },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    flagUrl: "https://flagcdn.com/ag.svg",
  },
  { name: "Argentina", code: "AR", flagUrl: "https://flagcdn.com/ar.svg" },
  { name: "Armenia", code: "AM", flagUrl: "https://flagcdn.com/am.svg" },
  { name: "Australia", code: "AU", flagUrl: "https://flagcdn.com/au.svg" },
  { name: "Austria", code: "AT", flagUrl: "https://flagcdn.com/at.svg" },
  { name: "Azerbaijan", code: "AZ", flagUrl: "https://flagcdn.com/az.svg" },
  { name: "Bahamas", code: "BS", flagUrl: "https://flagcdn.com/bs.svg" },
  { name: "Bahrain", code: "BH", flagUrl: "https://flagcdn.com/bh.svg" },
  { name: "Bangladesh", code: "BD", flagUrl: "https://flagcdn.com/bd.svg" },
  { name: "Barbados", code: "BB", flagUrl: "https://flagcdn.com/bb.svg" },
  { name: "Belarus", code: "BY", flagUrl: "https://flagcdn.com/by.svg" },
  { name: "Belgium", code: "BE", flagUrl: "https://flagcdn.com/be.svg" },
  { name: "Belize", code: "BZ", flagUrl: "https://flagcdn.com/bz.svg" },
  { name: "Benin", code: "BJ", flagUrl: "https://flagcdn.com/bj.svg" },
  { name: "Bhutan", code: "BT", flagUrl: "https://flagcdn.com/bt.svg" },
  { name: "Bolivia", code: "BO", flagUrl: "https://flagcdn.com/bo.svg" },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    flagUrl: "https://flagcdn.com/ba.svg",
  },
  { name: "Botswana", code: "BW", flagUrl: "https://flagcdn.com/bw.svg" },
  { name: "Brazil", code: "BR", flagUrl: "https://flagcdn.com/br.svg" },
  { name: "Brunei", code: "BN", flagUrl: "https://flagcdn.com/bn.svg" },
  { name: "Bulgaria", code: "BG", flagUrl: "https://flagcdn.com/bg.svg" },
  { name: "Burkina Faso", code: "BF", flagUrl: "https://flagcdn.com/bf.svg" },
  { name: "Burundi", code: "BI", flagUrl: "https://flagcdn.com/bi.svg" },
  { name: "Cabo Verde", code: "CV", flagUrl: "https://flagcdn.com/cv.svg" },
  { name: "Cambodia", code: "KH", flagUrl: "https://flagcdn.com/kh.svg" },
  { name: "Cameroon", code: "CM", flagUrl: "https://flagcdn.com/cm.svg" },
  { name: "Canada", code: "CA", flagUrl: "https://flagcdn.com/ca.svg" },
  {
    name: "Central African Republic",
    code: "CF",
    flagUrl: "https://flagcdn.com/cf.svg",
  },
  { name: "Chad", code: "TD", flagUrl: "https://flagcdn.com/td.svg" },
  { name: "Chile", code: "CL", flagUrl: "https://flagcdn.com/cl.svg" },
  { name: "China", code: "CN", flagUrl: "https://flagcdn.com/cn.svg" },
  { name: "Colombia", code: "CO", flagUrl: "https://flagcdn.com/co.svg" },
  { name: "Comoros", code: "KM", flagUrl: "https://flagcdn.com/km.svg" },
  {
    name: "Congo, Democratic Republic of the",
    code: "CD",
    flagUrl: "https://flagcdn.com/cd.svg",
  },
  {
    name: "Congo, Republic of the",
    code: "CG",
    flagUrl: "https://flagcdn.com/cg.svg",
  },
  { name: "Costa Rica", code: "CR", flagUrl: "https://flagcdn.com/cr.svg" },
  { name: "Croatia", code: "HR", flagUrl: "https://flagcdn.com/hr.svg" },
  { name: "Cuba", code: "CU", flagUrl: "https://flagcdn.com/cu.svg" },
  { name: "Cyprus", code: "CY", flagUrl: "https://flagcdn.com/cy.svg" },
  { name: "Czech Republic", code: "CZ", flagUrl: "https://flagcdn.com/cz.svg" },
  { name: "Denmark", code: "DK", flagUrl: "https://flagcdn.com/dk.svg" },
  { name: "Djibouti", code: "DJ", flagUrl: "https://flagcdn.com/dj.svg" },
  { name: "Dominica", code: "DM", flagUrl: "https://flagcdn.com/dm.svg" },
  {
    name: "Dominican Republic",
    code: "DO",
    flagUrl: "https://flagcdn.com/do.svg",
  },
  { name: "Ecuador", code: "EC", flagUrl: "https://flagcdn.com/ec.svg" },
  { name: "Egypt", code: "EG", flagUrl: "https://flagcdn.com/eg.svg" },
  { name: "El Salvador", code: "SV", flagUrl: "https://flagcdn.com/sv.svg" },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    flagUrl: "https://flagcdn.com/gq.svg",
  },
  { name: "Eritrea", code: "ER", flagUrl: "https://flagcdn.com/er.svg" },
  { name: "Estonia", code: "EE", flagUrl: "https://flagcdn.com/ee.svg" },
  { name: "Eswatini", code: "SZ", flagUrl: "https://flagcdn.com/sz.svg" },
  { name: "Ethiopia", code: "ET", flagUrl: "https://flagcdn.com/et.svg" },
  { name: "Fiji", code: "FJ", flagUrl: "https://flagcdn.com/fj.svg" },
  { name: "Finland", code: "FI", flagUrl: "https://flagcdn.com/fi.svg" },
  { name: "France", code: "FR", flagUrl: "https://flagcdn.com/fr.svg" },
  { name: "Gabon", code: "GA", flagUrl: "https://flagcdn.com/ga.svg" },
  { name: "Gambia", code: "GM", flagUrl: "https://flagcdn.com/gm.svg" },
  { name: "Georgia", code: "GE", flagUrl: "https://flagcdn.com/ge.svg" },
  { name: "Germany", code: "DE", flagUrl: "https://flagcdn.com/de.svg" },
  { name: "Ghana", code: "GH", flagUrl: "https://flagcdn.com/gh.svg" },
  { name: "Greece", code: "GR", flagUrl: "https://flagcdn.com/gr.svg" },
  { name: "Grenada", code: "GD", flagUrl: "https://flagcdn.com/gd.svg" },
  { name: "Guatemala", code: "GT", flagUrl: "https://flagcdn.com/gt.svg" },
  { name: "Guinea", code: "GN", flagUrl: "https://flagcdn.com/gn.svg" },
  { name: "Guinea-Bissau", code: "GW", flagUrl: "https://flagcdn.com/gw.svg" },
  { name: "Guyana", code: "GY", flagUrl: "https://flagcdn.com/gy.svg" },
  { name: "Haiti", code: "HT", flagUrl: "https://flagcdn.com/ht.svg" },
  { name: "Honduras", code: "HN", flagUrl: "https://flagcdn.com/hn.svg" },
  { name: "Hungary", code: "HU", flagUrl: "https://flagcdn.com/hu.svg" },
  { name: "Iceland", code: "IS", flagUrl: "https://flagcdn.com/is.svg" },
  { name: "India", code: "IN", flagUrl: "https://flagcdn.com/in.svg" },
  { name: "Indonesia", code: "ID", flagUrl: "https://flagcdn.com/id.svg" },
  { name: "Iran", code: "IR", flagUrl: "https://flagcdn.com/ir.svg" },
  { name: "Iraq", code: "IQ", flagUrl: "https://flagcdn.com/iq.svg" },
  { name: "Ireland", code: "IE", flagUrl: "https://flagcdn.com/ie.svg" },
  { name: "Israel", code: "IL", flagUrl: "https://flagcdn.com/il.svg" },
  { name: "Italy", code: "IT", flagUrl: "https://flagcdn.com/it.svg" },
  { name: "Jamaica", code: "JM", flagUrl: "https://flagcdn.com/jm.svg" },
  { name: "Japan", code: "JP", flagUrl: "https://flagcdn.com/jp.svg" },
  { name: "Jordan", code: "JO", flagUrl: "https://flagcdn.com/jo.svg" },
  { name: "Kazakhstan", code: "KZ", flagUrl: "https://flagcdn.com/kz.svg" },
  { name: "Kenya", code: "KE", flagUrl: "https://flagcdn.com/ke.svg" },
  { name: "Kiribati", code: "KI", flagUrl: "https://flagcdn.com/ki.svg" },
  { name: "Korea, North", code: "KP", flagUrl: "https://flagcdn.com/kp.svg" },
  { name: "Korea, South", code: "KR", flagUrl: "https://flagcdn.com/kr.svg" },
  { name: "Kuwait", code: "KW", flagUrl: "https://flagcdn.com/kw.svg" },
  { name: "Kyrgyzstan", code: "KG", flagUrl: "https://flagcdn.com/kg.svg" },
  { name: "Laos", code: "LA", flagUrl: "https://flagcdn.com/la.svg" },
  { name: "Latvia", code: "LV", flagUrl: "https://flagcdn.com/lv.svg" },
  { name: "Lebanon", code: "LB", flagUrl: "https://flagcdn.com/lb.svg" },
  { name: "Lesotho", code: "LS", flagUrl: "https://flagcdn.com/ls.svg" },
  { name: "Liberia", code: "LR", flagUrl: "https://flagcdn.com/lr.svg" },
  { name: "Libya", code: "LY", flagUrl: "https://flagcdn.com/ly.svg" },
  { name: "Liechtenstein", code: "LI", flagUrl: "https://flagcdn.com/li.svg" },
  { name: "Lithuania", code: "LT", flagUrl: "https://flagcdn.com/lt.svg" },
  { name: "Luxembourg", code: "LU", flagUrl: "https://flagcdn.com/lu.svg" },
  { name: "Madagascar", code: "MG", flagUrl: "https://flagcdn.com/mg.svg" },
  { name: "Malawi", code: "MW", flagUrl: "https://flagcdn.com/mw.svg" },
  { name: "Malaysia", code: "MY", flagUrl: "https://flagcdn.com/my.svg" },
  { name: "Maldives", code: "MV", flagUrl: "https://flagcdn.com/mv.svg" },
  { name: "Mali", code: "ML", flagUrl: "https://flagcdn.com/ml.svg" },
  { name: "Malta", code: "MT", flagUrl: "https://flagcdn.com/mt.svg" },
  {
    name: "Marshall Islands",
    code: "MH",
    flagUrl: "https://flagcdn.com/mh.svg",
  },
  { name: "Mauritania", code: "MR", flagUrl: "https://flagcdn.com/mr.svg" },
  { name: "Mauritius", code: "MU", flagUrl: "https://flagcdn.com/mu.svg" },
  { name: "Mexico", code: "MX", flagUrl: "https://flagcdn.com/mx.svg" },
  { name: "Micronesia", code: "FM", flagUrl: "https://flagcdn.com/fm.svg" },
  { name: "Moldova", code: "MD", flagUrl: "https://flagcdn.com/md.svg" },
  { name: "Monaco", code: "MC", flagUrl: "https://flagcdn.com/mc.svg" },
  { name: "Mongolia", code: "MN", flagUrl: "https://flagcdn.com/mn.svg" },
  { name: "Montenegro", code: "ME", flagUrl: "https://flagcdn.com/me.svg" },
  { name: "Morocco", code: "MA", flagUrl: "https://flagcdn.com/ma.svg" },
  { name: "Mozambique", code: "MZ", flagUrl: "https://flagcdn.com/mz.svg" },
  { name: "Myanmar", code: "MM", flagUrl: "https://flagcdn.com/mm.svg" },
  { name: "Namibia", code: "NA", flagUrl: "https://flagcdn.com/na.svg" },
  { name: "Nauru", code: "NR", flagUrl: "https://flagcdn.com/nr.svg" },
  { name: "Nepal", code: "NP", flagUrl: "https://flagcdn.com/np.svg" },
  { name: "Netherlands", code: "NL", flagUrl: "https://flagcdn.com/nl.svg" },
  { name: "New Zealand", code: "NZ", flagUrl: "https://flagcdn.com/nz.svg" },
  { name: "Nicaragua", code: "NI", flagUrl: "https://flagcdn.com/ni.svg" },
  { name: "Niger", code: "NE", flagUrl: "https://flagcdn.com/ne.svg" },
  { name: "Nigeria", code: "NG", flagUrl: "https://flagcdn.com/ng.svg" },
  {
    name: "North Macedonia",
    code: "MK",
    flagUrl: "https://flagcdn.com/mk.svg",
  },
  { name: "Norway", code: "NO", flagUrl: "https://flagcdn.com/no.svg" },
  { name: "Oman", code: "OM", flagUrl: "https://flagcdn.com/om.svg" },
  { name: "Pakistan", code: "PK", flagUrl: "https://flagcdn.com/pk.svg" },
  { name: "Palau", code: "PW", flagUrl: "https://flagcdn.com/pw.svg" },
  { name: "Palestine", code: "PS", flagUrl: "https://flagcdn.com/ps.svg" },
  { name: "Panama", code: "PA", flagUrl: "https://flagcdn.com/pa.svg" },
  {
    name: "Papua New Guinea",
    code: "PG",
    flagUrl: "https://flagcdn.com/pg.svg",
  },
  { name: "Paraguay", code: "PY", flagUrl: "https://flagcdn.com/py.svg" },
  { name: "Peru", code: "PE", flagUrl: "https://flagcdn.com/pe.svg" },
  { name: "Philippines", code: "PH", flagUrl: "https://flagcdn.com/ph.svg" },
  { name: "Poland", code: "PL", flagUrl: "https://flagcdn.com/pl.svg" },
  { name: "Portugal", code: "PT", flagUrl: "https://flagcdn.com/pt.svg" },
  { name: "Qatar", code: "QA", flagUrl: "https://flagcdn.com/qa.svg" },
  { name: "Romania", code: "RO", flagUrl: "https://flagcdn.com/ro.svg" },
  { name: "Russia", code: "RU", flagUrl: "https://flagcdn.com/ru.svg" },
  { name: "Rwanda", code: "RW", flagUrl: "https://flagcdn.com/rw.svg" },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    flagUrl: "https://flagcdn.com/kn.svg",
  },
  { name: "Saint Lucia", code: "LC", flagUrl: "https://flagcdn.com/lc.svg" },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    flagUrl: "https://flagcdn.com/vc.svg",
  },
  { name: "Samoa", code: "WS", flagUrl: "https://flagcdn.com/ws.svg" },
  { name: "San Marino", code: "SM", flagUrl: "https://flagcdn.com/sm.svg" },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    flagUrl: "https://flagcdn.com/st.svg",
  },
  { name: "Saudi Arabia", code: "SA", flagUrl: "https://flagcdn.com/sa.svg" },
  { name: "Senegal", code: "SN", flagUrl: "https://flagcdn.com/sn.svg" },
  { name: "Serbia", code: "RS", flagUrl: "https://flagcdn.com/rs.svg" },
  { name: "Seychelles", code: "SC", flagUrl: "https://flagcdn.com/sc.svg" },
  { name: "Sierra Leone", code: "SL", flagUrl: "https://flagcdn.com/sl.svg" },
  { name: "Singapore", code: "SG", flagUrl: "https://flagcdn.com/sg.svg" },
  { name: "Slovakia", code: "SK", flagUrl: "https://flagcdn.com/sk.svg" },
  { name: "Slovenia", code: "SI", flagUrl: "https://flagcdn.com/si.svg" },
  {
    name: "Solomon Islands",
    code: "SB",
    flagUrl: "https://flagcdn.com/sb.svg",
  },
  { name: "Somalia", code: "SO", flagUrl: "https://flagcdn.com/so.svg" },
  { name: "South Africa", code: "ZA", flagUrl: "https://flagcdn.com/za.svg" },
  { name: "South Sudan", code: "SS", flagUrl: "https://flagcdn.com/ss.svg" },
  { name: "Spain", code: "ES", flagUrl: "https://flagcdn.com/es.svg" },
  { name: "Sri Lanka", code: "LK", flagUrl: "https://flagcdn.com/lk.svg" },
  { name: "Sudan", code: "SD", flagUrl: "https://flagcdn.com/sd.svg" },
  { name: "Suriname", code: "SR", flagUrl: "https://flagcdn.com/sr.svg" },
  { name: "Sweden", code: "SE", flagUrl: "https://flagcdn.com/se.svg" },
  { name: "Switzerland", code: "CH", flagUrl: "https://flagcdn.com/ch.svg" },
  { name: "Syria", code: "SY", flagUrl: "https://flagcdn.com/sy.svg" },
  { name: "Taiwan", code: "TW", flagUrl: "https://flagcdn.com/tw.svg" },
  { name: "Tajikistan", code: "TJ", flagUrl: "https://flagcdn.com/tj.svg" },
  { name: "Tanzania", code: "TZ", flagUrl: "https://flagcdn.com/tz.svg" },
  { name: "Thailand", code: "TH", flagUrl: "https://flagcdn.com/th.svg" },
  { name: "Timor-Leste", code: "TL", flagUrl: "https://flagcdn.com/tl.svg" },
  { name: "Togo", code: "TG", flagUrl: "https://flagcdn.com/tg.svg" },
  { name: "Tonga", code: "TO", flagUrl: "https://flagcdn.com/to.svg" },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    flagUrl: "https://flagcdn.com/tt.svg",
  },
  { name: "Tunisia", code: "TN", flagUrl: "https://flagcdn.com/tn.svg" },
  { name: "Turkey", code: "TR", flagUrl: "https://flagcdn.com/tr.svg" },
  { name: "Turkmenistan", code: "TM", flagUrl: "https://flagcdn.com/tm.svg" },
  { name: "Tuvalu", code: "TV", flagUrl: "https://flagcdn.com/tv.svg" },
  { name: "Uganda", code: "UG", flagUrl: "https://flagcdn.com/ug.svg" },
  { name: "Ukraine", code: "UA", flagUrl: "https://flagcdn.com/ua.svg" },
  {
    name: "United Arab Emirates",
    code: "AE",
    flagUrl: "https://flagcdn.com/ae.svg",
  },
  { name: "United Kingdom", code: "GB", flagUrl: "https://flagcdn.com/gb.svg" },
  { name: "United States", code: "US", flagUrl: "https://flagcdn.com/us.svg" },
  { name: "Uruguay", code: "UY", flagUrl: "https://flagcdn.com/uy.svg" },
  { name: "Uzbekistan", code: "UZ", flagUrl: "https://flagcdn.com/uz.svg" },
  { name: "Vanuatu", code: "VU", flagUrl: "https://flagcdn.com/vu.svg" },
  { name: "Vatican City", code: "VA", flagUrl: "https://flagcdn.com/va.svg" },
  { name: "Venezuela", code: "VE", flagUrl: "https://flagcdn.com/ve.svg" },
  { name: "Vietnam", code: "VN", flagUrl: "https://flagcdn.com/vn.svg" },
  { name: "Yemen", code: "YE", flagUrl: "https://flagcdn.com/ye.svg" },
  { name: "Zambia", code: "ZM", flagUrl: "https://flagcdn.com/zm.svg" },
  { name: "Zimbabwe", code: "ZW", flagUrl: "https://flagcdn.com/zw.svg" },
];
