import { Box } from "@mui/material";
import EmptyPage from "../../shared/components/Errors/EmptyPage";

export default function ProjectDashboard() {
  return (
    <Box>
      <EmptyPage
        buttonTitle="This Page not build Yet!"
        tagLine="Coming soon!"
      />
    </Box>
  );
}
