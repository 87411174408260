import RegistrationDetails from "../../modules/auth/components/RegistrationDetails";
import OnboardingPageWrapper from "../../modules/shared/components/Wrapper/PageWrapper";

export default function Registration() {
  return (
    <OnboardingPageWrapper>
      <RegistrationDetails />
    </OnboardingPageWrapper>
  );
}
