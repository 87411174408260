import { Box } from "@mui/material";
import React from "react";
import ProjectSetup from "../../modules/project/components/ProjectSetup";
import { Outlet } from "react-router-dom";

export default function Project() {
  return (
    <Box>
      <ProjectSetup />
    </Box>
  );
}
