import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import TabsContainer from "../shared/components/Tabs/TabsContainer";
import APIKeys from "./components/apiKeys";
import Billing from "./components/billing";
import Profile from "./components/profile";
import Users from "./components/users";

const ConfigurationSetup = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  // console.log("Paths:", pathSegments);

  const configured = pathSegments[pathSegments.length - 1];

  const tabs = [
    {
      key: "profile",
      label: "Profile",
      component: <Profile />,
    },
    {
      key: "users",
      label: "Users",
      component: <Users />,
    },
    {
      key: "billing",
      label: "Billing",
      component: <Billing />,
    },
    {
      key: "apiKeys",
      label: "API keys",
      component: <APIKeys />,
    },
  ];

  return (
    <Box
      sx={{
        padding: "22px 20px 0px 20px",
      }}
    >
      {/* <EmptyPage buttonTitle="This Page not build Yet!" tagLine="Coming soon" /> */}

      <TabsContainer
        tabs={tabs}
        mainTab={configured === "configuration" ? "profile" : configured}
        subTab="app/configuration"
        initial="app/configuration"
        params={"profile"}
      />
    </Box>
  );
};

export default ConfigurationSetup;
