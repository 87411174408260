import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Cookies from "js-cookie";
import Lottie from "lottie-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../../../app/services";
import { Icons } from "../../../assets";
import { orangeLoader } from "../../../assets/lottie/orangeLoader";
import { AuthStore } from "../../../store/general";
import { ColorPalette } from "../../../themes/colorTokens/colorPalette";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import { googleLogin, requestOtp } from "../services/AuthService";

// Define the validation schema
const validationSchema = yup.object({
  EmailAddress: yup
    .string()
    .email("Enter a valid email address")
    .required("Email Address is required"),
});

type GoogleLoginProps = {
  postGoogleLoginDetails: (data: any) => void;
  responseToken: any;
  setFetchingDetails: any;
};

// Helper function for Google login
const handleGoogleLogin = async ({
  responseToken,
  postGoogleLoginDetails,
  setFetchingDetails,
}: GoogleLoginProps) => {
  try {
    setFetchingDetails(true);
    const res = await axios.get(
      "https://www.googleapis.com/oauth2/v2/userinfo",
      {
        headers: {
          Authorization: `Bearer ${responseToken.access_token}`,
        },
      }
    );
    AuthStore.update((s) => {
      s.signupEmailAddress = res.data.email;
      s.userName = res.data.name;
    });
    const data = { token: responseToken.access_token };
    setFetchingDetails(false);
    postGoogleLoginDetails(data);
  } catch (error) {
    setFetchingDetails(false);
    displayErrorMessage("Failed to retrieve user info from Google.");
  }
};

const generateRandomString = (length = 16) => {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
};

const clientID = process.env.REACT_APP_GIT_AUTH_CLIENT_ID;
const redirectURI = `${process.env.REACT_APP_GITHUB_REDIRECT_URL}/auth/github`;
const state = generateRandomString();

const loginWithGitHub = () => {
  window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientID}&response_type=code&scope=repo&redirect_uri=${redirectURI}&state=${state}&scope=user:email`;
};

export default function LoginDetails() {
  const navigate = useNavigate();
  const [fetchingDetails, setFetchingDetails] = useState(false);

  AuthStore.update((s) => {
    s.signupEmailAddress = null;
  });

  const { mutate: postGoogleLoginDetails, isLoading: googleLoader } =
    useMutation(googleLogin, {
      onSuccess: (res) => {
        displaySuccessMessage(res.message);
        if (res.isAccountVerified) {
          Cookies.set("access_token", res.access_token);
          navigate("/app");
        } else {
          Cookies.set("refresh_Token", res.access_token);
          navigate("/register");
          displayErrorMessage("Please register into platform");
        }
      },
      onError: (error) => {
        displayErrorMessage("Google Login Failed");
        navigate("/signup");
      },
    });

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (responseToken) => {
      handleGoogleLogin({
        responseToken,
        postGoogleLoginDetails,
        setFetchingDetails,
      });
    },
    onError: () => {
      displayErrorMessage("Google Login Failed");
    },
  });

  const { mutate: GetOTP, isLoading: loginLoader } = useMutation(requestOtp, {
    onSuccess: (res: any) => {
      AuthStore.update((s) => {
        s.signupEmailAddress = getValues("EmailAddress");
      });
      Cookies.set("refresh_Token", res.access_token);
      navigate("/otp-verification");
      displaySuccessMessage(res.message);
    },
    onError: (error) => {
      displayErrorMessage(error);
      navigate("/signup");
    },
  });

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    GetOTP({
      type: "LOGIN",
      value: data.EmailAddress,
    });
  };

  if (googleLoader || fetchingDetails) {
    return (
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2">Please Wait....</Typography>
        <Lottie
          animationData={orangeLoader}
          loop={true}
          autoplay={true}
          style={{ width: "150px", height: "150px" }} // Fixed size for better presentation
        />
      </Box>
    );
  }

  return (
    <Box>
      <img src={Icons.namedLogo} alt="loading..." width={"60%"} />
      <Stack my={"3vh"}>
        <Typography variant="h3">Welcome back!</Typography>
        <Typography variant="subtitle2">
          Log in to continue using your account.
        </Typography>
      </Stack>

      <TextField
        formateType={Formate.EmailAddress}
        control={control}
        name="EmailAddress"
        label="Email Address"
        required
        placeholder="Enter your email address"
      />

      <Stack gap={"1vh"} marginTop={"2vh"}>
        <MultiTypeButton
          buttonType={ButtonType.Gradient}
          actionOnClick={handleSubmit(onSubmit)}
          typeText="Login"
          isLoading={loginLoader}
          key={"log-in"}
        />

        <MultiTypeButton
          buttonType={ButtonType.Google}
          actionOnClick={loginWithGoogle}
          typeText="Login with Google"
          icon={Icons.googleLogo}
          key={"log-in-google"}
        />

        <MultiTypeButton
          buttonType={ButtonType.GitHub}
          actionOnClick={() => {
            loginWithGitHub();
          }}
          typeText="Login with Github"
          icon={Icons.githubLogo}
          key={"log-in-github"}
        />
      </Stack>

      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2vh",
        }}
      >
        <Typography variant="subtitle2">New User?</Typography>
        <Button
          variant="text"
          onClick={() => {
            navigate("/signup");
          }}
        >
          <Typography
            variant="subtitle2"
            color={ColorPalette.BrandColors.OrangeLight}
            fontWeight="bold"
          >
            Sign Up
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
