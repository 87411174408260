import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
// import { promptCategory } from "../../auth/utils/AuthUtils";
import { promptUseCase } from "../../auth/utils/AuthUtils";
import AutocompleteGeneric from "../../shared/components/Autocomplete/Autocomplete";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import { StyledContainer } from "../styles";

const projectSchema = yup.object({
  name: yup
    .string()
    .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric characters are allowed")
    .required("Project title is required"),
  tag: yup
    .string()
    .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric characters are allowed")
    .required("Project tag is required"),
  promptCategory: yup.string().required("Use Case is required"),
});

const PromptDetails = ({
  onValid,
  finalData,
}: {
  onValid: (isValid: boolean) => void;
  finalData: any;
}) => {
  const initialData = JSON.parse(localStorage.getItem("PromptDetails") || "{}");

  const { control, watch, formState, setValue } = useForm({
    resolver: yupResolver(projectSchema),
    mode: "onChange",
    defaultValues: {
      name: initialData.name || "",
      tag: initialData.tag || "",
      promptCategory: initialData.promptCategory || "CREATIVE_WRITING",
    },
  });

  const name = watch("name");
  const promptCategoryValue = watch("promptCategory");
  const tag = watch("tag");

  const checkValidity = () => {
    if (name && tag) {
      onValid(true);
    } else {
      onValid(false);
    }
  };

  useEffect(() => {
    checkValidity();
    finalData({ name, tag, promptCategory: promptCategoryValue });
  }, [name, tag, promptCategoryValue]);

  useEffect(() => {
    if (initialData.name) setValue("name", initialData.name);
    if (initialData.tag) setValue("tag", initialData.tag);
    if (initialData.promptCategory)
      setValue("promptCategory", initialData.promptCategory);
  }, [setValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 4,
      }}
    >
      <Typography
        variant="h3"
        fontWeight="600"
        sx={{
          width: "73%",
          textAlign: "left",
          mb: 2,
        }}
      >
        Prompt Details
      </Typography>
      <StyledContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "65%",
          }}
        >
          <TextField
            formateType={Formate.AllowCapsSmallSpaces}
            control={control}
            label="Prompt title"
            name="name"
            required
            placeholder="Enter prompt title..."
            backgroundColor="#FFFFFF"
          />
          <Typography
            sx={{ fontStyle: "italic", color: "grey", marginBottom: "10px" }}
          >
            Ex - Academic Research Paper Summarization, E-Commerce Product
            Descriptions
          </Typography>

          <AutocompleteGeneric
            name="promptCategory"
            control={control}
            options={promptUseCase}
            label="Prompt use case"
            required
            placeholder="Select a project category"
          />

          {/* <SingleSelect
            control={control}
            required
            name="promptCategory"
            label="Prompt Use Case"
            options={promptUseCase}
            defaultData={"CREATIVE_WRITING"}
            placeholder="Sentiment Analysis"
            backgroundColor="#FFFFFF"
          /> */}

          <TextField
            formateType={Formate.EmailAddress}
            control={control}
            label="Tag"
            name="tag"
            required
            placeholder="Enter a tag..."
            backgroundColor="#FFFFFF"
          />
          {/* <Typography
            sx={{ fontStyle: "italic", color: "grey", marginBottom: "10px" }}
          >
            Ex - chatbot-test, academic-paper-evaluation, question-generation
          </Typography> */}
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default PromptDetails;
