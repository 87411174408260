import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Images } from "../../../assets";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { getAllDatasets } from "../services/DatasetService";

export default function DatasetSetup() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const { data, isLoading }: any = useQuery(
    ["get-Datasets-details", search, limit, offset],
    () => getAllDatasets({ search, limit, offset, projectId }),
    {
      onSuccess: (res: any) => {
        if (res?.data?.length > 0) {
          if (projectId) {
            navigate(`/app/projects/${projectId}/datasets/all`);
          } else {
            navigate("/app/datasets/all");
          }
        }
      },
    }
  );

  const handleClickAdd = () => {
    if (projectId) {
      navigate(`/app/projects/${projectId}/datasets/create`);
    } else {
      navigate(`/app/datasets/create`);
    }
  };

  if (isLoading) {
    return (
      <>
        <LinearProgress></LinearProgress>
      </>
    );
  }

  return (
    <Box>
      {data?.count === 0 ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
            gap: "20px",
          }}
        >
          <img src={Images.emptyScreen} alt="" width={"32%"} />
          <Typography variant="h1" sx={{ fontWeight: 600 }}>
            {"Add your first Dataset"}
          </Typography>
          <Box width={"15%"}>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Create a Dataset"}
              actionOnClick={handleClickAdd}
              key={"add-dataset-button"}
            />
          </Box>
        </Stack>
      ) : null}
    </Box>
  );
}
