import Cookies from "js-cookie";
import React, { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import APIKeys from "../modules/configuration/components/apiKeys";
import Billing from "../modules/configuration/components/billing";
import Profile from "../modules/configuration/components/profile";
import Users from "../modules/configuration/components/users";
import AllDatasets from "../modules/dataset/components/AllDatasets";
import CreateDataset from "../modules/dataset/components/CreateDataset";
import DatasetsView from "../modules/dataset/components/DatasetsView";
import DatasetVersionTable from "../modules/dataset/components/DatasetVersionTable";
import HomeLayout from "../modules/layout/components/HomeLayout";
import AllProjects from "../modules/project/components/FindAllProjects";
import ProjectConfiguration from "../modules/project/components/ProjectConfiguration";
import ProjectDashboard from "../modules/project/components/ProjectDashboard";
import PromptDatasetNew from "../modules/prompt/components/datasetsComponents/promptDataSetNew";
import EmptyPromptPage from "../modules/prompt/components/EmptyPrompt";
import FindAllPrompts from "../modules/prompt/components/FindAllPrompts";
import PromptVersionTable from "../modules/prompt/components/promptComponents/PromptViewVersionTable";
import PromptsView from "../modules/prompt/components/promptsView";
import Prompts from "../modules/prompt/index";
import Configuration from "../pages/Configuration/Configuration";
import Dashboard from "../pages/Dashboard/Dashboard";
import Dataset from "../pages/Datasets/Dataset";
import Project from "../pages/Projects/Project";
import PageNotFound from "../pages/public/pageNotFound";
import Response from "../pages/Responses/Response";

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const isAuthenticated = !!Cookies.get("access_token");

  return isAuthenticated ? children : <Navigate to="/signup" />;
};

const PromptDatasets = lazy(() => import("../modules/prompt/index"));

const privateRoutes: RouteObject[] = [
  {
    path: "/app",
    element: (
      <PrivateRoute>
        <HomeLayout />
      </PrivateRoute>
    ),
    children: [
      { path: "dashboard", element: <Dashboard /> },
      {
        path: "projects",
        element: <Project />,
      },
      {
        path: "projects/all",
        element: <AllProjects />,
      },
      {
        path: "projects/:projectId/dashboard",
        element: <ProjectDashboard />,
      },
      {
        path: "projects/:projectId/datasets",
        element: <Dataset />,
      },
      {
        path: "projects/:projectId/datasets/all",
        element: <AllDatasets />,
      },
      {
        path: "projects/:projectId/datasets/create",
        element: <CreateDataset />,
      },
      {
        path: "projects/:projectId/datasets/:datasetId",
        element: <DatasetsView />,
        children: [
          {
            path: "detailed-view",
            element: <DatasetVersionTable />,
          },
        ],
      },
      {
        path: "projects/:projectId/configuration",
        element: <ProjectConfiguration />,
      },
      {
        path: "projects/:projectId/prompts",
        element: <EmptyPromptPage />,
      },
      {
        path: "projects/:projectId/prompts/all",
        element: <FindAllPrompts />,
      },
      {
        path: "projects/:projectId/prompts/create",
        element: <Prompts />,
      },
      {
        path: "projects/:projectId/prompts/prompt-datasets",
        element: <PromptDatasets />,
      },
      {
        path: "projects/:projectId/prompts/:promptId",
        element: <PromptsView />,
        children: [
          {
            path: "prompt-detailed-view",
            element: <PromptVersionTable />,
          },
        ],
      },
      {
        path: "projects/:projectId/prompts/:promptId/responses",
        element: <Response />,
      },
      // {
      //   path: "projects/:projectId/prompts/:promptId/response",
      //   element: <PromptView />,
      // },
      { path: "datasets", element: <Dataset /> },
      { path: "datasets/create", element: <CreateDataset /> },
      { path: "datasets/all", element: <AllDatasets /> },
      {
        path: "datasets/:datasetId",
        element: <DatasetsView />,
        children: [
          {
            path: "detailed-view",
            element: <DatasetVersionTable />,
          },
        ],
      },
      {
        path: "/app/projects/:projectId/datasets/:datasetId/add-dataset-version",
        element: <CreateDataset />,
      },
      {
        path: "datasets/:datasetId/add-dataset-version",
        element: <CreateDataset />,
        children: [
          {
            path: "detailed-view",
            element: <DatasetVersionTable />,
          },
        ],
      },
      {
        path: "configuration",
        element: <Configuration />,
        children: [
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "billing",
            element: <Billing />,
          },
          {
            path: "apiKeys",
            element: <APIKeys />,
          },
        ],
      },

      {
        path: "*",
        element: <PageNotFound />,
      },
      { path: "", element: <Navigate to="dashboard" /> },
    ],
  },
  {
    path: "/add-new-dataset",
    element: <PromptDatasetNew />,
  },
];

export default privateRoutes;
