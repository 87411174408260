import { CssBaseline, ThemeProvider } from "@mui/material";
import { ReactNode } from "react";
import { lightTheme } from "./LightTheme";

export const MuiThemeProvider = ({
  children,
  theme,
}: {
  children: ReactNode;
  theme?: any;
}) => {
  return (
    <ThemeProvider theme={theme ?? lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
