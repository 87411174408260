import {
  Box,
  IconButton,
  StackProps,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Icons } from "../../../../assets";
import { UIStore } from "../../../../store/general";
import { LabelWrapper } from "../LabelWrapper/LabelWrapper";
import Spinner from "../Loaders/Spinner";

// Function to extract headers directly from a binary file
const extractCsvHeadersFromFile = async (file: File) => {
  try {
    const arrayBuffer = await file.arrayBuffer();

    const workbook = XLSX.read(arrayBuffer, {
      type: "buffer",
      cellDates: true,
    });
    const sheetNames = workbook.SheetNames;
    if (!sheetNames.length) {
      throw new Error("No sheets found in the file");
    }

    const xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]], {
      header: 1,
    });

    const headers = xlData[0] as string[];

    return headers;
  } catch (error) {
    console.error("Error extracting headers:", error);
    return [];
  }
};

export type FileUploadFieldProps = {
  containerProps?: StackProps;
  description?: string;
  control?: any;
  required: boolean;
  uploadFileExtension: string;
  label: string;
  uploadFileType?: string;
  name: string;
  type: string;
  onUploadComplete: (uploadedFiles: any) => void;
  uploadedFiles?: {
    originalFileName: string;
    key: string;
    mediaType: string;
    createdAt: string;
    url?: string;
  }[];
};

export default function FileUploadField({
  label,
  required,
  name,
  uploadFileExtension,
  uploadFileType,
  containerProps,
  type,
  onUploadComplete,
  uploadedFiles = [],
}: FileUploadFieldProps) {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  const theme = useTheme();

  // Determine if multiple file selection is allowed based on the type
  const isMultipleAllowed = uploadFileType
    ? ["IMAGE", "VIDEO", "AUDIO"].includes(uploadFileType)
    : false;

  const uploadFile = async (
    file: File,
    type: string,
    subType: string | undefined,
    typeId: string
  ) => {
    if (file.size > 50 * 1024 * 1024) {
      toast.error("Upload file size should be less than 50MB");
      return;
    }
    setIsUploading(true);

    const Headers = await extractCsvHeadersFromFile(file);
    localStorage.setItem("ExtractedHeaders", JSON.stringify(Headers));

    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    if (subType) formData.append("subType", subType);
    formData.append("typeId", typeId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/media-object/file-upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`,
          },
        }
      );

      onUploadComplete(response.data);
      setIsUploading(false);
    } catch (error) {
      toast.error("File Upload Failed");
      setIsUploading(false);
    }
  };

  // Handle file selection and validation
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    uploadFiles(selectedFiles);
  };

  const uploadFiles = (files: File[]) => {
    if (!files.length) return;

    try {
      const token = Cookies.get("access_token");
      if (!token) {
        toast.error("No access token found");
        return;
      }

      const { id: typeId } = jwtDecode<{ id: string }>(token); // Extract `id` as `typeId`
      const commonData = { type: "DATASET", subType: uploadFileType, typeId };

      // Upload each file
      files.forEach((file) => {
        uploadFile(
          file,
          commonData.type,
          commonData.subType,
          commonData.typeId
        );
      });
      // toast.success("data uploaded successful");
    } catch (error) {
      toast.error("Invalid token. Please log in again.");
    }
  };

  // Upload files to S3, one by one for multiple files

  return (
    <LabelWrapper
      label={label}
      required={required}
      name={name}
      {...containerProps}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={2}
        border={`2px solid ${theme.palette.border.primary}`}
        borderRadius="8px"
        position="relative"
        height="120px"
        bgcolor={isDarkMode ? "#282E33" : "#fafafa"}
        onClick={() =>
          !isUploading && document.getElementById(`file-input-${name}`)?.click()
        }
        sx={{
          ":hover": { bgcolor: isDarkMode ? "#161A1D" : "#f0f0f0" },
          cursor:
            isUploading || (!isMultipleAllowed && uploadedFiles?.length > 0)
              ? "not-allowed"
              : "pointer",
          opacity:
            isUploading || (!isMultipleAllowed && uploadedFiles?.length > 0)
              ? 0.6
              : 1,
        }}
      >
        <input
          accept={uploadFileExtension}
          id={`file-input-${name}`}
          type="file"
          multiple={isMultipleAllowed}
          style={{ display: "none" }}
          onChange={handleFileChange}
          disabled={
            isUploading || (!isMultipleAllowed && uploadedFiles?.length > 0)
          }
        />
        <Box textAlign="center">
          <IconButton color="primary" component="span" disabled={isUploading}>
            {isUploading ? (
              <Spinner boundingBox={false} height={"85px"} width={"85px"} />
            ) : (
              <img src={Icons.uploadedFile} alt="Upload Icon" />
            )}
          </IconButton>
          <Typography variant="subtitle1" color="textSecondary">
            {isUploading ? "" : "Drag and Drop file here "}
            {!isUploading && (
              <Typography
                variant="subtitle2"
                component="span"
                color="#F86B20"
                sx={{ textDecoration: "underline", ml: 0.5 }}
              >
                Choose file
              </Typography>
            )}
          </Typography>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 400,
            opacity: 0.5,
          }}
        >
          Supported formats : CSV, MP3, PNG, JPG
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 400,
            fontStyle: "italic",
            opacity: 0.5,
          }}
        >
          Maximum size: 25 MB
        </Typography>
      </Box> */}
    </LabelWrapper>
  );
}
