import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Icons } from "../../../assets";
import { getOneProject } from "../../project/services/ProjectService";
import QuillEditor from "../../prompt/components/promptcreationComponents/Quill";
import DataGrid from "../../shared/components/Grid/DataGrid";
import { getAllResponses, getAllStatus } from "../services/DataGridService";

export default function ResponseViewSetup() {
  const ref = useRef("");
  const { projectId, promptId } = useParams();
  const [versionId, setVersion] = useState("");
  const [loading, setLoading] = useState(true);
  const [status, SetStatus] = useState("In progress");
  const [data, setData] = useState<any>();
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const { data: statusDetails, isLoading: statusLoading }: any = useQuery(
    ["prompt-responses", projectId, promptId],
    getAllStatus
  );

  const { data: FindResponse, isLoading: responseLoading }: any = useQuery(
    [
      "prompt-responses",
      {
        projectId: projectId,
        promptId: promptId,
        versionId: versionId,
        limit: limit,
        offset: offset,
      },
    ],
    getAllResponses,
    {
      enabled: !!versionId,
      onSuccess: (res: any) => {
        setLoading(false);
        // setData(res);
      },
    }
  );

  const { data: FindOneProject } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  const prompt = FindOneProject?.prompts.find((p: any) => p?._id == promptId);
  const version = prompt?.versions?.find((v: any) => v?._id == versionId);

  const transformedArray = FindResponse?.map((item: any) => ({
    DatasetRecord: item?.DatasetRecord,
    models: item?.models?.map((m: any) => ({
      model: m?.model,
      response: m?.responses,
    })),
  }));

  // console.log(FindResponse);

  const outputArray = transformedArray?.map((input: any) => {
    const output: any = {
      DatasetRecord: input?.DatasetRecord,
    };

    input?.models?.forEach((modelObj: any) => {
      const providerName = modelObj?.model?.providerName;
      const versionName = modelObj?.model?.versionName;
      const key = `${providerName}/${versionName}`;
      output[key] = { response: modelObj?.response };
    });

    return output;
  });

  // console.log("outputArray", outputArray?.length);
  // console.log("outputArray :", outputArray);

  const convertToCSV = (data: any) => {
    // Extract keys for the CSV header
    const headers = Object.keys(data[0]).join(",") + "\n";

    // Map data to CSV rows
    const rows = data
      .map((row: any) => {
        return Object.values(row)
          .map((value) => {
            // Check if the value is an object, and stringify if necessary
            if (typeof value === "object" && value !== null) {
              return `"${JSON.stringify(value)}"`; // Convert object to JSON string
            }
            return `"${value}"`; // Quote values to handle commas in strings
          })
          .join(",");
      })
      .join("\n");

    return headers + rows;
  };

  const downloadCSV = () => {
    if (!outputArray || outputArray.length === 0) {
      alert("No data to export!");
      return;
    }

    const csvData = convertToCSV(outputArray);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `responses.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (statusDetails && Array.isArray(statusDetails.promptStatus)) {
      const prompt = statusDetails?.promptStatus.find((item: any) => {
        return item?.promptId.toString() == promptId;
      });
      setData(prompt);
      const checkStatus =
        prompt?.versions?.length > 0
          ? prompt?.versions?.[0]?.runStatus !== "Done"
          : prompt?.versions?.runStatus !== "Done";
      if (checkStatus) {
        setLoading(true);
      } else {
        SetStatus("Done");
        const versionId =
          prompt?.versions?.length > 0
            ? prompt?.versions?.[0]?._id
            : prompt?.versions?._id;
        setVersion(versionId);
      }
    }
  }, [statusDetails]);

  const handleChange = useCallback((quillText: string) => {}, []);

  const modifiedData = outputArray?.map((obj: any) => {
    const newObj: any = {};
    for (const [key, value] of Object?.entries(obj)) {
      const newKey = key?.replace(/\./g, "_");
      newObj[newKey] = value;
    }
    return newObj;
  });

  if (statusLoading) {
    return <LinearProgress></LinearProgress>;
  }

  return (
    <Box>
      <Stack>
        <Box sx={{ mx: "10px", my: "10px" }}>
          <>
            {responseLoading || loading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Stack direction={"row"}>
                  <Typography variant="h3">Prompt Title:</Typography>
                  <Typography variant="h3">{data?.title}</Typography>
                </Stack>
                <img src={Icons.llmLoader} width={"20%"} alt="" />

                <Typography variant="h4">
                  Response Generation Status: {status}, Please come after some
                  time...........
                </Typography>
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    padding: "0 1.4vw",
                  }}
                >
                  <Stack
                    direction={"row"}
                    sx={{ border: "1px solid #1212121A" }}
                  >
                    <Box>
                      <QuillEditor
                        border={false}
                        height="20vh"
                        defaultHtml={version?.datasets?.[0]?.description}
                        placeholder="Enter user message"
                        onChange={(quillText: string) =>
                          handleChange(quillText)
                        }
                        mentions={[
                          {
                            mentionChar: "{",
                            values: [{ label: "sai", value: "kiran" }],
                          },
                        ]}
                      />
                    </Box>
                    <Stack
                      direction={"row"}
                      sx={{
                        width: "8%",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      {/* <IconButton sx={{ border: "2px soli red" }}>
                <BorderColorIcon sx={{ fontSize: "18px" }} />
              </IconButton>
              <Typography>Edit Prompt</Typography> */}
                    </Stack>
                  </Stack>
                </Box>
                <DataGrid
                  rowsData={modifiedData}
                  loading={loading || responseLoading}
                  setLimit={setLimit}
                  setOffset={setOffset}
                />
              </Box>
            )}
          </>

          {/* <QuillEditorWithMentions
            defaultHtml={ref.current}
            onChange={(quillText: string) => {
              ref.current = quillText;
            }}
            placeholder="Please enter text"
            key={"Quill-mentions"}
            mentions={[
              {
                mentionChar: "@",
                values: [{ id: 1, value: "varma" }],
              },
            ]}
          /> */}

          {/* {true ? (
            <>
              {" "}
              <Button
                sx={{
                  height: "40px",
                  width: "100px",
                  fontSize: "14px",
                  color: "white",
                  backgroundColor: "#F07946",
                  marginTop: "10px",
                }}
                variant="outlined"
              >
                Re-run
              </Button>
            </>
          ) : (
            <>
              {" "}
              <Button
                sx={{
                  width: "100px",
                  height: "40px",
                  fontSize: "14px",
                  color: "white",
                  backgroundColor: "#F07946",
                }}
                variant="outlined"
              >
                Run
              </Button>
            </>
          )} */}
        </Box>
        {/* {loading || responseLoading || isLoading ? (
          <>
            <Box>
              <Stack
                direction={"row"}
                py={"10px"}
                mx={"20px"}
                gap={"20px"}
                height={"200px"}
              >
                <Typography variant="h3">Prompt Title:</Typography>
                <Typography variant="h3">{data?.title}</Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </Box>
          </>
        ) : (
         
        )} */}
      </Stack>
    </Box>

    // <>
    //   <Stack
    //     direction={"row"}
    //     py={"10px"}
    //     mx={"20px"}
    //     gap={"20px"}
    //     height={"200px"}
    //   >
    //     <Typography variant="h3">Prompt Title:</Typography>
    //     <Typography variant="h3">{data?.title}</Typography>
    //   </Stack>
    //   {loading && (
    //     <Typography sx={{ mx: "20px" }} variant="h2">
    //       Data Generation In Progress.......
    //     </Typography>
    //   )}
    //   <Box
    //     sx={{
    //       position: "absolute",
    //       top: "50%",
    //       left: "50%",
    //       transform: "translate(-50%, -50%)",
    //       textAlign: "center",
    //     }}
    //   >
    //     {loading ? (
    //       <CircularProgress color="primary" />
    //     ) : (
    //       <>
    //         <MultiTypeButton
    //           buttonType={ButtonType.Gradient}
    //           typeText={"Download CSV"}
    //           actionOnClick={downloadCSV}
    //           key={"Download CSV"}
    //         />

    //         <Box>
    //           <Typography></Typography>
    //           <Typography>
    //             Total Number of Rows {outputArray?.length}
    //           </Typography>
    //         </Box>
    //       </>
    //     )}
    //   </Box>
    // </>
  );
}
