export const orangeVerified = {
  v: "5.7.1",
  fr: 60,
  ip: 0,
  op: 180,
  w: 332,
  h: 309,
  nm: "MF - Social Elements 36",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Mask",
      parent: 3,
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [218.423, 173.728, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [64.101, 64.101, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-56.5, -7.5],
                    [-9.5, 34.5],
                    [55.5, -44],
                  ],
                  o: [
                    [-56.5, -7.5],
                    [-9.5, 34.5],
                    [55.5, -44],
                  ],
                  v: [
                    [-56.5, -7.5],
                    [-9.5, 34.5],
                    [55.5, -44],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
              _render: true,
            },
            {
              ty: "st",
              c: { a: 0, k: [0.9961, 0.9608, 0.7412, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 31, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
              _render: true,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
              _render: true,
            },
          ],
          nm: "Shape 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
          _render: true,
        },
        {
          ty: "tm",
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 13,
                s: [0],
              },
              { t: 28, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Correct",
      parent: 4,
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [216.559, 171.575, 0], ix: 2 },
        a: { a: 0, k: [217.5, 172.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0.769, 0.769, 0.333], y: [0, 0, 0] },
              t: 13,
              s: [86.864, 86.864, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 28,
              s: [101.964, 101.964, 100],
            },
            {
              i: { x: [0.459, 0.459, 0.833], y: [1, 1, 1] },
              o: { x: [0.7, 0.7, 0.167], y: [0, 0, 0] },
              t: 152,
              s: [101.964, 101.964, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.841, 0.841, 0.167], y: [0, 0, 0] },
              t: 168,
              s: [129.664, 129.664, 100],
            },
            { t: 180, s: [93.664, 93.664, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [26.988999999999997, -28.296],
                    [32.332, -24.367],
                    [35.736000000000004, -19.628],
                    [34.77, -18.648],
                    [-2.4739999999999998, 26.334],
                    [-6.2829999999999995, 29.001],
                    [-7.721, 27.909999999999997],
                    [-34.198, 7.434],
                    [-35.737, 4.522],
                    [-31.506999999999998, -1.633],
                    [-26.671000000000003, -4.843],
                    [-10.171999999999999, 8.539],
                    [-6.3420000000000005, 9.440000000000001],
                    [24.361, -28.001],
                    [26.020999999999997, -29.000999999999998],
                  ],
                  o: [
                    [29.759, -26.379],
                    [35.73500000000001, -21.215],
                    [34.771, -18.647],
                    [34.77, -18.648],
                    [-4.596, 28.741],
                    [-7.6049999999999995, 28.013],
                    [-9.634, 26.445999999999998],
                    [-35.457, 6.369],
                    [-33.281, 0.6739999999999999],
                    [-28.421999999999997, -4.843],
                    [-24.647000000000002, -3.117],
                    [-7.885999999999999, 9.802999999999999],
                    [-4.27, 7.018000000000001],
                    [24.921, -28.808],
                    [26.906, -28.366999999999997],
                  ],
                  v: [
                    [27.066, -28.215],
                    [34.77, -22.195],
                    [34.771, -18.648],
                    [34.77, -18.648],
                    [-3.715, 27.524],
                    [-7.481, 28.106],
                    [-7.827, 27.798],
                    [-34.198, 7.434],
                    [-34.851, 3.122],
                    [-29.548, -3.773],
                    [-25.545, -3.773],
                    [-9.165, 9.165],
                    [-5.471, 8.294],
                    [24.361, -28.001],
                    [26.816, -28.432],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
              _render: true,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.9961, 0.9608, 0.7412, 1],
                ix: 4,
                x: "var $bm_rt;\n$bm_rt = thisComp.layer('Setting').effect('MF - Social Elements 36')('Pseudo/ds3Cd044a99ei-0001');",
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
              _render: true,
            },
            {
              ty: "tr",
              p: { a: 0, k: [218.358, 174.476], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
              _render: true,
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Badge",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [166, 154, 0], ix: 2 },
        a: { a: 0, k: [217.5, 172.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 16.676] },
              t: 0,
              s: [0, 0, 100],
            },
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [1, 1, 0.333], y: [0, 0, 0] },
              t: 15,
              s: [172.1, 172.1, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [1, 1, 0.333], y: [0, 0, 0] },
              t: 27,
              s: [172.1, 172.1, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 47,
              s: [147.7, 147.7, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 54,
              s: [153, 153, 100],
            },
            {
              i: { x: [0.3, 0.3, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 152,
              s: [153, 153, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.86, 0.86, 0.167], y: [0, 0, 0] },
              t: 162,
              s: [165.6, 165.6, 100],
            },
            { t: 180, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [72.701, -4.652],
                    [66.21799999999999, 8.608],
                    [68.911, 23.663],
                    [57.92, 33.242999999999995],
                    [54.626, 48.188],
                    [40.806999999999995, 52.855000000000004],
                    [32.069, 65.425],
                    [17.533, 64.452],
                    [4.651, 72.7],
                    [-8.608, 66.218],
                    [-23.663, 68.911],
                    [-33.242999999999995, 57.92],
                    [-48.189, 54.626000000000005],
                    [-52.856, 40.808],
                    [-65.42500000000001, 32.069],
                    [-64.452, 17.533],
                    [-72.701, 4.652],
                    [-66.218, -8.607],
                    [-68.911, -23.663],
                    [-57.92, -33.242999999999995],
                    [-54.626, -48.188],
                    [-40.808, -52.855000000000004],
                    [-32.07, -65.425],
                    [-17.533, -64.452],
                    [-4.652, -72.701],
                    [8.607, -66.218],
                    [23.663, -68.911],
                    [33.242999999999995, -57.919000000000004],
                    [48.188, -54.626000000000005],
                    [52.855000000000004, -40.807],
                    [65.42500000000001, -32.069],
                    [64.452, -17.533],
                  ],
                  o: [
                    [72.701, 4.652],
                    [64.452, 17.536],
                    [65.424, 32.072],
                    [52.853, 40.811],
                    [48.185, 54.629000000000005],
                    [33.239999999999995, 57.92100000000001],
                    [23.661, 68.911],
                    [8.607, 66.218],
                    [-4.652, 72.7],
                    [-17.535, 64.451],
                    [-32.072, 65.424],
                    [-40.811, 52.854],
                    [-54.629, 48.186],
                    [-57.921, 33.24],
                    [-68.912, 23.661],
                    [-66.218, 8.606],
                    [-72.701, -4.652],
                    [-64.452, -17.535],
                    [-65.424, -32.072],
                    [-52.853, -40.811],
                    [-48.185, -54.629],
                    [-33.24, -57.92100000000001],
                    [-23.661, -68.91199999999999],
                    [-8.607, -66.218],
                    [4.652, -72.701],
                    [17.535, -64.452],
                    [32.072, -65.424],
                    [40.809999999999995, -52.853],
                    [54.629, -48.186],
                    [57.922000000000004, -33.239000000000004],
                    [68.912, -23.661],
                    [66.218, -8.607],
                  ],
                  v: [
                    [72.701, 0],
                    [65.347, 13.005],
                    [67.182, 27.831],
                    [55.396, 37.013],
                    [51.406, 51.408],
                    [37.01, 55.398],
                    [27.829, 67.184],
                    [13.004, 65.348],
                    [0, 72.7],
                    [-13.004, 65.348],
                    [-27.832, 67.183],
                    [-37.013, 55.396],
                    [-51.409, 51.405],
                    [-55.399, 37.009],
                    [-67.183, 27.829],
                    [-65.348, 13.003],
                    [-72.701, 0],
                    [-65.348, -13.004],
                    [-67.183, -27.831],
                    [-55.396, -37.013],
                    [-51.406, -51.409],
                    [-37.009, -55.398],
                    [-27.829, -67.184],
                    [-13.004, -65.348],
                    [0, -72.701],
                    [13.004, -65.348],
                    [27.831, -67.182],
                    [37.013, -55.396],
                    [51.409, -51.405],
                    [55.398, -37.008],
                    [67.183, -27.829],
                    [65.348, -13.003],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
              _render: true,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [1, 0.5569, 0.2275, 1],
                ix: 4,
                x: "var $bm_rt;\n$bm_rt = thisComp.layer('Setting').effect('MF - Social Elements 36')('Pseudo/ds3Cd044a99ei-0002');",
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
              _render: true,
            },
            {
              ty: "tr",
              p: { a: 0, k: [217.5, 172.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
              _render: true,
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
      completed: true,
    },
  ],
  markers: [
    { tm: 0, cm: "", dr: 54 },
    { tm: 568, cm: "", dr: 31 },
  ],
  __complete: true,
};
