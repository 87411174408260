import { Box, styled, Switch } from "@mui/material";

export const StyledContainer = styled(Box)(() => ({
  border: "1px solid #1212121A",
  backgroundColor: "#FFFFFF",
  borderRadius: "15px",
  width: "60vw",
  padding: "1.1875rem",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  gap: "1.25rem",
  minHeight: "50vh",
}));

export const StyledContainerWithoutHeight = styled(Box)(() => ({
  border: "1px solid #1212121A",
  backgroundColor: "#FFFFFF",
  borderRadius: "15px",
  width: "60vw",
  padding: "0.9375rem 2.1875rem",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  gap: "1.25rem",
}));

export const StickyFooter = styled(Box)({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  backgroundColor: "#FFFFFF",
  // boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
  border: "1px solid #1212121A",
  display: "flex",
  zIndex: 1000,
});

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 21,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(15px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary,
        ...theme.applyStyles("dark", {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 18,
    height: 18,
    borderRadius: 9,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));
