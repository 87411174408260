import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { Icons } from "../../../../assets";

const CustomHeader = (
  { column, onLockColumn, onColumnPin, onToggleVisibility, hiddenColumns }: any,
  rowData: any,
  setPresetData: any
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isFirstColumn = column.getColId() === "DatasetRecord";

  // Utility Functions
  const getPresetData = () =>
    JSON.parse(localStorage.getItem("presetData") || "[]");

  const findSelectedPreset = (presets: any[]) =>
    presets.find((p: any) => p?.columnId === column.getColId());

  const updatePresetData = (presetId: string) => {
    const presets = rowData[0]?.[`${column.getColId()}`]?.response || [];
    const presetIndex = presets.findIndex(
      (preset: any) => preset?.presetId === presetId
    );
    const presetData = presets.find(
      (preset: any) => preset?.presetId === presetId
    );

    setPresetData((prevState: any) => {
      const newState = Array.isArray(prevState) ? [...prevState] : [];
      const existingIndex = newState.findIndex(
        (item) => item.columnId === column.getColId()
      );

      const newEntry = {
        columnId: column.getColId(),
        presetId,
        presetIndex,
        temperature: presetData?.preset?.temperature,
      };

      if (existingIndex !== -1) {
        newState[existingIndex] = newEntry;
      } else {
        newState.push(newEntry);
      }

      return newState;
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handlePresetChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updatePresetData(event.target.value);

  const handleColumnAction = (action: () => void) => {
    action();
    handleMenuClose();
  };

  // Action Handlers
  const handleToggleLock = () =>
    handleColumnAction(() =>
      onLockColumn(column.getColId(), !column.getColDef().editable)
    );

  const handlePinLeft = () =>
    handleColumnAction(() => onColumnPin(column.getColId(), "left"));

  const handlePinRight = () =>
    handleColumnAction(() => onColumnPin(column.getColId(), "right"));

  const handleToggleVisibility = () =>
    handleColumnAction(() => onToggleVisibility(column.getColId()));

  const retrievedPresetData = getPresetData();
  const selectedPreset = findSelectedPreset(retrievedPresetData);
  console.log("selectedPreset :", selectedPreset);
  const isHidden = hiddenColumns.includes(column.getColId());

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        cursor: isFirstColumn ? "default" : "pointer",
        border: "2px solid transparent",
      }}
    >
      <Stack
        direction="row"
        gap="10px"
        justifyContent="center"
        alignItems="center"
      >
        <Typography sx={{ color: "#121212CC" }} variant="subtitle1">
          {column?.getColId()}
        </Typography>

        {column?.colDef.lockPosition && !isFirstColumn && (
          <img
            src={Icons.lock}
            width="15px"
            height="15px"
            alt=""
            style={{ marginRight: "15px" }}
          />
        )}
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"10px"}
      >
        {!isFirstColumn && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"5px"}
            sx={{
              height: "30px",
              width: "120px",
              bgcolor: "#FFF1E0",
              borderRadius: "5px",
            }}
          >
            <Typography variant="caption" color="grey">
              {selectedPreset?.temperature ??
                rowData[0]?.[`${column.getColId()}`]?.response?.[0]?.preset
                  ?.temperature}
            </Typography>
            <Typography variant="caption" color="grey">
              temperature
            </Typography>
          </Stack>
        )}

        {!isFirstColumn && (
          <Box>
            <IconButton
              onClick={handleMenuOpen}
              sx={{ padding: 0 }}
              size="small"
            >
              <ArrowDropDownIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Stack padding="20px">
                <Typography variant="h6">Preset Configurations</Typography>
                <RadioGroup
                  value={
                    selectedPreset?.presetId ??
                    rowData[0]?.[`${column.getColId()}`]?.response?.[0]
                      ?.presetId
                  }
                  name="preset-options"
                  onChange={handlePresetChange}
                >
                  {rowData[0]?.[`${column.getColId()}`]?.response?.map(
                    (item: any) => (
                      <FormControlLabel
                        key={item.presetId}
                        value={item.presetId}
                        control={<Radio />}
                        label={`${item.preset.name}, Temperature: ${item.preset.temperature}, Max Tokens: ${item.preset.maxTokens}`}
                      />
                    )
                  )}
                </RadioGroup>
              </Stack>
              <Divider sx={{ mx: "10px" }} />
              <MenuItem onClick={handlePinLeft}>
                <img src={Icons.pin} alt="" style={{ marginRight: "15px" }} />
                <Typography variant="subtitle2">Pin to Left</Typography>
              </MenuItem>
              <MenuItem onClick={handlePinRight}>
                <img src={Icons.pin} alt="" style={{ marginRight: "15px" }} />
                <Typography variant="subtitle2">Pin to Right</Typography>
              </MenuItem>
              <MenuItem onClick={handleToggleLock}>
                {column?.colDef.lockPosition ? (
                  <>
                    <LockOpenIcon sx={{ marginRight: 1, fontSize: "24px" }} />
                    <Typography variant="subtitle2">Unlock Column</Typography>
                  </>
                ) : (
                  <>
                    <img
                      src={Icons.lock}
                      alt=""
                      style={{ marginRight: "15px" }}
                    />
                    <Typography variant="subtitle2">Lock Column</Typography>
                  </>
                )}
              </MenuItem>
              <MenuItem onClick={handleToggleVisibility}>
                {isHidden ? (
                  <>
                    <VisibilityIcon sx={{ marginRight: 1 }} />
                    <Typography variant="subtitle2">Show Column</Typography>
                  </>
                ) : (
                  <>
                    <img
                      src={Icons.hide}
                      alt=""
                      style={{ marginRight: "15px" }}
                    />
                    <Typography variant="subtitle2">Hide Column</Typography>
                  </>
                )}
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default CustomHeader;
