import { Box } from "@mui/material";
import EmptyPage from "../../modules/shared/components/Errors/EmptyPage";

const PageNotFound = () => {
  return (
    <Box>
      <EmptyPage buttonTitle="Page Not Found" tagLine="Page Not Found" />
    </Box>
  );
};

export default PageNotFound;
