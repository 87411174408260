import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import {
  Formate,
  TextField,
} from "../../../shared/components/TextField/TextField";
import DialogWrapper from "../../../shared/components/Wrapper/DialogWrapper";
import { addGradingParameter } from "../../services/utility";

const GradingDialogCard = ({ open, setOpen, data }: any) => {
  const { control, reset, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  const onSubmit = (formData: any) => {
    addGradingParameter(formData);
    setOpen(false);
    reset();
  };

  return (
    <DialogWrapper open={open} setOpen={setOpen} title={"Add Grading"}>
      <Box
        sx={{
          padding: "6px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextField
          formateType={Formate.Alphanumeric}
          control={control}
          label="Parameter Name"
          name="name"
          required
          placeholder="Enter Parameter Name"
          backgroundColor="#F8F8F8"
        />
        <TextField
          formateType={Formate.Alphanumeric}
          control={control}
          label="Description"
          name="description"
          required
          placeholder="Enter Description"
          backgroundColor="#F8F8F8"
        />
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 400,
            fontStyle: "italic",
            opacity: 0.7,
          }}
        >
          Note : With respect to parameter add the specific point you want the
          LM response to be graded on.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "10px",
            mt: 2,
          }}
        >
          <MultiTypeButton
            actionOnClick={() => setOpen(false)}
            //   isLoading={uploadLoader || uploadLoader2}
            typeText="Cancel"
            buttonType={ButtonType.Cancel}
            key={"grading-cancel"}
          />
          <MultiTypeButton
            actionOnClick={handleSubmit(onSubmit)}
            //   isLoading={uploadLoader || uploadLoader2}
            typeText="Submit"
            buttonType={ButtonType.Gradient}
            key={"grading-submit"}
            // disabled={uploadedFiles?.length === 0}
          />
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default GradingDialogCard;
