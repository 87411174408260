import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAllDatasets } from "../../dataset/services/DatasetService";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import Spinner from "../../shared/components/Loaders/Spinner";
import { StyledContainerWithoutHeight } from "../styles";
import PromptDatasetNew from "./datasetsComponents/promptDataSetNew";
import PromptDataSetTable from "./datasetsComponents/promptDataSetTable";

const PromptDataSets = ({
  onValid,
  skip,
  finalData,
}: {
  onValid: (isValid: boolean) => void;
  skip: any;
  finalData: any;
}) => {
  const [view, setView] = useState("default");
  const [dataSet, setDataset] = useState<any>();

  const { projectId } = useParams();

  const { data: GetAllDatasets, isLoading }: any = useQuery("datasets", () =>
    getAllDatasets({ projectId: projectId })
  );

  // useEffect(() => {
  //   const checkPromptDataset = () => {
  //     if (localStorage.getItem("PromptDataset")) {
  //       setView("selectDataset");
  //     }
  //   };

  //   checkPromptDataset();

  //   const handleStorageChange = () => {
  //     checkPromptDataset();
  //   };

  //   window.addEventListener("storage", handleStorageChange);

  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  useEffect(() => {
    const datasetTable = () => {
      if (localStorage.getItem("reloadTab")) {
        setView("selectDataset");
      }
    };
    datasetTable();
  }, []);

  useEffect(() => {
    finalData(dataSet);
    onValid(dataSet?.length >= 1 ? true : false);
  }, [dataSet]);

  const handleFirstClick = useCallback(() => {
    // setView("addNew");
    onValid(true);
    const newTab = window.open("", "_blank");
    if (newTab) {
      // newTab.document.body.innerHTML = "<h1>Loading...</h1>";
      newTab.document.body.innerHTML = `
      <div id="loader" style="display: flex; justify-content: center; align-items: center; height: 100vh;">
        <div class="MuiCircularProgress-root" style="width: 50px; height: 50px;">
          <svg viewBox="22 22 44 44" class="MuiCircularProgress-svg" style="stroke: #1976d2;">
            <circle cx="44" cy="44" r="20" fill="none" stroke-width="4"></circle>
            <circle cx="44" cy="44" r="20" fill="none" stroke-width="4" stroke-dasharray="126.92 126.92" stroke-dashoffset="32.31" style="stroke: #1976d2; stroke-linecap: round; animation: mui-progress-circle-rotate 1.75s linear infinite; transform-origin: center center;"></circle>
          </svg>
        </div>
      </div>
      <script>
        window.onload = () => {
          document.getElementById("loader").style.display = "none";
        };
      </script>
    `;

      if (projectId) {
        newTab.location.href = `/add-new-dataset?projectId=${encodeURIComponent(
          projectId
        )}`;
      }
    }
  }, [onValid, projectId]);

  const handleSecondClick = () => {
    setView("selectDataset");
  };
  const handleThirdClick = () => setView("generate");

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  if (view === "addNew") {
    return <PromptDatasetNew skip={skip} setView={setView} />;
  }

  if (view === "selectDataset") {
    return (
      <PromptDataSetTable
        dataState={setDataset}
        data={GetAllDatasets}
        setView={setView}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 1,
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "73%",
        }}
      >
        <Typography
          variant="h3"
          fontWeight="600"
          sx={{
            width: "50%",
          }}
        >
          Add Dataset
        </Typography>
        <Button onClick={skip} sx={{ fontSize: "1.1rem" }}>
          Skip
        </Button>
      </Box>

      <StyledContainerWithoutHeight>
        <InsideLayoutComponent
          backgroundColor="#FFF1E0"
          firstText="Generate Synthetic Dataset"
          secondText="Generate a synthetic dataset for your project now, or skip and proceed without adding one."
          buttonText="Generate"
          onButtonClick={() => alert("Button clicked!")}
          disabled={true}
        />
        <InsideLayoutComponent
          backgroundColor="#F4F7FF"
          firstText="Add New Dataset"
          secondText="No dataset available. You can create a new dataset now, or skip and continue without adding one."
          buttonText="Add New"
          onButtonClick={handleFirstClick}
        />
        <InsideLayoutComponent
          backgroundColor="#F4FFF6"
          firstText="Available Datasets"
          secondText="You can add it to your project now, or skip and continue without adding."
          buttonText="Select Dataset"
          onButtonClick={handleSecondClick}
        />
      </StyledContainerWithoutHeight>
    </Box>
  );
};

export default PromptDataSets;

const InsideLayoutComponent = ({
  backgroundColor = "#F4F7FF",
  firstText = "First Typography Text",
  secondText = "Second Typography Text",
  buttonText = "Click Me",
  onButtonClick = () => {},
  disabled = false,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid #0000001A",
        backgroundColor: backgroundColor,
        borderRadius: "14px",
        width: "35vw",
        padding: "0.5vw 1vw",
        flexShrink: 0,
      }}
    >
      {/* <Box
        sx={{
          border: "1px solid #00000014",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          width: "2vw",
        }}
      >
        <img
          src={Icons.File}
          alt="Dataset"
          style={{
            width: "30px",
            height: "30px",
            margin: "5px 0px 0px 4px",
          }}
        />
      </Box> */}
      <Typography
        variant="h4"
        fontWeight={600}
        sx={{
          textAlign: "left",
          marginTop: 1,
        }}
      >
        {firstText}
      </Typography>
      <Typography
        variant="h6"
        fontWeight={400}
        sx={{
          textAlign: "left",
          marginTop: 1,
          marginBottom: 2,
        }}
      >
        {secondText}
      </Typography>

      <MultiTypeButton
        buttonType={ButtonType.Gradient}
        // isLoading={isLoading}
        width={"10vw"}
        // height={"56px"}
        typeText={buttonText}
        actionOnClick={onButtonClick}
        disabled={disabled}
      />
    </Box>
  );
};
