import { createTheme } from "@mui/material/styles";

import { getCommonTheme, Theme } from "./CommonTheme";
import { DarkColorTokens } from "./colorTokens/darkColorTokens";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    ...DarkColorTokens,
  },
  ...getCommonTheme(Theme.DARK),
});
