export const tier = [
  {
    label: "Free",
    value: "free",
  },
  {
    label: "Tier 1",
    value: "tier_1",
  },
  {
    label: "Tier 2",
    value: "tier_2",
  },
  {
    label: "Tier 3",
    value: "tier_3",
  },
  {
    label: "Tier 4",
    value: "tier_4",
  },
  {
    label: "Tier 5",
    value: "tier_5",
  },
];

export function extractMinValue(range: any) {
  console.log("Function:Range", range);

  const minPart = range?.split("-");

  console.log("Function:Parts:Min", minPart);
  console.log("Function:Return", Number(minPart[0]));

  return minPart?.length === 2 ? Number(minPart[0]) : NaN;
}

export function extractMaxValue(range: any) {
  const maxPart = range?.split("-");
  console.log("Function:Parts:Max", maxPart);

  return maxPart?.length === 2 ? Number(maxPart[1]) : NaN;
}
