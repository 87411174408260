import { Box, Slider, Typography } from "@mui/material";

const SliderSection = ({
  label,
  value,
  onChange,
  max,
  min,
  color,
  step,
}: any) => {
  console.log("Temperature:", min, max);

  return (
    <Box
      sx={{
        my: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 400,
            mb: 1,
          }}
        >
          {label}
        </Typography>
        <Box
          sx={{
            bgcolor: "#F8F8F899",
            border: "1px solid #12121226",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            borderRadius: "5px",
            color: "#12121280",
            p: "0px 20px",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 400,
            }}
          >
            {label === "Max Tokens" ? value : value?.toFixed(1)}
          </Typography>
        </Box>
      </Box>

      <Slider
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        sx={{
          color: { color },
        }}
      />
    </Box>
  );
};

export default SliderSection;

// const SliderSection = ({
//   sliderTempRange,
//   sliderTokenRange,
//   sliderTopPRange,
//   onTempChange,
//   onMaxTokenChange,
//   onTopPChange,
// }: any) => {
//   return (
//     <div>
//       <div>
//         <label>Max Tokens: {sliderTokenRange?.max}</label>
//         <input
//           type="range"
//           min={sliderTokenRange.min}
//           max={sliderTokenRange.max}
//           step="1"
//           onChange={(e) => onMaxTokenChange(parseInt(e.target.value, 10))}
//         />
//       </div>
//       <div>
//         <label>Top P: {sliderTopPRange?.max}</label>
//         <input
//           type="range"
//           min={sliderTopPRange.min}
//           max={sliderTopPRange.max}
//           step="0.01"
//           onChange={(e) => onTopPChange(parseFloat(e.target.value))}
//         />
//       </div>
//       <div>
//         <label>Temperature: {sliderTempRange?.max}</label>
//         <input
//           type="range"
//           min={sliderTempRange.min}
//           max={sliderTempRange.max}
//           step="0.01"
//           onChange={(e) => onTempChange(parseFloat(e.target.value))}
//         />
//       </div>
//     </div>
//   );
// };

// export default SliderSection;
