import { Box, InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { LabelWrapper } from "../LabelWrapper/LabelWrapper";

interface CountryOption {
  name: string;
  code: string;
  flagUrl: string;
}

interface AutocompleteCountriesProps {
  name: string;
  control: Control<any>;
  options: CountryOption[]; // Define the options type
  label: string;
  placeholder?: string;
  disabled?: boolean;
  errors?: any;
  sx?: any;
  size?: "small" | "medium";
  required: boolean;
}

const AutocompleteCountries: React.FC<AutocompleteCountriesProps> = ({
  name,
  control,
  options,
  label,
  required,
  disabled = false,
  size = "small",
}) => {
  return (
    <LabelWrapper label={label} required={required} name={name}>
      <Controller
        name={name}
        control={control}
        defaultValue={"US"}
        render={({ field }) => (
          <Autocomplete
            disabled={disabled}
            size={size}
            {...field}
            options={options}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderOption={(props, option) => (
              <Box>
                <li {...props}>
                  <img
                    src={option.flagUrl}
                    alt=""
                    width="20"
                    style={{ marginRight: 8 }}
                  />
                  {option.name} ({option.code})
                </li>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: field.value ? (
                    <InputAdornment position="start">
                      <Box sx={{ marginLeft: "10px" }}>
                        <img
                          src={
                            options.find(
                              (option) => option.code === field.value
                            )?.flagUrl
                          }
                          alt=""
                          width="20"
                        />
                      </Box>
                    </InputAdornment>
                  ) : null,
                }}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "Select Country",
                }}
              />
            )}
            value={
              options.find((option) => option.code === field.value) || null
            }
            onChange={(e, newValue) => {
              field.onChange(newValue ? newValue.code : null); // Register code instead of name
            }}
          />
        )}
      />
    </LabelWrapper>
  );
};

export default AutocompleteCountries;
