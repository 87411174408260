import { Store } from "pullstate";

interface IUIStore {
  isDarkMode: boolean;
}

interface IAuth {
  signupEmailAddress: string | null;
  userName: string | null;
}

export const UIStore = new Store<IUIStore>({
  isDarkMode: false, // Default to light mode
});

export const AuthStore = new Store<IAuth>({
  signupEmailAddress: null, // Default to light mode
  userName: null,
});
