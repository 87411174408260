import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAllDatasets } from "../../../dataset/services/DatasetService";
import { getAllProjects } from "../../../project/services/ProjectService";
import Spinner from "../../../shared/components/Loaders/Spinner";
import { SingleSelect } from "../../../shared/components/Select/SingleSelect";
import Table from "../../../shared/components/Table";
import { StyledContainer } from "../../styles";

const PromptDataSetTable = ({
  dataState,
  data,
  setView,
}: {
  dataState: (selectedRow: any) => void;
  data: any;
  setView: any;
}) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedIds, setSelectedIds] = useState<any>([]);

  const { projectId: routeProjectId } = useParams();
  const [projectId, setProjectId] = useState<any>(routeProjectId);

  console.log("Main Check ID:", projectId);

  const [selectedProjectId, setSelectedProjectId] = useState<any>(null);

  const { data: FindAllDatasets, isLoading: allDatasetsLoading }: any =
    useQuery("datasets", getAllDatasets);

  const { data: GetAllDatasets, isLoading: projectDatasetsLoading }: any =
    useQuery(
      ["datasets", projectId],
      () =>
        getAllDatasets({
          projectId: projectId === "org_datasets" ? null : projectId,
        }),
      {
        enabled: !!projectId,
      }
    );

  const { data: GetAllProjects, isLoading: projectsLoading }: any = useQuery(
    "projects",
    getAllProjects
  );
  const AllProjects = [
    ...(GetAllProjects?.projects?.map((i: { title: any; _id: any }) => ({
      label: i?.title,
      value: i?._id,
    })) || []),
    { label: "Org Datasets", value: "org_datasets" },
  ];

  const {
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const storedDatasetIds = JSON.parse(
      localStorage.getItem("PromptDataset") || "[]"
    );

    setSelectedIds(storedDatasetIds);
  }, []);

  const FilteredData =
    FindAllDatasets?.data?.filter((f: any) => f?.projectIds?.length === 0) ||
    [];

  const MixedData =
    projectId === "org_datasets" ? FilteredData : GetAllDatasets?.data || [];

  useEffect(() => {
    if (selectedIds?.length > 0 && MixedData?.length > 0) {
      const preSelectedRows = MixedData.filter((row: any) =>
        selectedIds.includes(row?._id)
      );

      setSelectedRow(preSelectedRows);
      const reversedRows = [...preSelectedRows].reverse();
      dataState(reversedRows);
    } else {
    }
  }, [selectedIds, MixedData, dataState]);

  // const handleProjectSelect = (selectedProject: any) => {
  //   // console.log("Now ProjectId:", selectedProject);

  //   setSelectedProjectId(selectedProject?.value);
  //   setProjectId(selectedProject?.value);

  //   if (selectedProject?.value === "org_datasets") {
  //     setProjectId(null);
  //   }
  // };

  const handleProjectSelect = useCallback((selectedProject: any) => {
    setProjectId(selectedProject?.value);
    setSelectedProjectId(selectedProject?.value);

    // if (selectedProject?.value === "org_datasets") {
    //   setProjectId(null);
    // }
  }, []);

  const isLoading =
    projectsLoading ||
    allDatasetsLoading ||
    (projectId !== "org_datasets" && projectDatasetsLoading);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 4,
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "73%",
        }}
      >
        {/* <Typography
          variant="h3"
          fontWeight="500"
          sx={{
            width: "50%",
            textAlign: "left",
            mb: 2,
          }}
        >
          Add Dataset
        </Typography> */}
        <Button
          onClick={() => {
            setView("default");
            dataState(null);
          }}
          sx={{ fontSize: "20px" }}
        >
          Back
        </Button>

        <Button sx={{ fontSize: "20px" }}>Skip</Button>
      </Box>

      <StyledContainer sx={{ marginBottom: "0px" }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box sx={{ width: "40%" }}>
            {!projectsLoading ? (
              <SingleSelect
                control={control}
                required
                name="promptCategory"
                label="Select Project"
                options={AllProjects}
                // defaultData={AllProjects?.[0]?.value}
                defaultData={projectId}
                placeholder="Select Project"
                onChange={(value: any) => handleProjectSelect({ value })}
              />
            ) : (
              <></>
            )}
          </Box>

          {/* <Box sx={{ width: "20%", mt: 3 }}>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Create New Dataset"}
              // actionOnClick={() => setView("addNew")}
              actionOnClick={handleNewTabClick}
            />
          </Box> */}
        </Box>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Spinner />
          </Box>
        ) : (
          <Box>
            <Table
              data={MixedData || []}
              columns={columns}
              // loading={DataLoading}
              preSelectedRows={selectedRow}
              is_Id={true}
              // renderButtons={renderButtons}
              onRowSelectionChange={(selected: any) => {
                const newSelectedIds = selected
                  ? selected?.map((row: any) => row._id)
                  : [];

                setSelectedRow(selected || null);
                dataState(selected || null);

                // localStorage?.setItem(
                //   "PromptDataset",
                //   JSON.stringify(newSelectedIds)
                // );

                setSelectedIds(newSelectedIds);
              }}
            />
          </Box>
        )}
      </StyledContainer>
    </Box>
  );
};

export default PromptDataSetTable;

const columns = [
  {
    key: "name",
    title: "Dataset Title",
    render: (e: any) => {
      return e?.name;
    },
  },
  {
    key: "tag",
    title: "Dataset Tag",
    render: (e: any) => {
      return e?.dataSetVersions[0]?.tag || "N/A";
    },
  },
  // {
  //   key: "promptAssigned",
  //   title: "Prompt Assigned",
  //   render: (e: any) => {
  //     return e?.dataSetVersions[0]?.promptAssigned || "N/A";
  //   },
  // },
  {
    key: "versions",
    title: "Versions",
    render: (e: any) => {
      return (
        <Box
          sx={{
            border: "1px solid #C75737",
            backgroundColor: "#C75737",
            borderRadius: "4px",
            padding: "7px 10px",
            width: "fit-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "white",
              textAlign: "center",
              fontWeight: 600,
              flexWrap: "nowrap",
            }}
          >
            {e?.dataSetVersions?.length} Versions
          </Typography>
        </Box>
      );
    },
  },
];
