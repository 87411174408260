import { Components } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { DarkColorTokens } from "./colorTokens/darkColorTokens";
import { LightColorTokens } from "./colorTokens/lightColorTokens";
import { customCssBaseline } from "./customCssBaseline";

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}
export const getCommonTheme = (mode: Theme) => {
  const ColorTokens = mode === Theme.DARK ? DarkColorTokens : LightColorTokens;
  return {
    typography: {
      fontFamily: ["Figtree", "sans-serif"].join(","),
    },
    components: {
      MuiMultiSectionDigitalClockSection: {
        styleOverrides: {
          root: {
            width: "90px",
            scrollbarWidth: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
          item: {
            width: "50px",
            borderRadius: "4px",
            padding: "0px 8px",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            justifyContent: "flex-start",
            paddingLeft: "20px",
            "&.Mui-expanded": {
              minHeight: "0px",
            },
          },
          content: {
            flexGrow: "unset",
            margin: "0px 16px 0px 0px",
            "&.Mui-expanded": {
              margin: "16px 16px 0px 0px",
            },
          },
          expandIconWrapper: {
            "&.Mui-expanded": {
              transform: "rotate(180deg)",
              marginTop: "16px",
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            marginLeft: "2px",
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            fontFamily: "Figtree",
          },
          separator: {
            color: ColorTokens.text.tertiary,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            width: "400px",
            fontFamily: "Figtree",
            fontWeight: 500,
            fontSize: "14px",
            color: ColorTokens.text.primary,
          },
          standardSuccess: {
            backgroundColor: ColorTokens.highlight.greenBackground,
          },
          standardInfo: {
            backgroundColor: ColorTokens.highlight.blueBackground,
          },
          standardWarning: {
            backgroundColor: ColorTokens.highlight.orangeBackground,
          },
          standardError: {
            backgroundColor: ColorTokens.highlight.redBackground,
          },
        },
      },

      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableTouchRipple: true,
        },
        styleOverrides: {
          root: {
            borderRadius: "10px",
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              width: "25px",
              height: "25px",
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: "Figtree",
            fontWeight: 500,
            fontSize: "16px",
            padding: "12px 16px",
            backgroundColor: ColorTokens.surface.grey,
          },
          paper: {
            elevation: 2,
            borderRadius: "10px",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "0px",
            margin: "16px",
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          dot: {
            width: "4px",
            height: "4px",
            borderRadius: "10px",
            backgroundColor: ColorTokens.tertiary.main,
            margin: "3px",
            minWidth: "4px",
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: ColorTokens.secondary.main,
            "&:hover": {
              backgroundColor: ColorTokens.secondary.dark,
            },
            borderRadius: "6px",
            padding: "10px 10px",
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "Figtree",
            margin: "5px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            // backgroundColor: ColorTokens.surface.OnboardingCardBackground,
            border: `1px solid ${ColorTokens.border.primary}`,
            borderRadius: "8px",
            backgroundImage: "none",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fullWidth: true,
            backgroundColor: "transparent !important",
          },
          paper: {
            borderRadius: "5px",
            width: "100%",
            maxHeight: "calc(100vh - 64px)",
            overflow: "auto",
            boxShadow: "none",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            minWidth: "290px",
            padding: 0,
            "& > :last-child": {
              borderBottom: "none",
              "& li": {
                borderBottom: "none",
              },
            },
          },
          paper: {
            borderRadius: "5px",
            // border: `1px solid ${ColorTokens.secondary.main}`,
            "&::-webkit-scrollbar": {
              width: "0.5em",
              height: "0.5em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: ColorTokens.surface.paperBackground,
              borderRadius: "3px",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            minHeight: "50px !important",
            padding: "10px 16px",
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {},
        },
      },

      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#F07946",
            background: "#fff",

            "&.Mui-selected": {
              background: "#F07946",
              color: "#fff",
              // border: `1px solid ${ColorTokens.tertiary.main}`,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            padding: "10px 20px ",
            // maxHeight: "40px ",
            borderRadius: "5px ",
            boxShadow: "none ",
            fontSize: "14px ",
            fontFamily: "Figtree",
            fontWeight: 600,
            "&:hover": {
              boxShadow: "none ",
              "@media (hover: none )": {
                boxShadow: "none ",
              },
            },
            "&$focusVisible": {
              boxShadow: "none",
            },
            "&:active": {
              boxShadow: "none",
            },
            // "&$disabled": { boxShadow: "none" },
          },
          text: {
            padding: "0px",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          // variant: "outlined",
          color: "primary",
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            "& label": {
              display: "none",
            },
            "& legend": {
              "& span": {
                // display: "none",
              },
            },
            "& input:-webkit-autofill": {
              height: "7px",
            },
            minWidth: "200px",
            margin: "2px 0px",
            backgroundColor: ColorTokens.textField.defaultBackground,
            border: `2px solid ${ColorTokens.textField.border}`,
            borderRadius: "5px",
            fontFamily: "Figtree",

            // Remove border on focus and hover
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },

            // Customize the placeholder
            "& input::placeholder": {
              fontWeight: "600", // Optional: Customize font weight
              fontSize: "14px", // Customize the font size
              fontFamily: "Figtree",
            },
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: ColorTokens.surface.paperBackground,
            // boxShadow: `0px 2px 5px ${ColorTokens.secondary.main}`,
            border: `1px solid ${ColorTokens.secondary.main}`,
          },
          arrow: {
            color: ColorTokens.surface.paperBackground,
            "&:before": {
              border: `1px solid ${ColorTokens.secondary.main}`,
            },
          },
        },
      },
      // MuiSwitch: {
      //   styleOverrides: {
      //     root: {
      //       width: 40,
      //       height: 20,
      //       padding: 0,
      //       "& .MuiSwitch-switchBase": {
      //         padding: 1,
      //         margin: 2,
      //         transitionDuration: "500ms",
      //         "&.Mui-checked": {
      //           transform: "translateX(20px)",
      //           "& + .MuiSwitch-track": {
      //             backgroundColor: ColorTokens.surface.paperBackground,
      //             border: `1px solid ${ColorTokens.highlight.highlightRed}`,
      //             opacity: 1,
      //           },
      //           "& .MuiSwitch-thumb": {
      //             boxSizing: "border-box",
      //             backgroundColor: ColorTokens.highlight.highlightRed,
      //             width: 14,
      //             height: 14,
      //           },
      //           "&.Mui-disabled + .MuiSwitch-track": {
      //             opacity: 0.5,
      //           },
      //         },
      //       },
      //       "& .MuiSwitch-thumb": {
      //         boxSizing: "border-box",
      //         boxShadow: "none",
      //         borderRadius: 4,
      //         backgroundColor: ColorTokens.surface.grey,
      //         width: 14,
      //         height: 14,
      //       },
      //       "& .MuiSwitch-track": {
      //         borderRadius: 4,
      //         backgroundColor: ColorTokens.surface.paperBackground,
      //         border: `1px solid ${ColorTokens.border.primary}`,
      //         opacity: 1,
      //       },
      //       "&.Mui-disabled + .MuiSwitch-track": {
      //         opacity: 0.1,
      //       },
      //       "&.Mui-disabled + .MuiSwitch-thumb": {
      //         opacity: 0.1,
      //       },
      //     },
      //   },
      // },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: "0px",
          },
        },
      },
      MuiSnackbar: {
        defaultProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              padding: 0,
              maxHeight: "40px",
            },
            overflowY: "auto",
          },
          paper: {
            borderRadius: "10px",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& label": {
              display: "none",
            },
            "& legend": {
              "& span": {
                display: "none",
              },
            },
            maxHeight: "40px",
            fontSize: "16px",
            fontFamily: "Figtree",
            fontWeight: 600,
            color: ColorTokens.text.primary,
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          color: "primary",
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            margin: "2px 0px",
            backgroundColor: ColorTokens.textField.defaultBackground,
            border: `2px solid ${ColorTokens.textField.border}`,
            borderRadius: "5px",
            fontFamily: "Figtree",

            // Remove border on focus and hover
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },

            // Align the Select text to match input style
            "& .MuiSelect-select": {
              padding: "10px", // Adjust inner padding as needed
              fontWeight: "600",
              fontSize: "14px",
              fontFamily: "Figtree",
              // color: ColorPalette.BlueGreyColors.BlueGrey5,
            },

            // Custom styling for dropdown icon
            "& .MuiSelect-icon": {
              fontSize: "1.2rem",
            },
          },

          // Remove the label
          "& .MuiFormLabel-root": {
            display: "none",
          },
          "& .MuiInputLabel-root": {
            display: "none",
          },

          // Target specific selector name (if dynamically generated class is used)
          "& .css-ekeo4f-MuiFormLabel-root-MuiInputLabel-root": {
            display: "none",
          },

          // Menu list styling for dropdown
          paper: {
            border: "none", // Removes the dropdown border
            boxShadow: "0px 2px 10px rgba(0,0,0,0.1)", // Keeps subtle shadow for depth
          },

          // List item (MenuItem) styling
          list: {
            "& .MuiMenuItem-root": {
              fontWeight: "600",
              fontSize: "14px",
              fontFamily: "Figtree",
              padding: "10px 15px",

              // Remove selected background color
              "&.Mui-selected": {
                backgroundColor: "transparent", // Removes the selected item highlight
              },
              "&.Mui-selected:hover": {
                // backgroundColor: "#f5f5f5", // Keeps hover effect only
              },
            },
          },
        },
      },

      MuiLink: {
        defaultProps: {
          underline: "none",
        },
        styleOverrides: {
          root: {
            color: ColorTokens.text.tertiary,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "& .MuiTabs-flexContainer": {
              borderBottom: `1px solid ${ColorTokens.border.primary}`,
            },
          },
          indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            height: "3px",
            "& .MuiTabs-indicatorSpan": {
              borderRadius: "30px",
              width: "60%",
              backgroundColor: ColorTokens.tertiary.main,
            },
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            color: ColorTokens.text.secondary,
            fontSize: "14px",
            fontWeight: "600",
            textTransform: "initial",
            minHeight: "50px",
            paddingBottom: "0px",
            "&.Mui-selected": {
              color: ColorTokens.text.primary,
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            // background: ColorTokens.surface.defaultBackground,
            cursor: "pointer",
            border: `1px solid ${ColorTokens.primary.main}`,
            color: ColorTokens.primary.main,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: "16px", // Base font size
          },
          h1: {
            fontSize: "1.5rem", // 24px

            "@media (min-width:600px)": {
              fontSize: "1.625rem", // 26px
            },
            "@media (min-width:900px)": {
              fontSize: "1.75rem", // 28px
            },
            "@media (min-width:1200px)": {
              fontSize: "1.75rem",
            },
          },
          h2: {
            fontSize: "1.375rem", // 22px

            "@media (min-width:600px)": {
              fontSize: "1.2375rem", // 23px
            },
            "@media (min-width:900px)": {
              fontSize: "1.4rem", // 24px
            },
          },
          h3: {
            fontSize: "1.25rem", // 20px

            "@media (min-width:600px)": {
              fontSize: "1.25rem", // 21px
            },
            "@media (min-width:900px)": {
              fontSize: "1.3rem", // 22px
            },
            "@media (min-width:1200px)": {
              fontSize: "1.35rem", // 24px
            },
          },
          h4: {
            fontSize: "1.125rem", // 18px

            "@media (min-width:600px)": {
              fontSize: "1.1875rem", // 19px
            },
            "@media (min-width:900px)": {
              fontSize: "1.1875rem", // 20px
            },
          },
          h5: {
            fontSize: "1rem", // 16px

            "@media (min-width:900px)": {
              fontSize: "1.125rem", // 18px
            },
          },
          h6: {
            fontSize: "0.875rem", // 14px

            "@media (min-width:900px)": {
              fontSize: "1rem", // 16px
            },
          },
          subtitle1: {
            fontSize: "0.8775rem", // 16px
          },
          subtitle2: {
            fontSize: "0.775rem", // 14px
          },
          body1: {
            fontSize: "0.75rem", // 12px

            "@media (min-width:1536px)": {
              fontSize: "0.875rem", // 14px
            },
          },
          body2: {
            fontSize: "0.625rem", // 10px
          },
          caption: {
            fontSize: "0.75rem", // 12px
          },
        } as TypographyOptions,
      },
      MuiCssBaseline: {
        styleOverrides: customCssBaseline,
      },
    } as Components,
  };
};
