import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Cookies from "js-cookie";
import Lottie from "lottie-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../../../app/services";
import { Icons } from "../../../assets";
import { orangeLoader } from "../../../assets/lottie/orangeLoader";
import { AuthStore } from "../../../store/general";
import { ColorPalette } from "../../../themes/colorTokens/colorPalette";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import { googleLogin, verifyEmail } from "../services/AuthService";

// Define the validation schema
const validationSchema = yup.object({
  EmailAddress: yup
    .string()
    .email("Enter a valid email address")
    .required("Email Address is required"),
});

type GoogleLoginProps = {
  postGoogleLoginDetails: (data: any) => void;
  responseToken: any;
  setFetchingDetails: any;
};

// Helper function for Google login
const handleGoogleLogin = async ({
  responseToken,
  postGoogleLoginDetails,
  setFetchingDetails,
}: GoogleLoginProps) => {
  try {
    setFetchingDetails(true);
    const res = await axios.get(
      "https://www.googleapis.com/oauth2/v2/userinfo",
      {
        headers: {
          Authorization: `Bearer ${responseToken.access_token}`,
        },
      }
    );
    AuthStore.update((s) => {
      s.signupEmailAddress = res.data.email;
      s.userName = res.data.name;
    });
    const data = { token: responseToken.access_token };
    setFetchingDetails(false);
    postGoogleLoginDetails(data);
  } catch (error) {
    setFetchingDetails(false);
    displayErrorMessage("Failed to retrieve user info from Google.");
  }
};

const clientID = process.env.REACT_APP_GIT_AUTH_CLIENT_ID;
const redirectURI = `${process.env.REACT_APP_GITHUB_REDIRECT_URL}/auth/github`;

const loginWithGitHub = () => {
  window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectURI}&scope=user:email`;
};

export default function SignupDetails() {
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const navigate = useNavigate();
  AuthStore.update((s) => {
    s.signupEmailAddress = null;
  });

  const { mutate: postGoogleLoginDetails, isLoading: googleLoader } =
    useMutation(googleLogin, {
      onSuccess: (res) => {
        displaySuccessMessage(res.message);
        if (res.isAccountVerified) {
          Cookies.set("access_token", res.access_token);
          navigate("/app");
        } else {
          Cookies.set("refresh_Token", res.access_token);
          navigate("/register");
        }
      },
      onError: (error) => {
        displayErrorMessage("Google Login Failed");
        navigate("/signup");
      },
    });

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (responseToken) => {
      handleGoogleLogin({
        responseToken,
        postGoogleLoginDetails,
        setFetchingDetails,
      });
    },
    onError: () => {
      displayErrorMessage("Google Login Failed");
    },
  });

  const { mutate: verifyEmailAddress, isLoading: PostDetailsLoader } =
    useMutation(verifyEmail, {
      onSuccess: (res: any) => {
        AuthStore.update((s) => {
          s.signupEmailAddress = getValues("EmailAddress");
        });
        Cookies.set("refresh_Token", res.refreshToken);
        if (res.user.isEmailVerified) {
          navigate(`/register`);
          displaySuccessMessage("Please Complete the registration");
        } else {
          displaySuccessMessage(res.message);
          navigate(`/email-verification`);
        }
      },
      onError: (error: any) => {
        displayErrorMessage(error);
      },
    });

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    verifyEmailAddress({ emailAddress: data.EmailAddress });
  };

  if (googleLoader || fetchingDetails) {
    return (
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2">Please Wait....</Typography>
        <Lottie
          animationData={orangeLoader}
          loop={true}
          autoplay={true}
          style={{ width: "150px", height: "150px" }} // Fixed size for better presentation
        />
      </Box>
    );
  }

  return (
    <Box>
      <img src={Icons.namedLogo} alt="loading..." width={"60%"} />
      <Stack my={"3vh"}>
        <Typography variant="h3">Sign Up and Get Started!</Typography>
        <Typography variant="subtitle2">
          Create an account to unlock personalized features and enhance your
          experience.
        </Typography>
      </Stack>

      <TextField
        control={control}
        formateType={Formate.EmailAddress}
        name="EmailAddress"
        label="Email Address"
        required
        placeholder="Enter your email address"
      />

      <Stack gap={"1vh"} marginTop={"2vh"}>
        <MultiTypeButton
          buttonType={ButtonType.Gradient}
          actionOnClick={handleSubmit(onSubmit)}
          isLoading={PostDetailsLoader}
          typeText="Sign Up"
          key={"sign-up"}
        />

        <MultiTypeButton
          buttonType={ButtonType.Google}
          actionOnClick={loginWithGoogle}
          typeText="Sign up with Google"
          icon={Icons.googleLogo}
          key={"sign-up-google"}
        />

        <MultiTypeButton
          buttonType={ButtonType.GitHub}
          actionOnClick={() => {
            loginWithGitHub();
          }}
          typeText="Sign up with Github"
          icon={Icons.githubLogo}
          key={"sign-up-github"}
        />
      </Stack>

      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2vh",
        }}
      >
        <Typography variant="subtitle2">Have an account?</Typography>
        <Button
          variant="text"
          onClick={() => {
            navigate("/login");
          }}
        >
          <Typography
            variant="subtitle2"
            color={ColorPalette.BrandColors.OrangeLight}
            fontWeight="bold"
          >
            Login
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
