// import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Stack, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useConfirm } from "../../project/components/confirmDialog";
import { getOneProject } from "../../project/services/ProjectService";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import Spinner from "../../shared/components/Loaders/Spinner";
import PromptViewTab from "./promptComponents/PromptViewTab";

export default function PromptsView() {
  const theme = useTheme();
  const { projectId, datasetId } = useParams();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { control } = useForm();
  const [selectedPromptId, setSelectedPromptId] = useState<string | null>(null);

  const { data: FindOneProject, isLoading } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  const GetAllPrompts = FindOneProject?.prompts;

  const handleDatasetClick = (
    clickedStatus: boolean,
    index: number,
    dataset: any,
    id: string
  ) => {
    // If clickedStatus is true, set the dataset as selected
    setSelectedPromptId(clickedStatus ? id : null);
  };

  if (isLoading) {
    return (
      <Stack height={"90vh"} alignItems={"center"} justifyContent={"center"}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ width: "100%", height: "92vh" }}>
        <Grid
          size={3.5}
          sx={{ borderRight: `1px solid ${theme.palette.border.primary}` }}
        >
          <Box
            className="Heading"
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
            width="100%"
            padding={2}
          >
            <Typography variant="h2" sx={{ fontWeight: "600" }}>
              All Prompts
            </Typography>
            <Stack direction="row" alignItems="center" gap={"5px"}>
              <MultiTypeButton
                actionOnClick={() => {
                  navigate(`/app/projects/${projectId}/prompts/create`);
                }}
                buttonType={ButtonType.Gradient}
                isLoading={false}
                typeText="Add New"
                key="prompt-view"
              />
            </Stack>
          </Box>
          {GetAllPrompts?.map((p: any) => {
            return (
              <>
                <PromptViewTab
                  index={1}
                  noOfVersions="2"
                  // onClick={(clickedStatus, index, dataset, id) =>
                  //   handleCheckboxClick(clickedStatus, id)
                  // }
                  onClick={handleDatasetClick}
                  key={"dataSetView-checkbox"}
                  control={control}
                  prompt={p}
                  selectedPromptId={selectedPromptId}
                />
              </>
            );
          })}
        </Grid>
        <Grid size={8.5}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}
