import { Stack, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { displaySuccessMessage } from "../../../app/services";
import { Icons } from "../../../assets";
import { AuthStore } from "../../../store/general";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { emailVerificationStatus } from "../services/AuthService";

export default function EmailVerificationDetails() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const EmailAddress = AuthStore.useState((s) => s.signupEmailAddress);
  const { data: emailVerificationData }: any = useQuery(
    ["emailVerificationData", EmailAddress],
    emailVerificationStatus,
    {
      refetchInterval: 5000,
      enabled: !!EmailAddress,
      onSuccess: (res) => {
        if (res.isEmailVerified) {
          displaySuccessMessage(res.message);
          navigate("/register");
        }
      },
    }
  );

  return (
    <Stack alignItems={"center"} gap={"12px"}>
      <img src={Icons.email} alt="loading..." width={"20%"} />
      <Typography variant="h3">Verify your Email</Typography>
      <Typography variant="subtitle2">
        Please check your email for a confirmation
      </Typography>
      <MultiTypeButton
        isLoading={false}
        buttonType={ButtonType.Gradient}
        actionOnClick={() => {
          queryClient.invalidateQueries("emailVerificationData");
        }}
        typeText="Resend Verification Link"
        key={"email-verification"}
      />
    </Stack>
  );
}
