import Box from "@mui/material/Box";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import "./app/App.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Box>
      <App />
    </Box>
  </React.StrictMode>
);
