// Authentication API functions

import { http } from "../../../app/services";

// Request OTP
export const requestOtp = async (body: any) => {
  try {
    const response = await http.post("/auth/request-otp", body);
    return response.data;
  } catch (error) {
    throw error; // Error handling will be managed by interceptors
  }
};

// Verify OTP
export const verifyOtp = async (body: any) => {
  try {
    const response = await http.post("/auth/verify-otp", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Google Login
export const googleLogin = async (body: any) => {
  try {
    const response = await http.post("/auth/google", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// GitHub Login
export const githubLogin = async ({ queryKey }: any) => {
  try {
    const response = await http.post("/auth/github", queryKey[1]);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Signup APIs

// Verify Email
export const verifyEmail = async (body: any) => {
  try {
    const response = await http.post("/auth/verify-email", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Validate Email
export const validateEmail = async ({ queryKey }: any) => {
  try {
    const response = await http.post("/auth/validate", queryKey[1]);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Signup
export const signUp = async (body: any) => {
  try {
    const response = await http.post("/auth/sign-up", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const emailVerificationStatus = async ({ queryKey }: any) => {
  try {
    const response = await http.get(`/auth/verify-status/${queryKey[1]}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
