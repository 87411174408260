import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, IconButton, Popover, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { UIStore } from "../../../../store/general";

interface VersionParamsCardProps {
  params: string[];
}

export default function VersionParamsCard({ params }: VersionParamsCardProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  const popoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (popoverTimeout.current) {
      clearTimeout(popoverTimeout.current);
      popoverTimeout.current = null;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    popoverTimeout.current = setTimeout(() => {
      setAnchorEl(null);
    }, 200);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const visibleChips = params.slice(0, 2);
  const showEllipsis = params.length > 2;

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        display: "flex",
        gap: "1px",
        flexWrap: "wrap",
        // backgroundColor: "#f0f0f0",
        padding: "10px 0px",
        position: "relative",
        width: "20vw",
      }}
    >
      {visibleChips.map((param, index) => (
        <Chip
          key={index}
          // label={index === 3 && showEllipsis ? "and more..." : param}
          label={param}
          sx={{
            fontSize: 14,
            height: 24,
            backgroundColor: isDarkMode ? " rgba(40, 48, 60, 1)" : "#e0e0e0",
            padding: "15px",
            borderRadius: "20px",
          }}
        />
      ))}

      {showEllipsis && (
        <Typography
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          variant="subtitle2"
          sx={{
            cursor: "pointer",
            mt: 1,
            color: isDarkMode ? "#fff" : "#222222CC",
          }}
        >
          and more...
        </Typography>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleMouseLeave}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            backgroundColor: isDarkMode ? "#293640" : "#FFFFFF",
            border: "1px solid #1212121A",
            padding: "10px",
            borderRadius: 1,
            // boxShadow: 1,
            maxWidth: "30vw",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            top: 0,
            right: 0,
            padding: "0px",
            zIndex: 1,
          }}
        >
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              color: isDarkMode ? "#FFFFFF" : "#000000",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        {params.map((param, index) => (
          <Chip
            key={index}
            label={param}
            sx={{
              fontSize: 14,
              height: 24,
              backgroundColor: isDarkMode ? " rgba(40, 48, 60, 1)" : "#e0e0e0",
              padding: "15px",
              borderRadius: "20px",
            }}
          />
        ))}
      </Popover>
    </Box>
  );
}
