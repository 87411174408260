import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UIStore } from "../../../store/general";
import { getOneProject } from "../../project/services/ProjectService";
import { SingleSelect } from "../../shared/components/Select/SingleSelect";
import { getOneUser } from "../services/LayoutService";

const StyledToolbar = styled(Toolbar)({
  justifyContent: "space-between",
  display: "flex",
  alignItems: "center",
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "transparent",
  color: "#121212CC", // Set text color to white
  padding: 0,
});

const StyledAvatarBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const pages = ["Dashboard", "Projects", "Datasets", "Configuration"];
const settings = ["Janaspandana"];

interface ResponsiveAppBarProps {
  isSidebarOpen: boolean;
}
export default function ResponsiveAppBar({
  isSidebarOpen,
}: ResponsiveAppBarProps) {
  const url = useLocation();

  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId, promptId } = useParams();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [search, setSearch] = useState("");
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState<
    string | null
  >(null);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);

      const userId = decoded?.userId;
      if (userId) {
        setUserId(userId);
      } else {
        // console.error("Failed to decode user id from token");
      }
    } else {
      // console.error("No token found in localStorage");
    }
  }, []);

  const { data: FindOneUser } = useQuery(["users", userId], getOneUser, {
    enabled: !!userId,
  });

  const { data: FindOneProject } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  const GetAllPrompts = FindOneProject?.prompts;

  const segregatedData =
    GetAllPrompts?.find((prompt: any) => prompt?._id === promptId) || {};

  const versionOptions =
    segregatedData?.versions?.map(
      (i: { number: any; _id: any; datasets?: any }) => ({
        label: i?.number,
        value: i?._id,
        dataSetVersions: i?.datasets[0]?.datasetVersionIds || [],
      })
    ) || [];

  // const { data: AllPrompts, isLoading } = useQuery(
  //   ["prompt", promptId],
  //   () => getOnePrompt({ projectId }),
  //   {
  //     enabled: !!promptId,
  //   }
  // );
  // console.log("All Prompts:", AllPrompts);

  const handleOpenNavMenu = (event: any) => setAnchorElNav(event.currentTarget);
  const handleOpenUserMenu = (event: any) =>
    setAnchorElUser(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleNavigate = (path: any) => {
    navigate(`/${path.toLowerCase()}`);
    handleCloseNavMenu();
    handleCloseUserMenu();
  };

  const currentPath = window.location.pathname;

  const getRouteTitle = () => {
    switch (currentPath) {
      case "/app/dashboard":
        return "Dashboard";
      case "/app/projects":
        return "Your Projects";
      case "/app/projects/all":
        return "Your Projects";
      case "/app/datasets":
        return "Datasets";
      case "/app/datasets/create":
        return "Datasets";
      case "/app/configuration":
        return "Configuration";

      default:
        return FindOneProject?.title || "Project Name";
    }
  };

  const versions = [
    {
      label: "version 1",
      value: "version 1",
    },
  ];

  const { control } = useForm();

  return (
    <Box
      sx={{
        // borderBottom: `1px solid ${theme.palette.border.primary}`,
        borderRadius: "0px",
        padding: "0.5vh clamp(10px, 2vw, 20px)",
        bgcolor: theme.palette.surface.AppBarBackground,
        position: "fixed",
        zIndex: theme.zIndex.appBar,
        width: isSidebarOpen ? "86vw" : "96vw",
        left: isSidebarOpen ? "14vw" : "4vw",
        transition: "width 0.3s ease, left 0.3s ease",
      }}
    >
      <StyledToolbar disableGutters>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => navigate(`/app/projects/${projectId}/prompts/all`)}
            sx={{ color: theme.palette.text.primary, fontSize: "20px" }}
          >
            <ArrowBackIcon
              sx={{
                color: "black",
                width: "2vw",
                height: "2vh",
              }}
            />
          </IconButton>
          <Typography variant="h3">Prompt Responses View</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            // gap: "25px",
            padding: "0 2vw",
            alignItems: "center",
          }}
        >
          {/* <Box>
            <Button
              sx={{ height: "40px", fontSize: "14px" }}
              variant="outlined"
            >
              your Dataset
            </Button>
          </Box> */}

          <SingleSelect
            control={control}
            name="promptVersions"
            // options={versionOptions}
            options={versions}
            // defaultData={versionOptions?.[0]?.label}
            defaultData={"version 1"}
            placeholder=""
            backgroundColor="#FEF2ED"
          />

          {/* <Box>
            <Button
              sx={{
                height: "40px",
                fontSize: "14px",
                color: "white",
                backgroundColor: "#F07946",
              }}
              variant="outlined"
            >
              Invite Experts
            </Button>
          </Box> */}

          {/* <IconButton onClick={() => {}}>
            <SettingsIcon />
          </IconButton> */}
        </Box>
      </StyledToolbar>
    </Box>
  );
}
