import { Box } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import {
  Formate,
  TextField,
} from "../../../shared/components/TextField/TextField";
import DialogWrapper from "../../../shared/components/Wrapper/DialogWrapper";

const SavePresentDialogCard = ({
  open,
  setOpen,
  data,
  onPresetsUpdate,
}: any) => {
  const [nameError, setNameError] = useState("");

  const { control, watch, handleSubmit, reset, setError } = useForm({
    // resolver: yupResolver(projectSchema),
    mode: "onChange",
  });
  // console.log("SubmitDialog22:", data);

  const onSubmit = (formData: any) => {
    const { selectedCards, maxTokens, temperature, topP } = data;
    const normalizedCards = Array.isArray(selectedCards)
      ? selectedCards
      : selectedCards
      ? [selectedCards]
      : [];

    const key = normalizedCards
      ?.map((card: any) => card.versionName)
      .sort()
      .join(",");

    const existingPresets = JSON.parse(localStorage.getItem("presets") || "{}");

    const isDuplicateName = normalizedCards.some((card: any) => {
      const modelPresets = existingPresets[card.versionName] || [];
      return modelPresets.some((preset: any) => preset.name === formData.name);
    });

    if (isDuplicateName) {
      setError("name", {
        type: "manual",
        message: "This preset name already exists in this model.",
      });
      setNameError("This preset name already exists in model.");
      return;
    }

    const newPreset = {
      name: formData.name,
      maxTokens,
      topP,
      temperature,
      selectedCards: normalizedCards,
      applicableKeys: normalizedCards.map((card: any) => card.versionName),
    };

    if (!existingPresets[key]) existingPresets[key] = [];
    if (existingPresets[key].length >= 3) {
      alert("You can only save up to 3 presets for this selection.");
      return;
    }

    existingPresets[key].push(newPreset);
    localStorage.setItem("presets", JSON.stringify(existingPresets));
    onPresetsUpdate();
    setOpen(false);
    reset();
    setNameError("");
  };

  return (
    <DialogWrapper open={open} setOpen={setOpen} title={"Add Preset"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextField
          formateType={Formate.Alphanumeric}
          control={control}
          label="Preset Name"
          name="name"
          required
          placeholder="Preset 1"
          backgroundColor="#FFFFFF"
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "10px",
            mt: 2,
          }}
        >
          <MultiTypeButton
            actionOnClick={() => {
              setOpen(false);
              reset();
              setNameError("");
            }}
            typeText="Cancel"
            buttonType={ButtonType.Cancel}
            key={"presets-cancel"}
          />
          <MultiTypeButton
            actionOnClick={handleSubmit(onSubmit)}
            //   isLoading={uploadLoader || uploadLoader2}
            typeText="Submit"
            buttonType={ButtonType.Gradient}
            key={"presets-submit"}
          />
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default SavePresentDialogCard;
