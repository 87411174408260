import { Box, Stack, styled, Tabs } from "@mui/material";

interface StyledTabsProps {
  size: "small" | "medium";
  containerVariant?: "page" | "box";
  underlineColor?: string;
  mobileUnderlineColor?: string;
}

export const StyledTabs = styled(Tabs)<StyledTabsProps>(
  ({
    theme,
    size,
    containerVariant,
    underlineColor,
    mobileUnderlineColor,
  }) => ({
    background: "#FDF7F4",
    minHeight: "50px",
    ["@media(max-width: 680px)"]: {
      minHeight: "30px",
    },
    padding: "0px 20px",
    border: "1px solid #1111110D",
    // borderBottom: "1px solid #1111110D",
    "& .MuiTabs-indicator": {
      backgroundColor: underlineColor ?? theme.palette.primary.main,
      ["@media(max-width: 680px)"]: {
        backgroundColor: mobileUnderlineColor,
      },
    },
    "& span": {
      color: theme.palette.error.main,
      fontSize: "20px",
      marginTop: "-20px",
      marginLeft: "3px  !important",
    },
    "& .MuiTabs-flexContainer": {
      height: "100%",
      alignItems: "end",
      gap: "30px",
      justifyContent: "flex-start",
      ["@media(max-width: 680px)"]: {
        justifyContent: "center",
      },
    },
    "& .MuiTab-root": {
      textTransform: "none",
      transition: "color 0.2s ease-in-out",
      padding: "5px 15px",
      paddingBottom: "8px",
      minHeight: 0,
      fontSize: size === "medium" ? "18px" : "16px",
      fontWeight: "600",

      "&:hover": {
        backgroundColor: "transparent",
      },
      ["@media(max-width: 680px)"]: {
        fontWeight: "500",
      },
    },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    ...(containerVariant === "page" && {
      background: "none",
      paddingLeft: 0,
      borderBottom: theme.palette.secondary.main,
    }),
  })
);

export const StyledContainer = styled(Stack)(({ theme }) => ({
  borderRadius: "20px",
  padding: "0px 20px",
}));

export const StyledComponentWrapper = styled(Box)<{
  containerVariant: "page" | "box";
}>(({ theme, containerVariant }) => ({
  height: "100%",
  ...(containerVariant === "page" && {
    padding: "15px 0",
  }),
}));

export const StyledTabsWrapper = styled(Box)(({ theme }) => ({
  borderBottom: theme.palette.secondary.main,
}));
