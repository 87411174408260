import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";

const CardSelector = ({
  names,
  onNameSelect,
  cards,
  setDelete,
  emptyPreset,
}: {
  names: string[];
  onNameSelect: (selectedName: string) => void;
  cards?: any;
  setDelete?: any;
  emptyPreset?: any;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (names.length === 0) {
      setSelectedIndex(0);
      onNameSelect("");
      return;
    }

    if (selectedIndex >= names.length) {
      setSelectedIndex(names.length - 1);
    }

    const selectedName = names[selectedIndex];
    if (selectedName) {
      const selectedCard = cards.find(
        (card: any) =>
          `${card?.providerName} ${card?.versionName}` === selectedName
      );
      onNameSelect(selectedCard || null);
    }
  }, [selectedIndex, names, cards, onNameSelect]);

  const handleUp = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? names.length - 1 : prevIndex - 1
    );
    setDelete(false);
    emptyPreset(null);
  };

  const handleDown = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === names.length - 1 ? 0 : prevIndex + 1
    );
    setDelete(false);
    emptyPreset(null);
  };

  useEffect(() => {
    if (!names[selectedIndex]) {
      const newIndex = selectedIndex === 0 ? 0 : selectedIndex - 1;
      setSelectedIndex(newIndex);
    }
  }, [names, selectedIndex]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5vw",
        position: "relative",
        bgcolor: "#FDF7F4",
        border: "1px solid #1212121A",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 0.5vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
          }}
        >
          {names[selectedIndex]}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0px",
        }}
      >
        <IconButton
          size="small"
          onClick={handleUp}
          sx={{
            p: 0,
            m: 0,
          }}
        >
          <ExpandLessIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleDown}
          sx={{
            p: 0,
            m: 0,
          }}
        >
          <ExpandMoreIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CardSelector;
