import { Box, Typography } from "@mui/material";
import Cookies from "js-cookie";
import Lottie from "lottie-react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { orangeLoader } from "../../../assets/lottie/orangeLoader";
import { AuthStore } from "../../../store/general";
import OnboardingPageWrapper from "../../shared/components/Wrapper/PageWrapper";
import { githubLogin } from "../services/AuthService";

function GitHubCallback() {
  const navigate = useNavigate();

  // Extract code from URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  // Use React Query's useQuery to manage API call and data state
  const {} = useQuery(
    ["gitHubCallback", { token: code?.toString() }],
    githubLogin,
    {
      enabled: !!code,
      onSuccess: (data) => {
        if (data.isAccountVerified) {
          Cookies.set("access_token", data.access_token);
          navigate("/app", { replace: true });
        } else {
          Cookies.set("refresh_Token", data.access_token);
          AuthStore.update((s) => {
            s.userName = data.userName;
          });
          navigate("/register", { replace: true });
        }
      },
    }
  );

  return (
    <OnboardingPageWrapper>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2">Please Wait....</Typography>
        <Lottie
          animationData={orangeLoader}
          loop={true}
          autoplay={true}
          style={{ width: "150px", height: "150px" }} // Fixed size for better presentation
        />
      </Box>
    </OnboardingPageWrapper>
  );
}

export default GitHubCallback;
