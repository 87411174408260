export const DATA_KEY = "GradingParams";

export const getGradingData = () => {
  const data = localStorage.getItem(DATA_KEY);
  return data ? JSON.parse(data) : [];
};

export const saveGradingData = (data: any[]) => {
  localStorage.setItem(DATA_KEY, JSON.stringify(data));
};

export const addGradingParameter = (param: any) => {
  if (!param.hasOwnProperty("type")) {
    param.type = "Custom";
  }

  const data = getGradingData();
  data.push(param);
  saveGradingData(data);
};

export const deleteGradingParameter = (index: number) => {
  const data = getGradingData();
  data.splice(index, 1);
  saveGradingData(data);
};

export function formatNumber(num: number): string {
  if (num >= 1000) {
    return (num / 1000)?.toFixed(num % 1000 === 0 ? 0 : 1) + "k";
  }
  return num?.toString();
}

export const getCurrencySymbol = (currency: string) => {
  const symbols: Record<string, string> = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    INR: "₹",
    JPY: "¥",
    CNY: "¥",
    AUD: "A$",
    CAD: "C$",
    CHF: "CHF",
  };

  return symbols[currency] || "";
};
