import { Box } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Icons } from "../../../assets";
import { getAllUsers } from "../../layout/services/LayoutService";
import { useConfirm } from "../../project/components/confirmDialog";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import SearchContainer from "../../shared/components/SearchContainer";
import Table from "../../shared/components/Table";
import UsersForm from "./usersForm";

const Users = () => {
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [selectedRow, setSelectedRow] = useState<any>(null);

  const { data: Users, isLoading }: any = useQuery(
    ["users", search, limit, offset],
    () => getAllUsers({ search, limit, offset })
  );

  // const { mutate } = useMutation(deleteProject, {
  //   onSuccess: () => {
  //     toast.success("User deleted successfully");
  //     queryClient.invalidateQueries("projects");
  //   },
  //   onError: (err) => handleError(err),
  // });

  const handleDelete = (items: any) => {
    // const idsToDelete =
    //   items.length >= 2 ? items.map((item: any) => item?._id) : [items[0]?._id];
    // confirm({
    //   msg: "Are you sure you want to delete this?",
    //   action: () => {
    //     mutate({ ids: idsToDelete });
    //   },
    // });
  };

  const handleView = (data: any) => {
    // navigate(`/app/projects/${data?._id}/prompts`, { replace: true });
  };

  const renderButtons = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        {selectedRow.length >= 2 ? (
          <MultiTypeButton
            buttonType={ButtonType.Outlined}
            typeText={"Delete users"}
            actionOnClick={() => handleDelete(selectedRow)}
            icon={Icons.TrashBin}
          />
        ) : (
          <>
            <MultiTypeButton
              buttonType={ButtonType.Outlined}
              isLoading={isLoading}
              typeText={"Delete"}
              actionOnClick={() => handleDelete(selectedRow)}
              icon={Icons.TrashBin}
            />
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Edit"}
              actionOnClick={() => setOpen2(true)}
              icon={Icons.Edit}
            />

            <UsersForm open={open2} setOpen={setOpen2} data={selectedRow[0]} />
          </>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ padding: "30px 25px" }}>
      <Box display="flex" flexDirection={"row"} justifyContent="space-between">
        <SearchContainer debounced={true} onChange={setSearch} value={search} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Box>
            {selectedRow?.length >= 1 &&
              Users?.data?.length >= 1 &&
              renderButtons()}
          </Box>
          <Box>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Create new user"}
              actionOnClick={() => setOpen(true)}
            />
            <UsersForm open={open} setOpen={setOpen} data={null} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Table
          data={Users?.data || []}
          columns={columns}
          loading={isLoading}
          is_Id={true}
          renderButtons={renderButtons}
          onRowSelectionChange={(selected: any) => {
            setSelectedRow(selected || null);
          }}
          onViewClick={(item: any) => handleView(item)}
          pagination={{
            totalCount: Users?.data?.length,
            pageCount: limit,
            setPageCount: setLimit,
            page: offset / limit,
            setPage: (page) => {
              const newOffset = page * limit;
              setOffset(newOffset);
            },
          }}
          onPageChangeCallback={(paginationData) => {
            setOffset(paginationData.offset);
            setLimit(paginationData.limit);
          }}
        />
      </Box>
    </Box>
  );
};

export default Users;

const columns = [
  { key: "name", title: "User name" },
  { key: "emailAddress", title: "Email Address" },
  // {
  //   key: "role",
  //   title: "User Role",

  // },
  // { key: "status", title: "Status" },
  {
    key: "createdAt",
    title: "Created on",
    render: (e: any) => {
      const Date = moment(e?.createdAt).format("YYYY-MM-DD");
      return Date || "NA";
    },
  },
  // { key: "user.name", title: "Created by" },
];
