import { useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { UIStore } from "../../../../../store/general";

interface OtpInputProps {
  length: number;
  onChangeOtp: (otp: string) => void;
}

const OtpInput = ({ length, onChangeOtp }: OtpInputProps) => {
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  const [otp, setOtp] = useState<string[]>(Array(length).fill(""));
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const theme = useTheme();

  const handleChange = (element: HTMLInputElement, index: number) => {
    const value = element.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      onChangeOtp(newOtp.join(""));

      if (value && index < length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const paste = event.clipboardData.getData("text");
    const digits = paste.split("").filter((char) => /^[0-9]$/.test(char));

    if (digits.length > 0) {
      const newOtp = [...otp];
      const startIndex = Number(event.currentTarget.dataset.index) || 0;
      let focusIndex = startIndex;

      for (let i = startIndex; i < length; i++) {
        if (digits.length > 0) {
          newOtp[i] = digits.shift()!;
          focusIndex = i;
        }
      }

      setOtp(newOtp);
      onChangeOtp(newOtp.join(""));
      inputRefs.current[Math.min(focusIndex + 1, length - 1)].focus();
    }
  };

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      {otp.map((value, index) => (
        <input
          className="MuiOtpInput"
          key={index}
          ref={(el) => (inputRefs.current[index] = el!)}
          type="text"
          value={value} // Display actual OTP digit instead of masking
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          data-index={index}
          maxLength={1}
          style={{
            width: "50px",
            height: "50px",
            textAlign: "center",
            fontSize: "20px",
            // color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey[200],
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: "4px",
            transition: "all 0.2s ease",
            outline: "none",
          }}
          onFocus={(e) =>
            (e.currentTarget.style.borderColor = theme.palette.warning.main)
          }
          onBlur={(e) =>
            (e.currentTarget.style.borderColor = theme.palette.secondary.main)
          }
        />
      ))}
      <style>
        {`
          .MuiOtpInput:focus {
            box-shadow: 0 0 15px rgba(255, 165, 0, 0.1); /* Slight glow effect */
            transform: scale(1.2); /* Slightly enlarge on focus */
          }
        `}
      </style>
    </div>
  );
};

export default OtpInput;
export type { OtpInputProps };
