import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

type SimpleCheckBoxProps = {
  isChecked: Function;
  control?: any;
  text: String;
};

export default function SimpleCheckBox({
  isChecked,
  control,
  text,
}: SimpleCheckBoxProps) {
  return (
    <Controller
      name="checked"
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <MuiCheckbox
              size="large"
              {...field}
              onClick={(event: any) => {
                isChecked(event?.target?.checked);
                event.stopPropagation();
              }}
            />
          }
          label={<Typography variant="subtitle2">{text}</Typography>}
        />
      )}
    />
  );
}
