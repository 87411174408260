import { http } from "../../../app/services";

const addProject = (data: any) => {
  return http.post("/projects", data);
};

const getAllProjects = (queryKey: any) => {
  return http
    .get(`/projects`, {
      params: { ...queryKey },
    })
    .then((res) => res.data);
};

const getOneProject = ({ queryKey }: any) => {
  return http.get(`/projects/${queryKey[1]}`).then((res) => res.data);
};

const updateProject = ({ data, id }: any) => {
  return http.put(`/projects/${id}`, data).then((res) => res.data);
};

const deleteProject = ({ ids }: any) => {
  return http.delete(`/projects`, { data: { ids } });
};

export {
  addProject,
  deleteProject,
  getAllProjects,
  getOneProject,
  updateProject,
};
