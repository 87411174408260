import { Box } from "@mui/material";
import Lottie from "lottie-react";
import { orangeLoader } from "../../../../assets/lottie/orangeLoader";
interface SpinnerProps {
  boundingBox?: boolean;
  width?: string;
  height?: string;
}
export default function Spinner({ boundingBox, width, height }: SpinnerProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: boundingBox ? "85vh" : "auto",
      }}
    >
      <Lottie
        animationData={orangeLoader}
        loop={true}
        autoplay={true}
        style={{
          width: width ? width : "150px",
          height: height ? height : "150px",
        }} // Fixed size for better presentation
      />
    </Box>
  );
}
