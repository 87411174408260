// Black, BlackItalic, Bold, BoldItalic, ExtraLight, ExtraLightItalic, Italic, Light, LightItalic, Regular, Semibold, SemiboldItalic
const blackFigtree = require("./Figtree-Black.ttf");
const blackItalicFigtree = require("./Figtree-BlackItalic.ttf");
const boldFigtree = require("./Figtree-Bold.ttf");
const boldItalicFigtree = require("./Figtree-BoldItalic.ttf");

const extraBoldFigtree = require("./Figtree-ExtraBold.ttf");
const extraBoldItalicFigtree = require("./Figtree-ExtraBoldItalic.ttf");

const lightItalicFigtree = require("./Figtree-LightItalic.ttf");
const mediumFigtree = require("./Figtree-Medium.ttf");
const mediumItalicFigtree = require("./");

// const extraLightFigtree = require("./Figtree-ExtraLight.ttf");
// const extraLightItalicFigtree = require("./Figtree-ExtraLightItalic.ttf");

const italicFigtree = require("./Figtree-Italic.ttf");
const lightFigtree = require("./Figtree-Light.ttf");
const regularFigtree = require("./Figtree-Regular.ttf");

const semiboldFigtree = require("./Figtree-SemiBold.ttf");
const semiboldItalicFigtree = require("./Figtree-SemiBoldItalic.ttf");

export {
  blackFigtree,
  blackItalicFigtree,
  boldFigtree,
  boldItalicFigtree,
  extraBoldFigtree,
  extraBoldItalicFigtree,
  //   extraLightFigtree,
  //   extraLightItalicFigtree,
  italicFigtree,
  lightFigtree,
  lightItalicFigtree,
  mediumFigtree,
  mediumItalicFigtree,
  regularFigtree,
  semiboldFigtree,
  semiboldItalicFigtree,
};
