const Project = ({ color = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
  >
    <path
      d="M6.86416 12.1063C4.3818 11.1134 3.14062 10.6169 3.14062 10C3.14062 9.38306 4.3818 8.88659 6.86416 7.89365L10.3748 6.48941C12.8571 5.49648 14.0983 5 15.6406 5C17.183 5 18.4241 5.49648 20.9065 6.48941L24.4171 7.89365C26.8995 8.88659 28.1406 9.38306 28.1406 10C28.1406 10.6169 26.8995 11.1134 24.4171 12.1063L20.9065 13.5106C18.4241 14.5035 17.183 15 15.6406 15C14.0983 15 12.8571 14.5035 10.3748 13.5106L6.86416 12.1063Z"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
    />
    <path
      d="M7.84829 12.5L6.86416 12.8936C4.3818 13.8866 3.14062 14.3831 3.14062 15C3.14062 15.6169 4.3818 16.1134 6.86416 17.1064L10.3748 18.5106C12.8571 19.5035 14.0983 20 15.6406 20C17.183 20 18.4241 19.5035 20.9065 18.5106L24.4171 17.1064C26.8995 16.1134 28.1406 15.6169 28.1406 15C28.1406 14.3831 26.8995 13.8866 24.4171 12.8936L23.433 12.5"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
    />
    <path
      d="M7.84829 17.5L6.86416 17.8936C4.3818 18.8866 3.14062 19.3831 3.14062 20C3.14062 20.6169 4.3818 21.1134 6.86416 22.1064L10.3748 23.5106C12.8571 24.5035 14.0983 25 15.6406 25C17.183 25 18.4241 24.5035 20.9065 23.5106L24.4171 22.1064C26.8995 21.1134 28.1406 20.6169 28.1406 20C28.1406 19.3831 26.8995 18.8866 24.4171 17.8936L23.433 17.5"
      stroke={color}
      stroke-opacity="0.7"
      stroke-width="2"
    />
  </svg>
);

export default Project;
