import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { Icons } from "../../../../assets";
import { UIStore } from "../../../../store/general";

// Enum for button types
export enum ButtonType {
  Google = "Google",
  GitHub = "GitHub",
  Gradient = "Gradient",
  Cancel = "Cancel",
  Outlined = "Outlined",
}

interface MultiTypeButtonProps {
  typeText?: string;
  actionOnClick: () => void;
  width?: string | number;
  height?: string | number;
  buttonType: ButtonType;
  disabled?: boolean;
  isLoading?: boolean;
  icon?: any;
}

// Styled LoadingButton for consistent styling across buttons
const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  border: "1px solid black",
  "& .MuiCircularProgress-root": {
    color: "rgba(240, 121, 70, 1)",
  },
  "&.MuiButton-outlined": {
    border: "2px solid rgba(240, 121, 70, 1)",
  },
}));

export default function MultiTypeButton({
  typeText,
  actionOnClick,
  width,
  height,
  buttonType,
  disabled = false,
  isLoading = false,
  icon = null,
}: MultiTypeButtonProps) {
  const theme = useTheme();
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);

  const getButtonProps = (type: ButtonType) => {
    switch (type) {
      case ButtonType.Google:
        return {
          bgColor: theme.palette.textField.defaultBackground,
          icon: Icons.googleLogo,
          text: typeText || "Sign Up with Google",
        };
      case ButtonType.GitHub:
        return {
          bgColor: theme.palette.textField.defaultBackground,
          icon: Icons.githubLogo,
          text: typeText || "Sign In with GitHub",
        };
      case ButtonType.Gradient:
        return {
          bgColor: `linear-gradient(to bottom, rgba(240, 121, 70, 1), rgba(244, 181, 55, 1))`,
          text: typeText || "Gradient Button",
          hoverColor: "white",
        };
      case ButtonType.Cancel:
        return {
          bgColor: `#E2E2E2`,
          borderColor: "#E2E2E2",
          text: typeText || "Gradient Button",
          hoverColor: "none",
        };
      default:
        return {
          bgColor: theme.palette.textField.defaultBackground,
          hoverColor: "white",
          text: typeText,
        };
    }
  };

  const { bgColor, hoverColor, text, borderColor } = getButtonProps(buttonType);

  return isLoading ? (
    <StyledLoadingButton
      loading
      fullWidth
      variant={"outlined"}
      startIcon={<SaveIcon />}
      sx={{
        width: width,
        borderRadius: "8px",
        // "&:hover": {
        //   backgroundColor: hoverColor,
        // },
      }}
    />
  ) : (
    <Button
      fullWidth
      variant={buttonType === ButtonType.Gradient ? "contained" : "outlined"}
      onClick={actionOnClick}
      disabled={disabled}
      sx={{
        background: disabled ? null : bgColor,
        width: width,
        height: height,
        borderRadius: "8px",
        borderColor: borderColor,
        textWrap: "nowrap",
        "&:hover": {
          backgroundColor: hoverColor,
        },
      }}
    >
      {icon && <img width={"22px"} src={icon} alt="icon" />}
      <Typography
        color={
          buttonType === ButtonType.Gradient
            ? "#FFFFFF"
            : buttonType === ButtonType.Cancel
            ? "#12121299"
            : icon && isDarkMode
            ? "#FFFFFF"
            : "#F07946"
        }
        paddingLeft={icon ? "10px" : "0"}
        variant="h4"
        fontWeight={buttonType === ButtonType.Gradient ? "500" : "500"}
      >
        {text}
      </Typography>
    </Button>
  );
}
