import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Helper function to display error messages
export const displayErrorMessage = (error: any) => {
  if (error.response && error.response.data && error.response.data.message) {
    const message = error.response.data.message;
    const errorMessage = Array.isArray(message) ? message.join(", ") : message;
    toast.error(errorMessage); // Show toast error
  } else {
    toast.error(error); // Fallback message
  }
};

// Helper function to display success messages
export const displaySuccessMessage = (message: any) => {
  toast.success(message); // Show toast success
};

// Axios instance setup
export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
  },
});

// Intercept requests to add Authorization header
http.interceptors.request.use((config) => {
  const token = Cookies.get("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Intercept responses to handle errors and success messages
http.interceptors.response.use(
  (response) => {
    if (response.data && response.data.success) {
      // displaySuccessMessage(response.data.message);
    }
    return response; // Return the response if no errors
  },
  (error) => {
    // console.log("Error captured: ", error);

    if (error.message === "Network Error" && !navigator.onLine) {
      toast.error("Network error. Please check your internet connection.");
    } else if (axios.isCancel(error)) {
      toast.warn("Request was canceled.");
    } else if (error.response && error.response.status === 401) {
      Cookies.remove("access_token");
      localStorage.clear();
      window.location.href = "/signup";
    } else {
      // displayErrorMessage(error);
    }

    return Promise.reject(error); // Propagate the error
  }
);
