import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../../shared/components/Errors/HandleError";
import { SingleSelect } from "../../../shared/components/Select/SingleSelect";
import {
  Formate,
  TextField,
} from "../../../shared/components/TextField/TextField";
import DialogWrapper from "../../../shared/components/Wrapper/DialogWrapper";
import { createAPIKey } from "../../services";
import { tier } from "../../utility";

const apiSchema = yup.object({
  apiProvider: yup.string().required("AI Provider is required"),
  tier: yup.string().required("Tier is required"),
  apiKey: yup.string().required("API Key is required"),
});

const APIDialogCard = ({ open, setOpen, data }: any) => {
  const queryClient = useQueryClient();

  const { control, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(apiSchema),
    mode: "onChange",
    defaultValues: {
      apiProvider: data?.providerName || "",
      tier: "",
      apiKey: data?.apiKey || "",
    },
  });

  useEffect(() => {
    if (data?.providerName) {
      setValue("apiProvider", data?.providerName);
    }
  }, [data?.providerName, setValue]);

  const { mutate, isLoading } = useMutation(createAPIKey, {
    onSuccess: () => {
      toast.success("API Key Added  Successfully");
      queryClient.invalidateQueries("projects");
      queryClient.invalidateQueries("all-models");

      setOpen(false);
    },
    onError: (err) => handleError(err),
  });

  const onSubmit = (value: any) => {
    const mutationData = {
      providerId: data?.providerId,
      tier: value?.tier,
      apiKey: value.apiKey,
    };
    mutate(mutationData);
  };

  return (
    <>
      <DialogWrapper open={open} setOpen={setOpen} title={"Add API Key"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <TextField
            formateType={Formate.Alphanumeric}
            control={control}
            name="apiProvider"
            label="API Provider"
            required
            placeholder="Enter API provider"
            backgroundColor="#FFFFFF"
            defaultValue={data?.providerName}
          />

          <SingleSelect
            control={control}
            required
            name="tier"
            label="Tier"
            options={tier}
            defaultData={tier?.[0]?.value}
            placeholder="Tier"
            backgroundColor="#FFFFFF"
            // border="#F8F8F8"
          />

          <TextField
            formateType={Formate.Alphanumeric}
            control={control}
            label="API Key"
            name="apiKey"
            required
            placeholder="API Key"
            backgroundColor="#FFFFFF"
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              mt: 2,
              justifyContent: "space-between",
            }}
          >
            <MultiTypeButton
              buttonType={ButtonType.Cancel}
              // isLoading={isLoading}
              typeText={"Cancel"}
              actionOnClick={() => {
                setOpen(false);
              }}
            />
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              // isLoading={isLoading}
              typeText={"Save"}
              actionOnClick={handleSubmit(onSubmit)}
            />
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

export default APIDialogCard;
