import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Icons } from "../../../assets";
import { UIStore } from "../../../store/general";
import { useConfirm } from "../../project/components/confirmDialog";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../shared/components/Errors/HandleError";
import SearchContainer from "../../shared/components/SearchContainer";
import Table from "../../shared/components/Table";
import { deleteDataset, getAllDatasets } from "../services/DatasetService";

export default function AllDatasets() {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [viewMode, setViewMode] = useState<"table" | "grid">("table");

  const { data: GetAllDatasets, isLoading }: any = useQuery(
    ["get-Datasets-details", search, limit, offset],
    () => getAllDatasets({ search, limit, offset, projectId }),
    {
      onSuccess: (res: any) => {
        // if (res?.data?.length > 0) {
        //   navigate("/app/datasets/all");
        // }
      },
    }
  );

  const { mutate: deleteDatasets } = useMutation(deleteDataset, {
    onSuccess: () => {
      toast.success("Datasets Deleted Successfully");
      queryClient.invalidateQueries("get-Datasets-details");
    },
    onError: (err) => handleError(err),
  });

  const handleDelete = (items: any) => {
    const idsToDelete =
      items.length >= 2 ? items.map((item: any) => item?._id) : [items[0]?._id];

    confirm({
      msg: "Are you sure you want to delete this?",
      action: () => {
        deleteDatasets({ ids: idsToDelete });
      },
    });
  };

  const renderButtons = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5vw" }}>
        {selectedRow.length >= 2 ? (
          <MultiTypeButton
            buttonType={ButtonType.Outlined}
            typeText={"Delete"}
            actionOnClick={() => handleDelete(selectedRow)}
            icon={Icons.TrashBin}
          />
        ) : (
          <>
            <MultiTypeButton
              buttonType={ButtonType.Outlined}
              isLoading={isLoading}
              typeText={"Delete"}
              actionOnClick={() => handleDelete(selectedRow)}
              icon={Icons.TrashBin}
            />
            {/* <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Edit Project"}
              actionOnClick={() => setOpen(true)}
              icon={Icons.Edit}
            /> */}

            {/* <AddProjectForm
              open={open2}
              setOpen={setOpen2}
              data={GetAllProjects?.projects[0]}
            /> */}
          </>
        )}
      </Box>
    );
  };

  const columns = [
    {
      key: "name",
      title: "Dataset title",
      render: (e: any) => {
        return (
          <>
            {" "}
            <Typography
              variant="subtitle2"
              onClick={() => {
                if (projectId) {
                  navigate(
                    `/app/projects/${projectId}/datasets/${e?._id}/detailed-view`
                  );
                } else {
                  navigate(`/app/datasets/${e?._id}/detailed-view`);
                }
              }}
            >
              {e?.name || "NA"}
            </Typography>
          </>
        );
      },
    },
    {
      key: "tag",
      title: "Dataset tag",
      render: (e: any) => {
        return (
          <>
            <Typography
              variant="subtitle2"
              onClick={() => {
                if (projectId) {
                  navigate(
                    `/app/projects/${projectId}/datasets/${e?._id}/detailed-view`
                  );
                } else {
                  navigate(`/app/datasets/${e?._id}/detailed-view`);
                }
              }}
            >
              {e?.dataSetVersions?.find((obj: any) => obj?.versionNumber === 1)
                ?.tag || "NA"}
            </Typography>
          </>
        );
      },
    },
    {
      key: "createdAt",
      title: "Created on",
      render: (e: any) => {
        const Date = moment(e?.createdAt).format("YYYY-MM-DD");
        return Date || "NA";
      },
    },
    {
      key: "versions",
      title: "Versions",
      render: (e: any) => {
        return (
          <>
            {" "}
            <Box
              sx={{
                width: "6vw",
                bgcolor: isDarkMode ? "rgba(75, 170, 190, 0.3)" : "#C75737",
                textAlign: "center",
                padding: "4px",
                borderRadius: "5px",
              }}
              onClick={() => {
                if (projectId) {
                  navigate(
                    `/app/projects/${projectId}/datasets/${e?._id}/detailed-view`
                  );
                } else {
                  navigate(`/app/datasets/${e?._id}/detailed-view`);
                }
              }}
            >
              <Typography
                color={isDarkMode ? "rgba(75, 170, 190, 1)" : "white"}
                variant="subtitle2"
              >
                {e?.dataSetVersions?.length || "NA"}
              </Typography>
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <Box sx={{ padding: "3.5vh 2vw" }}>
      <Box display="flex" flexDirection={"row"} justifyContent="space-between">
        <Typography
          variant="h1"
          sx={{
            fontWeight: 600,
          }}
        >
          Datasets
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5vw",
            alignItems: "center",
          }}
        >
          <SearchContainer
            debounced={true}
            onChange={setSearch}
            value={search}
          />
          <Box>
            {selectedRow?.length >= 1 &&
              GetAllDatasets?.data?.length >= 1 &&
              renderButtons()}
          </Box>
          <Box>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Create dataset"}
              actionOnClick={() => {
                if (projectId) {
                  navigate(`/app/projects/${projectId}/datasets/create`);
                } else {
                  navigate("/app/datasets/create");
                }
              }}
            />
          </Box>

          {/* <Grid>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <ToggleButtonGroup
                  value={viewMode}
                  exclusive
                  onChange={(e, newViewMode) =>
                    newViewMode && setViewMode(newViewMode)
                  }
                  sx={{ marginLeft: "20px" }}
                >
                  <ToggleButton
                    value="table"
                    sx={{
                      ...getToggleButtonStyles(viewMode === "table"),
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <TableRows />
                  </ToggleButton>
                  <ToggleButton
                    value="grid"
                    sx={{
                      ...getToggleButtonStyles(viewMode === "grid"),
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <Apps />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid> */}
        </Box>
      </Box>

      <Box sx={{ mt: "4vh" }}>
        {viewMode === "table" ? (
          <Table
            data={GetAllDatasets?.data || []}
            columns={columns}
            loading={isLoading}
            is_Id={true}
            renderButtons={renderButtons}
            onRowSelectionChange={(selected: any) => {
              // console.log("Selected Row:", selected);
              setSelectedRow(selected || null);
            }}
            // onViewClick={(item: any) => handleView(item)}
            pagination={{
              totalCount: GetAllDatasets?.count,
              pageCount: limit,
              setPageCount: setLimit,
              page: offset / limit,
              setPage: (page) => {
                const newOffset = page * limit;
                setOffset(newOffset);
              },
            }}
            onPageChangeCallback={(paginationData) => {
              setOffset(paginationData.offset);
              setLimit(paginationData.limit);
            }}
          />
        ) : (
          <></>
          // <ProjectsGrid
          //   paginatedData={GetAllDatasets?.data || []}
          //   handleDelete={() => handleDelete(selectedRow)}
          //   handleEdit={() => setOpen(true)}
          //   // onViewClick={(item: any) => handleView(item)}
          // />
        )}
      </Box>
    </Box>
  );
}
