import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Box,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { ColDef, GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { Icons } from "../../../../assets";
import MultiTypeButton, { ButtonType } from "../Button/MultiTypeButton";
import CustomHeader from "./CustomHeader";
import "./grid.css";

// Assuming CustomHeader component is already defined as shown in your code

interface GridExampleProps {
  rowsData?: any[];
  loading?: boolean;
  setLimit?: any;
  setOffset?: any;
}

const DataGrid = ({
  rowsData = [],
  loading = false,
  setLimit,
  setOffset,
}: GridExampleProps) => {
  const [rowData, setRowData] = useState<any[]>(rowsData || []);
  const [selectedCell, setSelectedCell] = useState<
    { rowIndex: number; colId: string } | any
  >(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | any>();
  const [isAllLocked, setIsAllLocked] = useState(false);
  const [RowHeight, setRowHeight] = useState<any>("250");
  const [presetData, setPresetData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState(1);

  let totalPages = rowData?.length / pageSize;

  console.log(totalPages);

  const rowHeight = Number(RowHeight);

  // console.log("presetData :", presetData);

  // useEffect(() => {
  //   localStorage.setItem("presetData", JSON.stringify(presetData));
  // }, [presetData]);

  // Update rowData and columnDefs when rowsData changes
  // useEffect(() => {
  //   if (rowsData?.length > 0) {
  //     setRowData(rowsData);
  //     const dynamicColumnDefs = Object.keys(rowsData[0]).map((key, index) => ({
  //       field: key,
  //       flex: 1,
  //       editable: false,
  //       pinned: index === 0 ? "left" : undefined,
  //       lockPosition: index === 0,
  //       suppressMovable: index === 0,
  //       suppressSizeToFit: index === 0,
  //       headerComponent: (props: any) =>
  //         CustomHeader(props, rowsData, setPresetData),
  //       cellRenderer: (cellData: any) => renderCell(cellData, key),
  //     }));
  //     setColumnDefs(dynamicColumnDefs as ColDef<any, any>[]);
  //   }
  // }, [rowsData, selectedCell, presetData]);

  const dynamicColumnDefs: any = useMemo(() => {
    if (rowsData?.length > 0) {
      return Object.keys(rowsData[0]).map((key, index) => ({
        field: key,
        flex: 1,
        editable: false,
        pinned: index === 0 ? "left" : undefined,
        lockPosition: index === 0,
        suppressMovable: index === 0,
        suppressSizeToFit: index === 0,
        headerComponent: (props: any) =>
          CustomHeader(props, rowsData, setPresetData),
        cellRenderer: (cellData: any) => renderCell(cellData, key),
      }));
    }

    return [];
  }, [rowsData, presetData, CustomHeader]);

  useEffect(() => {
    setColumnDefs(dynamicColumnDefs);
    localStorage.setItem("presetData", JSON.stringify(presetData));
  }, [dynamicColumnDefs]);

  const renderCell = (cellData: any, key: any) => {
    const isSelected =
      selectedCell?.rowIndex === cellData.node.rowIndex &&
      selectedCell?.colId === key;

    const selectedPreset: any = presetData?.find(
      (p: any) => p?.columnId === key
    );

    if (key === "DatasetRecord") {
      return (
        <>
          {isSelected ? (
            <>
              <button
                onClick={() => alert(`Cell Text: ${cellData.value}`)}
                style={{
                  background: "#e3f2fd",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
              >
                {cellData.value}
              </button>
            </>
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                mx={"5px"}
                variant="subtitle1"
                sx={{ textDecoration: "underline" }}
              >
                {" "}
                {cellData.value}
              </Typography>
            </Box>
          )}
        </>
      );
    } else {
      return (
        <Box sx={{ height: "100%", width: "100%", padding: "5px" }}>
          <Typography mx={"5px"} variant="subtitle2" color="#121212B2">
            {
              cellData?.value?.response?.[selectedPreset?.presetIndex || 0]
                ?.response
            }
          </Typography>
        </Box>
      );
    }
  };

  const defaultColDef: ColDef = {
    cellStyle: (params) => {
      const isSelected = selectedColumns.includes(params.colDef.field || "");
      return {
        fontWeight: isSelected ? "bold" : "normal",
        // backgroundColor: isSelected ? "rgba(25, 118, 210, 0.2)" : "",
        // borderLeft: "0.2px solid lightGrey",
        borderRight: "0.2px solid lightGrey",
        whiteSpace: "normal",
        textAlign: "left",
        padding: "0",
        // borderAll: "2px solid #1976d2",
      };
    },
  };

  const handleLockColumn = (colId: string, editable: boolean) => {
    const newColumnDefs = columnDefs.map((col) => {
      if (col.field === colId) {
        const lockPosition = !col.lockPosition;

        return {
          ...col,
          suppressMovable: lockPosition,
          suppressDrag: lockPosition,
          lockPosition: lockPosition,
        };
      }
      return col;
    });
    setColumnDefs(newColumnDefs);
  };

  const handleToggleAllColumnsLock = () => {
    const newLockedState = !isAllLocked;
    const newColumnDefs = columnDefs.map((col) => ({
      ...col,
      editable: !newLockedState,
      suppressMovable: newLockedState,
      suppressDrag: newLockedState,
      lockPosition: newLockedState,
      suppressSizeToFit: newLockedState,
    }));
    setColumnDefs(newColumnDefs);
    setIsAllLocked(newLockedState);
  };

  const handleToggleVisibility = (colId: string) => {
    setHiddenColumns((prevHidden) =>
      prevHidden.includes(colId)
        ? prevHidden.filter((col) => col !== colId)
        : [...prevHidden, colId]
    );
  };

  const handleShowAllColumns = () => {
    setHiddenColumns([]);
  };

  const handleColumnSelect = (
    colId: string,
    isCtrlPressed: boolean,
    isShiftPressed: boolean
  ) => {
    if (isShiftPressed && selectedColumns.length > 0) {
      const colIndex = columnDefs.findIndex((col) => col.field === colId);
      const lastSelectedIndex = columnDefs.findIndex(
        (col) => col.field === selectedColumns[selectedColumns.length - 1]
      );
      const newSelectedColumns = columnDefs
        .slice(
          Math.min(colIndex, lastSelectedIndex),
          Math.max(colIndex, lastSelectedIndex) + 1
        )
        .map((col) => col.field || "");
      setSelectedColumns(newSelectedColumns);
    } else if (isCtrlPressed) {
      setSelectedColumns((prev) =>
        prev.includes(colId)
          ? prev.filter((col) => col !== colId)
          : [...prev, colId]
      );
    } else {
      setSelectedColumns([colId]);
    }
  };

  const handleExportSelectedColumns = () => {
    if (!gridApi) return;

    gridApi.exportDataAsCsv({
      onlySelected: false,
      columnKeys: selectedColumns.length ? selectedColumns : undefined,
    });
  };

  const handleGlobalKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setSelectedColumns([]);
    }
  };

  const handleClickOutsideGrid = () => {
    setSelectedColumns([]);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleGlobalKeyPress);
    document.addEventListener("click", handleClickOutsideGrid);

    return () => {
      document.removeEventListener("keydown", handleGlobalKeyPress);
      document.removeEventListener("click", handleClickOutsideGrid);
    };
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Set the anchor for the dropdown
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the dropdown
  };

  const handleSelect = (height: number) => {
    setRowHeight(height); // Update row height
    setAnchorEl(null); // Close the dropdown
    // console.log("Selected Row Height:", height);
  };

  const handlePageSizeChange = (e: any) => {
    setPageSize(e?.target?.value);
    // setLimit(e?.target?.value);
  };

  const handlePageChange = (event: any, value: number) => {
    setCurrentPage(value);
    gridApi.paginationGoToPage(value - 1);
  };

  const convertToCSV = (data: any) => {
    // Extract keys for the CSV header
    const headers = Object.keys(data[0]).join(",") + "\n";

    // Map data to CSV rows
    const rows = data
      .map((row: any) => {
        return Object.values(row)
          .map((value) => {
            // Check if the value is an object, and stringify if necessary
            if (typeof value === "object" && value !== null) {
              return `"${JSON.stringify(value)}"`; // Convert object to JSON string
            }
            return `"${value}"`; // Quote values to handle commas in strings
          })
          .join(",");
      })
      .join("\n");

    return headers + rows;
  };

  const downloadCSV = () => {
    if (!rowsData || rowsData.length === 0) {
      alert("No data to export!");
      return;
    }

    const csvData = convertToCSV(rowsData);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `responses.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"end"}
        gap={"1vw"}
        sx={{
          padding: "1vw 1.5vw",
        }}
      >
        <IconButton onClick={handleShowAllColumns}>
          <img
            src={Icons.hide}
            style={{
              width: "1.3rem",
              marginRight: "0.5rem",
            }}
            alt="hide"
          />{" "}
          <Typography variant="subtitle2"> Hide fields</Typography>
        </IconButton>

        <IconButton onClick={handleToggleAllColumnsLock}>
          {isAllLocked ? (
            <>
              <LockOpenIcon
                sx={{ fontSize: "1.2rem", marginRight: "0.5rem" }}
              />
              <Typography variant="subtitle2"> Unlock fields</Typography>
            </>
          ) : (
            <>
              <img
                src={Icons.lock}
                style={{
                  width: "1.2rem",
                  marginRight: "0.5rem",
                }}
                alt="lock"
              />{" "}
              <Typography variant="subtitle2"> Lock fields</Typography>
            </>
          )}
        </IconButton>

        <FormControl>
          <IconButton onClick={handleClick}>
            <Stack direction={"row"}>
              <img
                src={Icons.upDownArrow}
                style={{
                  width: "1.2rem",
                  marginRight: "0.5rem",
                }}
                alt="height"
              />{" "}
              <Typography variant="subtitle2">Row height</Typography>
            </Stack>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "row-height-button",
            }}
          >
            <MenuItem onClick={() => handleSelect(200)}>h1</MenuItem>
            <MenuItem onClick={() => handleSelect(300)}>h2</MenuItem>
            <MenuItem onClick={() => handleSelect(350)}>h3</MenuItem>
            <MenuItem onClick={() => handleSelect(400)}>h4</MenuItem>
            <MenuItem onClick={() => handleSelect(450)}>h5</MenuItem>
          </Menu>
        </FormControl>

        <MultiTypeButton
          actionOnClick={downloadCSV}
          typeText="Export"
          buttonType={ButtonType.Gradient}
          icon={Icons.DownloadIcon}
          width={"10vw"}
        />
        <MultiTypeButton
          actionOnClick={handleExportSelectedColumns}
          typeText="Compare Models"
          buttonType={ButtonType.Gradient}
          width={"10vw"}
          disabled={true}
        />
      </Stack>

      <div
        style={{
          // overflow: "auto",
          width: "100%",
          height: "100%",
          margin: 0,
          padding: 0,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <AgGridReact
          className="ag-theme-alpine"
          loading={loading}
          pagination={true}
          paginationPageSize={pageSize}
          rowData={rowData}
          suppressPaginationPanel={true}
          suppressHorizontalScroll={false}
          onPaginationChanged={(e) => {}}
          columnDefs={columnDefs
            .filter((col) => !hiddenColumns.includes(col.field || ""))
            .map((col, index: any) => {
              return {
                ...col,
                minWidth: col.field === "DatasetRecord" ? 100 : 500,
                headerComponentParams: {
                  onLockColumn: handleLockColumn,
                  onColumnSelect: handleColumnSelect,
                  onToggleVisibility: handleToggleVisibility,
                  onColumnPin: (colId: string, pin: any) => {
                    if (gridApi) gridApi.setColumnPinned(colId, pin);
                  },
                  selectedColumns,
                  hiddenColumns,
                },
              };
            })}
          defaultColDef={defaultColDef}
          rowHeight={rowHeight || 400}
          onGridReady={(params) => setGridApi(params.api)}
          domLayout="autoHeight"
          onCellClicked={(event) => {
            if (event.colDef && event.rowIndex !== undefined) {
              setSelectedCell({
                rowIndex: event.rowIndex,
                colId: event.colDef.field || "",
              });
            }
          }}
          onCellFocused={(event) => {
            if (!event.rowIndex && !event.column) {
              // Deselect cell when focus is lost
              setSelectedCell(null);
            }
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography>Page Size:</Typography>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              size="small"
            >
              {[5, 20, 50, 100].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Typography>
            Note: LLM may display inaccurate info, including about people, so
            double-check its responses.
          </Typography>

          {/* Pagination Controls */}

          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            siblingCount={1}
            boundaryCount={1}
            color="primary"
            sx={{ color: "#F87320" }}
            onChange={handlePageChange}
          />
        </Box>
      </div>
    </Box>
  );
};

export default DataGrid;
