import { Box } from "@mui/material";
import EmptyPage from "../../shared/components/Errors/EmptyPage";

const Billing = () => {
  return (
    <Box>
      <EmptyPage
        buttonTitle="This Page not build Yet!"
        tagLine="Page under Construction"
      />
    </Box>
  );
};

export default Billing;
