import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";
import AutocompleteGeneric from "../../shared/components/Autocomplete/Autocomplete";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import DialogWrapper from "../../shared/components/Wrapper/DialogWrapper";
import { userType } from "../utils/utils";

const usersSchema = yup.object({
  name: yup
    .string()
    .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric characters are allowed")
    .required("Name is required"),
  email: yup.string().required("Email is required"),
  type: yup.string().required("Type is required"),
});

const UsersForm = ({ open, setOpen, data }: any) => {
  console.log("User Data:", data);

  const queryClient = useQueryClient();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(usersSchema),
    defaultValues: {
      name: "",
      email: "",
      type: "USER",
    },
  });

  //   const { mutate, isLoading } = useMutation(data ? updateProject : addProject, {
  //     onSuccess: () => {
  //       {
  //         data
  //           ? toast.success("Project Updated  Successfully")
  //           : toast.success("Project Added  Successfully");
  //       }

  //       queryClient.invalidateQueries("projects");
  //       setOpen(false);
  //       reset();
  //     },
  //     onError: (err) => handleError(err),
  //   });

  useEffect(() => {
    if (data) {
      reset({
        name: data.name || "",
        email: data.emailAddress || "",
        type: data.role?.name || "USER",
      });
    }
  }, [data, reset]);

  const onSubmit = (value: any) => {
    const mutationData = {
      name: value.name,
      email: value.email,
      type: value.type,
    };

    // if (data) {
    //   mutate({
    //     data: mutationData,
    //     id: data._id,
    //   });
    // } else {
    //   mutate(mutationData);
    // }
  };

  return (
    <DialogWrapper
      open={open}
      setOpen={() => {
        setOpen(false);
        reset();
      }}
      title={data ? "Update user" : "Create user"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextField
          formateType={Formate.Alphanumeric}
          control={control}
          label="Name"
          name="name"
          required={data ? false : true}
          placeholder="Enter user name"
          backgroundColor="#FFFFFF"
        />

        <TextField
          formateType={Formate.EmailAddress}
          control={control}
          label="Email"
          name="email"
          required={data ? false : true}
          placeholder="Enter email"
          backgroundColor="#FFFFFF"
        />

        <AutocompleteGeneric
          name="type"
          control={control}
          options={userType}
          label="Type"
          required={data ? false : true}
          placeholder="Select user type"
          backgroundColor="#FFFFFF"
        />

        <Box sx={{ display: "flex", textAlign: "center", mt: 2, gap: "15px" }}>
          <MultiTypeButton
            buttonType={ButtonType.Cancel}
            typeText={"Cancel"}
            actionOnClick={() => setOpen(false)}
          />

          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            // isLoading={isLoading}
            typeText={data ? "Update" : "Submit"}
            actionOnClick={() => {
              handleSubmit(onSubmit)();
            }}
          />
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default UsersForm;
