import EmailVerificationDetails from "../../modules/auth/components/EmailVerificationDetails";
import OnboardingPageWrapper from "../../modules/shared/components/Wrapper/PageWrapper";

export default function EmailVerification() {
  return (
    <OnboardingPageWrapper>
      <EmailVerificationDetails />
    </OnboardingPageWrapper>
  );
}
