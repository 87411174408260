import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Images } from "../../../assets";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import SearchContainer from "../../shared/components/SearchContainer";
import { getAllModels } from "../services";
import { AntSwitch } from "../styles";
import { extractMaxValue, extractMinValue } from "../utility";
import LLMCard from "./modelSelectionComponents/LLMCard";
import PresetsSection from "./modelSelectionComponents/PresetsSection";
import SliderSection from "./modelSelectionComponents/SliderSection";
import SavePresentDialogCard from "./modelSelectionComponents/savePresentDialogCard";
import CustomDropdown from "./modelSelectionComponents/selectLlmSeries";

const ModelSelection = ({
  onValid,
  finalData,
  buttonData,
}: {
  onValid: (isValid: boolean) => void;
  finalData: any;
  buttonData?: any;
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");

  const [cardSelected, setCardSelected] = useState<any[]>([]);
  localStorage.setItem("selectedCards", JSON.stringify(cardSelected));

  const [sliderMaxTokens, setSliderMaxTokens] = useState(1647);
  const [maxTokens, setMaxTokens] = useState(1647);

  const [sliderTopPValue, setSliderTopPValue] = useState(0.5);

  const [sliderTempValue, setSliderTempValue] = useState<any>(0);
  const [tempValue, setTempValue] = useState("0-1");

  const [openPresent, setOpenPresent] = useState(false);

  const [parent, setParent] = useState<any>();
  const [child, setChild] = useState<any>();
  const [version, setVersion] = useState<any>();

  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [onePreset, setOnePreset] = useState<any>();

  const [presetCreation, setPresetCreation] = useState<any>();
  if (presetCreation !== null && presetCreation !== undefined) {
    localStorage.setItem("SelectedPreset", JSON.stringify(presetCreation));
  }

  const [textForNext, setTextForNext] = useState<any>();

  const [localPreset, setLocalPreset] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem("presets") || "{}");
    } catch (error) {
      console.error("Error parsing presets from localStorage:", error);
      return {};
    }
  });

  const [presetsVersion, setPresetsVersion] = useState(0);
  // console.log("NumberLocal:", presetsVersion);

  const { data: GetModels, isLoading: modelsLoading } = useQuery(
    ["all-models", search, parent, child],
    () =>
      getAllModels({
        search,
        providerId: parent,
        modelId: child,
        // versionId: version,
      })
  );

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const updatedPresets = JSON.parse(
          localStorage.getItem("presets") || "{}"
        );
        setLocalPreset(updatedPresets);
      } catch (error) {
        console.error("Error updating presets from localStorage:", error);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handlePresetsUpdate = useCallback(() => {
    setPresetsVersion((prevVersion) => prevVersion + 1);
  }, []);

  // const fetchPresets = () => {
  //   const local = JSON.parse(localStorage.getItem("presets") || "{}");
  //   const selectedversionNames = cardSelected.map((card) => card?.versionName);

  //   const matchingPresets = Object.values(local)
  //     .flat()
  //     .filter((preset: any) =>
  //       preset.selectedCards.some((card: any) =>
  //         selectedversionNames.includes(card?.versionName)
  //       )
  //     );

  //   return matchingPresets;
  // };

  // const presets = fetchPresets();

  const presets = useMemo(() => {
    const local = JSON.parse(localStorage.getItem("presets") || "{}");
    const selectedversionNames = cardSelected.map((card) => card?.versionName);
    return Object.values(local)
      ?.flat()
      ?.filter((preset: any) =>
        preset.selectedCards.some((card: any) =>
          selectedversionNames.includes(card?.versionName)
        )
      );
  }, [cardSelected, presetsVersion]);

  const finalPresetData = useMemo(() => {
    return cardSelected.map((selectedCard) => {
      const applicablePresets = presets
        ?.filter((preset: any) =>
          preset.applicableKeys?.includes(selectedCard.versionName)
        )
        .map((preset: any) => ({
          name: preset.name,
          maxTokens: preset.maxTokens,
          temperature: preset.temperature,
          topP: preset.topP,
        }));

      return {
        providerId: selectedCard.providerId,
        providerName: selectedCard.providerName,
        modelId: selectedCard.modelId,
        modelName: selectedCard.modelName,
        versionId: selectedCard.versionId,
        versionName: selectedCard.versionName,
        presets: applicablePresets,
      };
    });
  }, [cardSelected, presets]);

  const validatePresetData = React.useCallback(() => {
    const isValid = finalPresetData.every((model) => model.presets.length > 0);
    onValid(isValid);
    buttonData(isValid ? "Success" : "Error");
  }, [finalPresetData, onValid, buttonData]);

  useEffect(() => {
    finalData(finalPresetData);
    validatePresetData();
  }, [finalPresetData, validatePresetData, finalData]);

  const Options =
    GetModels?.dropdowns?.map(
      (i: { provider: any; _id: any; models: any }) => ({
        label: i?.provider,
        _id: i?._id,
        children:
          i?.models?.map((j: any) => ({
            label: j?.name,
            _id: j?._id,
            versions:
              j?.versions?.map((k: any) => ({
                label: k?.apiCode,
                _id: k?._id,
              })) || [],
          })) || [],
      })
    ) || [];

  const handleCardSelection = useCallback((data: any, checked: boolean) => {
    setCardSelected((prevSelected) => {
      if (checked) {
        return [...prevSelected, { ...data, checked }];
      } else {
        return prevSelected?.filter(
          (selectedCard) => selectedCard?.versionName !== data?.versionName
        );
      }
    });
  }, []);

  const handleToggleChange = () => {
    setShowSelectedOnly((prevState: any) => !prevState);
  };
  // console.log("Delete1:", onePreset);
  // console.log("Max Tokens:", typeof sliderMaxTokens);

  const handleTempChange = (value: any) => {
    setSliderTempValue(value);
  };

  const handleMaxTokenChange = (value: any) => {
    setSliderMaxTokens(value);
  };

  const handleTopP = (value: any) => {
    setSliderTopPValue(value);
  };

  const handleDeletePreset = () => {
    if (!onePreset) return;

    const updatedPresets = JSON.parse(localStorage.getItem("presets") || "{}");
    // console.log("Delete2:", updatedPresets);

    const presetKey = onePreset.selectedCards[0].versionName;

    if (updatedPresets[presetKey]) {
      updatedPresets[presetKey] = updatedPresets[presetKey]?.filter(
        (preset: any) => preset.name !== onePreset.name
      );

      if (updatedPresets[presetKey].length === 0) {
        delete updatedPresets[presetKey];
      }

      localStorage.setItem("presets", JSON.stringify(updatedPresets));
      setLocalPreset(updatedPresets);
      setOnePreset(null);
      setShowDelete(false);
      handlePresetsUpdate();
    }
  };

  const handleSaveChanges = () => {
    if (!onePreset) return;

    const updatedPresets = JSON.parse(localStorage.getItem("presets") || "{}");
    const presetKey = onePreset.selectedCards[0].versionName;

    if (updatedPresets[presetKey]) {
      updatedPresets[presetKey] = updatedPresets[presetKey].map(
        (preset: any) => {
          if (preset.name === onePreset.name) {
            return {
              ...preset,
              temperature: sliderTempValue,
              topP: sliderTopPValue,
              maxTokens: sliderMaxTokens,
            };
          }
          return preset;
        }
      );

      localStorage.setItem("presets", JSON.stringify(updatedPresets));
      setLocalPreset(updatedPresets);
      setOnePreset(null);
      setShowDelete(false);
      handlePresetsUpdate();
    }
  };

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        // height: "90vh",
        mt: 2,
      }}
    >
      <Box
        width={"93%"}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "2vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5vw",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
            }}
          >
            LLM Model Selection
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ opacity: 0.5, fontWeight: 500 }}
          >
            {GetModels?.models?.length} Models Available
          </Typography>
        </Box>
        <Box>
          <Button sx={{ fontSize: "1rem" }}>+ Add Custom Model</Button>
        </Box>
      </Box>
      <Box
        border={`2px solid ${theme.palette.border.primary}`}
        sx={{
          width: "93%",
          display: "flex",
          flexDirection: "row",
          borderRadius: "10px",
          mt: 1,
        }}
      >
        <Box
          sx={{
            width: "75%",
            p: "1vw 0.8vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mb: 2,
            }}
          >
            <Box
              sx={{
                mt: -1,
              }}
            >
              <SearchContainer
                debounced={true}
                onChange={setSearch}
                value={search}
              />
            </Box>
            <Box
              sx={{
                mt: -0.5,
                position: "relative",
              }}
            >
              <CustomDropdown
                options={Options}
                onChange={(
                  parentIds: any,
                  childrenIds: any,
                  versionIds: any
                ) => {
                  setParent(parentIds);
                  setChild(childrenIds);
                  setVersion(versionIds);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 0.5,
                gap: "0.5vw",
              }}
            >
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                onChange={handleToggleChange}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                }}
              >
                {useMediaQuery("(max-width: 1700px)")
                  ? "Selected Models"
                  : "Show only Selected Models"}
              </Typography>
            </Box>
          </Box>

          <Box>
            <LLMCard
              load={presetsVersion}
              data={GetModels?.models}
              loading={modelsLoading}
              // data={filteredModels}
              onCardSelection={handleCardSelection}
              selectedCard={cardSelected}
              showSelectedOnly={showSelectedOnly}
              nextButton={setTextForNext}
              // sliderTemp={setSliderTempValue}
              maxTemp={setTempValue}
              sliderMaxTokens={setSliderMaxTokens}
              maxTokens={setMaxTokens}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "25%",
            p: "5px 0px 0px 0px",
            borderLeft: "1px solid #1212121A",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              paddingTop: "1.5vh",
              paddingBottom: "2vh",
              paddingLeft: "0.8vw",
              borderBottom: "1px solid #1212121A",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
              }}
            >
              Preset Configuration
            </Typography>
          </Box>

          {cardSelected?.length > 0 ? (
            <Box
              sx={{
                p: "5px 15px 0px 15px",
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                scrollbarColor: "#F07946 #E8E8E8",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#F07946",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#E8E8E8",
                  margin: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <PresetsSection
                presets={presets}
                cardSelected={cardSelected}
                preSetDelete={setShowDelete}
                includedPreset={setOnePreset}
                presetCreation={setPresetCreation}
                clearSelection={onePreset}
              />
              <SliderSection
                label="Max Tokens"
                value={Number(sliderMaxTokens)}
                onChange={(event: any, newValue: any) =>
                  setSliderMaxTokens(newValue)
                }
                color="#F57C00"
                step={1}
                min={0}
                max={maxTokens}
              />
              <SliderSection
                label="Top P"
                value={Number(sliderTopPValue)}
                onChange={(event: any, newValue: any) =>
                  setSliderTopPValue(newValue)
                }
                color="#F57C00"
                step={0.1}
                min={0}
                max={1}
              />
              <SliderSection
                label="Temperature"
                value={sliderTempValue}
                onChange={(event: any, newValue: any) =>
                  setSliderTempValue(newValue)
                }
                color="#F57C00"
                step={0.1}
                min={extractMinValue(tempValue)}
                max={extractMaxValue(tempValue)}
              />

              {showDelete ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    onClick={handleDeletePreset}
                    sx={{
                      bgcolor: "#F2F2F2",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>

                  <MultiTypeButton
                    buttonType={ButtonType.Gradient}
                    // isLoading={isLoading}
                    typeText={"Save Changes"}
                    actionOnClick={handleSaveChanges}
                  />
                </Box>
              ) : (
                (() => {
                  // const selectedKeys = cardSelected.map(
                  //   (card: any) => card?.versionName
                  // );
                  const selectedKeys = [textForNext?.versionName];
                  const maxPresetsReached = selectedKeys.some((key: string) => {
                    const presetsForKey = presets?.filter((preset: any) =>
                      preset.selectedCards.some(
                        (card: any) => card?.versionName === key
                      )
                    );
                    return presetsForKey.length >= 3;
                  });

                  return maxPresetsReached ? null : (
                    <MultiTypeButton
                      buttonType={ButtonType.Gradient}
                      // isLoading={isLoading}
                      typeText={"Add Preset"}
                      // actionOnClick={handleAddPreset}
                      actionOnClick={() => {
                        // if (cardSelected.length >= 2) {
                        //   toast.error(
                        //     "Presets can only be added to one model at a time."
                        //   );
                        // } else {
                        setOpenPresent(true);
                        // }
                      }}
                    />
                  );
                })()
              )}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  fontStyle: "italic",
                  mt: 1,
                }}
              >
                Note: Upon selecting a model, you may configure up to three
                presets, including temperature settings, for each selected LLM
                model.
              </Typography>

              <SavePresentDialogCard
                open={openPresent}
                setOpen={setOpenPresent}
                data={{
                  selectedCards: presetCreation || cardSelected,
                  // selectedCards: cardSelected,
                  maxTokens: sliderMaxTokens,
                  temperature: sliderTempValue,
                  topP: sliderTopPValue,
                }}
                onPresetsUpdate={handlePresetsUpdate}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                p: 2,
                marginTop: "15vh",
              }}
            >
              <img
                src={Images.emptyScreen}
                alt="Empty"
                style={{
                  width: "60%",
                  height: "auto",
                  // marginBottom: "20px",
                }}
              />
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Create your first preset
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                You can save your preset configuration by selecting one of the
                model
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default ModelSelection;
