import Brightness4Icon from "@mui/icons-material/Brightness4"; // Moon icon
import Brightness7Icon from "@mui/icons-material/Brightness7"; // Sun icon
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState } from "react";
import { UIStore } from "../../../store/general";

function ThemeToggle() {
  // State for theme mode (light or dark)
  const [darkMode, setDarkMode] = useState(false);

  // MUI theme configuration based on the darkMode state
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  const isDarkMode = UIStore.useState((s) => s.isDarkMode);

  // Toggle function for the theme
  const handleThemeChange = () => {
    UIStore.update((s) => {
      s.isDarkMode = !isDarkMode;
    });

    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        onClick={handleThemeChange}
        display="flex"
        alignItems="center"
        justifyContent={darkMode ? "flex-end" : "flex-start"}
        width={60}
        height={30}
        bgcolor="background.default"
        borderRadius={15}
        p={0.5}
        sx={{
          cursor: "pointer",
          boxShadow: 1,
          border: "1px solid", // Outline added
          borderColor: darkMode ? "grey.700" : "grey.300", // Adjust color based on mode
          position: "relative",
          transition: "background-color 0.3s, justify-content 0.3s",
        }}
      >
        {/* Sun Icon */}
        <Brightness7Icon
          sx={{
            fontSize: 18,
            color: darkMode ? "text.disabled" : "orange",
            opacity: darkMode ? 0.4 : 1, // Fade out in dark mode
            position: "absolute",
            left: 6,
            transition: "opacity 0.3s, color 0.3s",
          }}
        />

        {/* Moon Icon */}
        <Brightness4Icon
          sx={{
            fontSize: 18,
            color: darkMode ? "orange" : "text.disabled",
            opacity: darkMode ? 1 : 0.4, // Fade out in light mode
            position: "absolute",
            right: 6,
            transition: "opacity 0.3s, color 0.3s",
          }}
        />

        {/* Toggle Button Circle */}
        <Box
          width={24}
          height={24}
          bgcolor={darkMode ? "#333" : "#fff"}
          borderRadius="50%"
          border="1px solid"
          borderColor={darkMode ? "#555" : "#ccc"} // Circle outline
          sx={{
            transition: "transform 0.3s",
          }}
        />
      </Box>
    </ThemeProvider>
  );
}

export default ThemeToggle;
