import LoginDetails from "../../modules/auth/components/LoginDetails";
import OnboardingPageWrapper from "../../modules/shared/components/Wrapper/PageWrapper";

export default function Login() {
  return (
    <OnboardingPageWrapper>
      <LoginDetails />
    </OnboardingPageWrapper>
  );
}
