import { Box } from "@mui/material";
import ConfigurationSetup from "../../modules/configuration";

export default function Configuration() {
  return (
    <Box>
      <ConfigurationSetup />
    </Box>
  );
}
