import {
  blackFigtree,
  boldFigtree,
  // extraLightFigtree,
  lightFigtree,
  mediumFigtree,
  regularFigtree,
  semiboldFigtree,
} from "../assets/fonts/Figtree/index";

export const customCssBaseline = `
 @font-face {
  font-family: 'Figtree';
  src: url(${lightFigtree}) format('truetype');
  font-display: swap;
  font-weight: 200;
}
@font-face {
  font-family: 'Figtree';
  src: url(${regularFigtree}) format('truetype');
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: 'Figtree';
  src: url(${mediumFigtree}) format('truetype');
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: 'Figtree';
  src: url(${semiboldFigtree}) format('truetype');
  font-display: swap;
  font-weight: 600;
}
@font-face {
  font-family: 'Figtree';
  src: url(${boldFigtree}) format('truetype');
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: 'Figtree';
  src: url(${blackFigtree}) format('truetype');
  font-display: swap;
  font-weight: 900;
}

html {
    font-size: 16px;
  }

  body {
    font-family: 'Figtree', sans-serif;
  }

  @media (max-width: 600px) {
    html {
      font-size: 14px;
    }
  }

  @media (min-width: 1200px) {
    html {
      font-size: 18px;
    }
  }
`;
