import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";
import _ from "lodash";

interface SearchContainerProps {
  placeHolder?: string;
  onChange: (v: string) => void;
  onFocus?: (e: any) => void;
  width?: string;
  maxWidth?: string;
  debounced?: boolean;
  minWidth?: string;
  value?: string;
  defaultValue?: string;
  autoFocus?: boolean;
}

function SearchContainer(props: SearchContainerProps) {
  const {
    placeHolder = "Search",
    onChange,
    maxWidth = "40vw",
    width = "100%",
    debounced,
    minWidth = "20vw",
    onFocus,
    defaultValue = "",
    autoFocus = false,
  } = props;

  const handleChange = _.debounce(function (e: any) {
    onChange(e.target.value);
  }, 1000);

  return (
    <TextField
      sx={{
        maxWidth,
        width,
        minWidth,
        borderRadius: "8px",
        backgroundColor: "transparent",
        height: "45px",
        "&:hover": {},
        "& .MuiOutlinedInput-root": {
          background: "F8F8F9",
          borderRadius: "8px",
          height: "42px",
          "& .MuiOutlinedInput-input": {
            backgroundColor: "none",
            marginTop: 0,
          },
        },
        "& .MuiInputBase-root": {
          boxShadow: "none",
        },
        "& .MuiInputAdornment-root": {
          color: "grey",
          backgroundColor: "#FBFBFB",
        },
        "& .css-3m43fk-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused": {
          boxShadow: "none",
        },
        "& .MuiInputBase-input::placeholder": {
          fontSize: "16px",
          fontWeight: 300,
          fontFamily: "Figtree",
        },
      }}
      fullWidth
      color="primary"
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      onChange={(e) => {
        debounced ? handleChange(e) : onChange(e.target.value);
      }}
      size="small"
      placeholder={placeHolder}
      InputProps={{
        startAdornment: <Search sx={{ color: "grey", marginRight: 1 }} />,
      }}
      onFocus={onFocus}
    />
  );
}

export default SearchContainer;
