import { ColorPalette } from "./colorPalette";

export const LightColorTokens = {
  primary: {
    main: ColorPalette.BrandColors.OrangeLight,
    light: ColorPalette.BrandColors.YellowLight,
    dark: ColorPalette.BrandColors.YellowLight,
  },
  secondary: {
    main: ColorPalette.BrandColors.OrangeLight,
    light: ColorPalette.GreyColors.GreyBlack,
    dark: ColorPalette.GreyColors.GreyBlack70,
  },
  tertiary: { main: ColorPalette.BrandColors.Cream2 },
  text: {
    primary: ColorPalette.GreyColors.GreyBlack85,
    secondary: ColorPalette.GreyColors.GreyBlack70,
    tertiary: ColorPalette.GreyColors.GreyBlack80,
    black: ColorPalette.GreyColors.GreyBlack,
    white: ColorPalette.GreyColors.GreyWhite,
    primaryContrast: ColorPalette.GreyColors.GreyWhite,
  },
  surface: {
    defaultBackground: ColorPalette.GreyColors.GreyWhite100,
    layoutBackground: ColorPalette.GreyColors.GreyWhite100,
    outletBackground: ColorPalette.GreyColors.GreyWhite100,
    AppBarBackground: ColorPalette.GreyColors.GreyWhite100,
    OnboardingCardBackground: ColorPalette.GreyColors.GreyWhite100,
    paperBackground: ColorPalette.GreyColors.GreyWhite,
    grey: ColorPalette.GreyColors.Grey1,
  },
  border: {
    primary: ColorPalette.GreyColors.GreyBlack10,
  },
  textField: {
    defaultBackground: ColorPalette.GreyColors.GreyWhite2,
    border: ColorPalette.GreyColors.GreyBlack15,
  },
  highlight: {
    highlightGreen: ColorPalette.UtilityColors.Green,
    highlightOrange: ColorPalette.UtilityColors.Orange,
    highlightBlue: ColorPalette.UtilityColors.Blue,
    highlightRed: ColorPalette.UtilityColors.Red,
    highlightPink: ColorPalette.UtilityColors.Pink,
    highlightYellow: ColorPalette.UtilityColors.Yellow,
    greenBackground: ColorPalette.UtilityColors.Green20,
    orangeBackground: ColorPalette.UtilityColors.Orange20,
    blueBackground: ColorPalette.UtilityColors.Blue20,
    redBackground: ColorPalette.UtilityColors.Red20,
    pinkBackground: ColorPalette.UtilityColors.Pink20,
    yellowBackground: ColorPalette.UtilityColors.LightYellow,
  },
};
