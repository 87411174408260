import { Box, Tab, Typography } from "@mui/material";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { StyledContainer, StyledTabs } from "./styles";

export interface TabsContainerProps {
  tabs: {
    key: string;
    label: string;
    component?: ReactNode;
    highlight?: boolean;
  }[];
  size?: "small" | "medium";
  containerVariant?: "box" | "page";
  onTabChange?: (tabKey: string) => void;
  tabUnderlineColor?: string;
  tabMobileUnderlineColor?: string;
  currentTabIndex?: number;
  mainTab?: string;
  subTab?: string;
  initial?: string;
  params?: string;
}

export default function TabsContainer({
  tabs,
  size = "small",
  containerVariant = "box",
  tabUnderlineColor,
  tabMobileUnderlineColor,
  mainTab,
  subTab,
  initial,
  params,
}: TabsContainerProps) {
  const [currentTab, setCurrentTab] = useState(mainTab);

  const navigate = useNavigate();
  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
    navigate(`/${initial}/${value}`);
  };

  const SubTab = mainTab === `${params}`;

  useEffect(() => {
    if (SubTab) {
      navigate(`/${initial}/${mainTab}`);
    }
  }, [SubTab, mainTab, navigate]);

  return (
    <StyledContainer>
      <StyledTabs
        containerVariant={containerVariant}
        size={size}
        onChange={handleTabsChange}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        textColor="primary"
        indicatorColor="primary"
        underlineColor={tabUnderlineColor}
        mobileUnderlineColor={tabMobileUnderlineColor}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            label={
              <Typography variant="h5" fontWeight={600}>
                {tab.label}
              </Typography>
            }
            value={tab.key}
            // icon={tab.highlight ? <span>{"."}</span> : null}
            iconPosition="end"
            disableRipple
          />
        ))}
      </StyledTabs>
      <Box
        sx={{
          mt: 1,
          overflowY: "auto",
          // height: "80vh",
        }}
      >
        <Outlet />
      </Box>
    </StyledContainer>
  );
}
