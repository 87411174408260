import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { updateAPIKey } from "../../prompt/services";
import { tier } from "../../prompt/utility";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../shared/components/Errors/HandleError";
import { SingleSelect } from "../../shared/components/Select/SingleSelect";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";

interface IApiKeysCardProps {
  index: number;
  item: any;
  providerId: string;
}

export default function ApiKeysCard({
  index,
  item,
  providerId,
}: IApiKeysCardProps) {
  const queryClient = useQueryClient();
  const [disableField, setDisableField] = useState(true);

  const { mutate: updateApiKey, isLoading } = useMutation(updateAPIKey, {
    onSuccess: () => {
      toast.success("API Updated Successfully");
      queryClient.invalidateQueries("users");
      setDisableField(true);
    },
    onError: (err) => handleError(err),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const onSubmit = (data: any) => {
    const mutationData = {
      providerId: providerId,
      apiKey: data?.apiKey,
      tier: data.tier,
    };
    console.log(mutationData);
    updateApiKey(mutationData);
  };

  return (
    <Box>
      <Stack direction={"row"} gap={"15px"}>
        <TextField
          formateType={Formate.AllowCapsSmallSpaces}
          control={control}
          name={`apiConfiguration[${index}].provider`}
          label="API Provider"
          placeholder="Enter API provider"
          defaultValue={item?.provider}
          disabled={true}
          backgroundColor="#F8F8F8"
          width="40vw"
        />
        <SingleSelect
          control={control}
          required
          name="tier"
          label="Tier"
          options={tier}
          disabled={disableField}
          defaultData={item?.tier}
          placeholder="Tier"
        />
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <TextField
          formateType={Formate.APIKey}
          control={control}
          label="API Key"
          name={`apiKey`}
          // defaultValue={decryptedApiKey}
          defaultValue={item.apiKey}
          disabled={disableField}
          backgroundColor="#F8F3F8"
          width="40vw"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            mt: 3,
          }}
        >
          <MultiTypeButton
            buttonType={ButtonType.Cancel}
            // isLoading={isLoading}
            typeText={"Edit"}
            width={"10vw"}
            height={"4.5vh"}
            actionOnClick={() => {
              setDisableField(false);
            }}
          />
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            // isLoading={isLoading}
            typeText={"Save"}
            width={"10vw"}
            height={"4.5vh"}
            disabled={disableField}
            actionOnClick={() => {
              handleSubmit(onSubmit)();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
