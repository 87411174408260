import { Button, Stack, Typography, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../../../app/services";
import { AuthStore } from "../../../store/general";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import OtpInput from "../../shared/components/TextField/OtpInput/OtpInput";
import { verifyOtp } from "../services/AuthService";

export default function OtpVerificationDetails() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(0);
  const refresh_Token = Cookies.get("refresh_Token");
  const EmailAddress = AuthStore.useState((s) => s.signupEmailAddress);
  const { mutate: verifyOTP, isLoading: loginLoader } = useMutation(verifyOtp, {
    onSuccess: (res: any) => {
      Cookies.set("access_token", res.access_token);
      Cookies.remove("refresh_Token");
      displaySuccessMessage(res.message);
      navigate(`/app`, { replace: true });
    },
    onError: (error: any) => {
      displayErrorMessage(error);
    },
  });

  return (
    <Stack alignItems={"center"} justifyContent={"center"} gap={"20px"}>
      <Typography variant="h3"> OTP Verification</Typography>
      <Typography variant="subtitle2">
        {" "}
        Please enter the code, we sent to your Email Address
      </Typography>

      <OtpInput
        length={6}
        onChangeOtp={(e: any) => {
          setOtp(e);
        }}
      />

      <MultiTypeButton
        isLoading={loginLoader}
        buttonType={ButtonType.Gradient}
        actionOnClick={() => {
          verifyOTP({
            otp: otp.toString(),
            token: refresh_Token,
            type: "LOGIN",
            value: EmailAddress,
          });
        }}
        key={"Verify-otp"}
        typeText={"Verify OTP"}
      />
      <Button variant="text">Resend OTP</Button>
    </Stack>
  );
}
