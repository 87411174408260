import { Delete, Visibility } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { Icons } from "../../../../assets";
import { UIStore } from "../../../../store/general";
import { LabelWrapper } from "../../../shared/components/LabelWrapper/LabelWrapper";
import { mediaSubType } from "../CreateDataset";

export type UploadedDataDisplayProps = {
  containerProps?: StackProps;
  description?: string;
  control?: any;
  required: boolean;
  uploadFileType: mediaSubType; // Dynamically passed
  label: string;
  name: string;
  DeleteFilesHandler: (index: any) => void;
  uploadedFiles: {
    originalFileName: string;
    key: string;
    mediaType: string;
    createdAt: string;
    url?: string; // URL if already available
  }[];
};

export const fileTypeIcons = {
  IMAGE: Icons.imageFile, // replace with actual image icon import
  VIDEO: Icons.video, // replace with actual video icon import
  AUDIO: Icons.audio, // replace with actual audio icon import
  CSV: Icons.csv, // replace with actual csv icon import
  EXCEL: Icons.excel, // replace with actual excel icon import
  PDF: Icons.pdf, // replace with actual pdf icon import
};

export default function UploadedDataDisplay({
  label,
  required,
  name,
  uploadFileType,
  containerProps,
  uploadedFiles,
  DeleteFilesHandler,
}: UploadedDataDisplayProps) {
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  const awsUrl = process.env.REACT_APP_AWS_URL;

  const handleViewClick = (fileUrl: string) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <Box my="10px">
      <LabelWrapper
        label={label}
        required={required}
        name={name}
        {...containerProps}
      >
        <Box
          component="div"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap={2}
          height={uploadedFiles.length <= 2 ? "15vh" : "25vh"}
          overflow="auto"
          border="2px dashed #CCCCCC"
          borderRadius={"10px"}
          p={2}
        >
          {uploadedFiles?.map((file: any, index) => (
            <Card
              key={file._id || index} // Use unique _id or index as key
              sx={{
                width: "100%",
                height: "60px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                backgroundColor: isDarkMode ? "auto" : "#F7F8FA",
              }}
            >
              <CardMedia
                component="img"
                image={fileTypeIcons[uploadFileType]}
                alt={file?.originalFileName}
                sx={{
                  width: 40,
                  height: 40,
                  objectFit: "cover",
                  margin: "10px",
                }}
              />
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px",
                  textAlign: "left",
                  flex: 1,
                  height: "50px",
                }}
              >
                <Stack>
                  <Typography variant="subtitle2" fontWeight={"bold"} noWrap>
                    {file?.originalFileName}
                  </Typography>
                  <Typography variant="caption">
                    Date: {new Date(file.createdAt).toLocaleDateString()}
                  </Typography>
                </Stack>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mt={1}
                  gap={"10px"}
                >
                  <IconButton
                    onClick={() =>
                      handleViewClick(file.url || `${awsUrl}/${file.key}`)
                    }
                    // color="primary"
                    size="small"
                    sx={{
                      color: "blue",
                      border: "0.5px solid grey",
                      background: "FFFFFF",
                      ":hover": {
                        backgroundColor: isDarkMode
                          ? "transparent"
                          : "lightgray",
                      },
                    }}
                  >
                    <Visibility
                      fontSize="small"
                      htmlColor={isDarkMode ? "grey" : "#F7711F"}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => DeleteFilesHandler(index)}
                    color="error"
                    size="small"
                    sx={{
                      border: "0.5px solid grey",
                      background: "FFFFFF",
                      ":hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
                    }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </LabelWrapper>
    </Box>
  );
}
