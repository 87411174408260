import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FormControl,
  MenuItem,
  Select as MuiSelect,
  StackProps,
  Typography,
} from "@mui/material";
import { LabelWrapper } from "../LabelWrapper/LabelWrapper";

export type SelectProps = {
  name?: string;
  required?: boolean;
  label?: string;
  containerProps?: StackProps;
  description?: string;
  options: any;
  defaultData?: string;
  backgroundColor?: string;
  border?: string;
  control?: any;
  placeholder?: string;
};

export const NoSelect = ({
  name = "",
  required = false,
  label,
  options,
  description,
  backgroundColor,
  border,
  placeholder,
  containerProps,
}: SelectProps) => {
  return (
    <LabelWrapper
      label={label}
      required={required}
      name={name}
      containerProps={containerProps}
    >
      <FormControl fullWidth>
        <MuiSelect
          value=""
          displayEmpty
          IconComponent={ExpandMoreIcon}
          renderValue={(value) => <Typography>{placeholder}</Typography>}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 300,
                overflowY: "auto",
                mt: 1,
                borderRadius: 2,
                boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
              },
            },
          }}
          sx={{
            backgroundColor: backgroundColor,
            border: border,
            cursor: "none",
          }}
        >
          {options.map((option: any, index: number) => (
            <MenuItem
              key={option?.value ? option?.value : option}
              sx={{
                borderBottom:
                  index !== options.length - 1 ? "1px solid #e0e0e0" : "none",
                fontWeight: 400,
                fontSize: "16px",
                padding: "8px 16px",
              }}
            >
              {option?.label ? option?.label : option}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
      {description && <Typography variant="caption">{description}</Typography>}
    </LabelWrapper>
  );
};
