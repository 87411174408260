import { Box, LinearProgress } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import EmptyPage from "../../shared/components/Errors/EmptyPage";
import { getAllProjects } from "../services/ProjectService";

export default function ProjectSetup() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const { data: GetAllProjects, isLoading }: any = useQuery(
    ["projects", search, limit, offset],
    () => getAllProjects({ search, limit, offset }),
    {
      onSuccess: (res: any) => {
        if (res?.total > 0) {
          navigate("/app/projects/all");
        }
      },
    }
  );

  if (isLoading) {
    return (
      <>
        <LinearProgress />
      </>
    );
  }

  return (
    <Box>
      {GetAllProjects?.total === 0 ? (
        <EmptyPage
          buttonTitle="Add Project"
          tagLine="Create your first Project"
        />
      ) : null}
    </Box>
  );
}
