import { createTheme } from "@mui/material/styles";
import { LightColorTokens } from "./colorTokens/lightColorTokens";
import { getCommonTheme, Theme } from "./CommonTheme";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    ...LightColorTokens,
  },
  ...getCommonTheme(Theme.LIGHT),
});
