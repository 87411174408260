import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmDialogProvider from "../modules/project/components/confirmDialog";
import router from "../routes/AppRoutes";
import { UIStore } from "../store/general";
import { darkTheme, lightTheme } from "../themes/export";
import { MuiThemeProvider } from "../themes/MuiThemeProvider";
import "./App.css";

function App() {
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Box className="App">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MuiThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <QueryClientProvider client={queryClient}>
              <ConfirmDialogProvider>
                <RouterProvider router={router} />
              </ConfirmDialogProvider>
            </QueryClientProvider>
            <ToastContainer position="top-right" />
          </MuiThemeProvider>
        </LocalizationProvider>
      </Box>
    </GoogleOAuthProvider>
  );
}

export default App;
