import { ColorPalette } from "./colorPalette";

export const DarkColorTokens = {
  primary: {
    main: ColorPalette.BrandColors.OrangeLight,
    light: ColorPalette.BrandColors.YellowLight,
    dark: ColorPalette.BrandColors.YellowLight,
  },
  secondary: {
    main: ColorPalette.BlueGreyColors.GreyBlue70,
    light: ColorPalette.BlueGreyColors.GreyBlue50,
    dark: ColorPalette.BlueGreyColors.NavyBlue,
  },
  tertiary: { main: ColorPalette.BrandColors.Cream1 },
  text: {
    primary: ColorPalette.GreyColors.GreyWhite100,
    secondary: ColorPalette.GreyColors.GreyWhite100,
    tertiary: ColorPalette.GreyColors.GreyWhite100,
    black: ColorPalette.GreyColors.GreyDark,
    white: ColorPalette.GreyColors.GreyWhite100,
    primaryContrast: ColorPalette.BlueGreyColors.GreyBlue50,
  },
  surface: {
    layoutBackground: ColorPalette.BlueGreyColors.GreyBlue70,
    outletBackground: ColorPalette.BlueGreyColors.GreyBlue,
    AppBarBackground: ColorPalette.BlueGreyColors.GreyBlue,
    OnboardingCardBackground: ColorPalette.BlueGreyColors.GreyBlue50,
    paperBackground: ColorPalette.BlueGreyColors.GreyBlue70,
    grey: ColorPalette.BlueGreyColors.GreyBlueLight,
  },
  border: {
    primary: ColorPalette.GreyColors.GreyWhite10,
  },
  textField: {
    defaultBackground: ColorPalette.BlueGreyColors.BlueGrey2,
    border: ColorPalette.GreyColors.GreyWhite15,
  },
  highlight: {
    highlightGreen: ColorPalette.UtilityColors.Green,
    highlightOrange: ColorPalette.UtilityColors.Orange,
    highlightBlue: ColorPalette.UtilityColors.Blue,
    highlightRed: ColorPalette.UtilityColors.Red,
    highlightPink: ColorPalette.UtilityColors.Pink,
    highlightYellow: ColorPalette.UtilityColors.Yellow,
    greenBackground: ColorPalette.UtilityColors.Green20,
    orangeBackground: ColorPalette.UtilityColors.Orange20,
    blueBackground: ColorPalette.UtilityColors.Blue20,
    redBackground: ColorPalette.UtilityColors.Red20,
    pinkBackground: ColorPalette.UtilityColors.Pink20,
    yellowBackground: ColorPalette.UtilityColors.LightYellow,
  },
};
