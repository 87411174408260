import OtpVerificationDetails from "../../modules/auth/components/OtpVerificationDetails";
import OnboardingPageWrapper from "../../modules/shared/components/Wrapper/PageWrapper";

export default function OtpVerification() {
  return (
    <OnboardingPageWrapper>
      <OtpVerificationDetails />
    </OnboardingPageWrapper>
  );
}
