import { http } from "../../../app/services";

const getAllUsers = (queryKey: any) => {
  return http
    .get(`/users`, {
      params: { ...queryKey },
    })
    .then((res) => res.data);
};

const getOneUser = ({ queryKey }: any) => {
  return http.get(`/users/${queryKey[1]}`).then((res) => res.data);
};

export { getAllUsers, getOneUser };
