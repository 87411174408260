import Cookies from "js-cookie";
import { Navigate, RouteObject } from "react-router-dom";
import GitHubCallback from "../modules/auth/components/GithubLoading";
import EmailVerification from "../pages/auth/EmailVerification";
import Login from "../pages/auth/Login";
import OtpVerification from "../pages/auth/OtpVerification";
import Registration from "../pages/auth/Registration";
import Signup from "../pages/auth/Signup";
import VerifyEmail from "../pages/auth/VerifyEmail";

interface PublicRouteProps {
  children: JSX.Element;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const isAuthenticated = !!Cookies.get("access_token");

  return isAuthenticated ? <Navigate to="/app/dashboard" /> : children; // Redirect to the dashboard if authenticated
};

const publicRoutes: RouteObject[] = [
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: "/signup",
    element: (
      <PublicRoute>
        <Signup />
      </PublicRoute>
    ),
  },
  {
    path: "/register",
    element: (
      <PublicRoute>
        <Registration />
      </PublicRoute>
    ),
  },
  {
    path: "/email-verification",
    element: (
      <PublicRoute>
        <EmailVerification />
      </PublicRoute>
    ),
  },
  {
    path: "/otp-verification",
    element: (
      <PublicRoute>
        <OtpVerification />
      </PublicRoute>
    ),
  },
  {
    path: "/verify",
    element: (
      <PublicRoute>
        <VerifyEmail />
      </PublicRoute>
    ),
  },
  {
    path: "/auth/github",
    element: (
      <PublicRoute>
        <GitHubCallback />
      </PublicRoute>
    ),
  },
  {
    path: "/",
    element: <Navigate to="/signup" />,
  },
];

export default publicRoutes;
